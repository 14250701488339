import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {AnimationOptions, LottieTransferState} from "ngx-lottie";

@Component({
  selector: 'app-redmed-loading',
  templateUrl: './redmed-loading.component.html',
  styleUrls: ['./redmed-loading.component.scss']
})
export class RedmedLoadingComponent implements OnInit, OnDestroy {
  public lottieConfig: AnimationOptions;
  showLoading = false;
  isBrowser: boolean;
  private anim: any;

  constructor(public loading: LoadingService,
              private lottieTransferState: LottieTransferState,
              @Inject(PLATFORM_ID) platformId: Object) {
    // this.isBrowser = isPlatformBrowser(platformId);
    this.createOptions();
  }

  ngOnInit() {
    this.loading.isLoading
      .subscribe(
        (isLoading: boolean) => {
          this.showLoading = isLoading;
          if (!isLoading) {
            document.body.style.overflow = "auto";
          } else {
            document.body.style.overflow = "hidden";
          }
        });
  }

  ngOnDestroy(): void {
    document.body.style.overflow = "auto";
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  private createOptions(): void {
    if (this.lottieTransferState.get('RedmedLoader-1.json')) {
      this.lottieConfig = {
        animationData: this.lottieTransferState.get('RedmedLoader-1.json')
      };
    } else {
      this.lottieConfig = {
        path: '/assets/RedmedLoader-1.json'
      };
    }
  }

}
