import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SeoOptimizationService } from '../../services/seo-optimization.service';
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from "src/app/services";
@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent implements OnInit {
  @Output() show = new EventEmitter<boolean>();
  @Output() preferencesToggleChange = new EventEmitter<boolean>();
  @Output() performanceToggleChange = new EventEmitter<boolean>();
  preferencesToggleValue: boolean = false;
  performanceToggleValue: boolean = false;
  @Input() startValuePreference: boolean;
  @Input() startValuePerformance: boolean;
  public selectedLanguage: string;
  // Method to emit the value
  sendValue(value: boolean) {
    this.show.emit(value);
  }

  constructor(
    private seoOptimizationService: SeoOptimizationService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.languageService.getLang();
    this.preferencesToggleValue=this.startValuePreference;
    this.performanceToggleValue=this.startValuePerformance;
  }

  changePreference(event: any){
    this.preferencesToggleValue=event.checked;
  }

  changePerfomance(event: any){
    this.performanceToggleValue=event.checked;
  }
  
  save(){
    this.preferencesToggleChange.emit(this.preferencesToggleValue);
    this.performanceToggleChange.emit(this.performanceToggleValue);
    this.sendValue(false);
  }

  close(){
    this.sendValue(false);
  }
}
