import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, UserService} from '../../../services';
import {MessagingService} from '../../messaging.service';
import {UserModel} from '../../models/user.model';
import {HelpCrunchService} from '../../services/help-crunch.service';
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";

@Component({
  selector: 'app-redmed-header',
  templateUrl: './redmed-header.component.html',
  styleUrls: ['./redmed-header.component.scss']
})
export class RedmedHeaderComponent implements OnInit {
  @Input() mobileSizeScreen: boolean;
  @Output() showSidenav = new EventEmitter();

  currentUser: UserModel;
  showBurgerMenu: boolean = false;
  showAppDownloadSection = true;
  selectedLanguage = localStorage.getItem('CurLang');


  constructor(private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private messagingService: MessagingService,
              private userService: UserService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private helpCrunchService: HelpCrunchService,
              public translate: TranslateService,
              private ART: ActivatedRoute,
              private location: Location) {
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/logo.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'person',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/person.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/phone.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'about-us',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/about-us.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stethoscope',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/stethoscope.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/calendar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'star',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/img/star.svg')
    );
  }

  titleArray= [
    {
    title: 'doctor-space',
    isOpen: false
  },    
  {
    title: 'aboutUs',
    isOpen: false
  },    {
    title: 'termsConditions',
    isOpen: false
  },

]

  ngOnInit(): void {
    this.getCurrentUser();
    this.checkForRouteChange();
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    localStorage.setItem('CurLang', lang);
    // this.translate.use(lang);
    const url = this.router.createUrlTree([], {relativeTo: this.ART, queryParams: {...this.ART.queryParams['value'], selectedLanguage: this.selectedLanguage}}).toString();
    this.location.replaceState(url);
    window.location.reload();
  }

  public scrollToHowItWorksSection(): void {
    document.body.style.overflow = 'auto'
    this.showBurgerMenu = false
    this.closeContainers()
    const itemToScrollTo = document.getElementById("how-it-works-main-section");
    if (itemToScrollTo) {
      const topOfElement = itemToScrollTo.offsetTop;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  get getStoneName(): string {
    const userAgent = navigator.userAgent;
    if(/android/i.test(userAgent)){
      return 'Play';
    }
    if(/iPad|iPhone|iPod/i.test(userAgent)){
      return 'App';
    }
  }
  onAppDownloadClick(){
    if(this.checkMobileSystem() === 'Android'){
      window.location.replace('market://details?id=ge.redmed.doctor');
    }else if(this.checkMobileSystem() === 'iOS'){
      window.location.replace('itms-apps://itunes.apple.com/app/1483753311');
    }else{
      window.location.replace('https://play.google.com/store/apps/details?id=ge.redmed.doctor&hl=en&gl=US');
    }
          // if (isPlatformBrowser(this.platformId)) {
    //   const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    //   const android = !!navigator.platform && /android/i.test(navigator.platform);
    //   if (iOS) {
    //     window.location.href = 'https://apps.apple.com/sr/app/redmed/id1483844703'
    //   } else if (android) {
    //     window.location.replace('intent://redmed.ge/#Intent;scheme=https;package=ge.redmed.patient;end');
    //   }
    // }  
  }

  onOpenAppClick(){
    window.open('https://redmed.ge/download?utm_source=download+buttom&utm_medium=web&utm_id=redmed+website', '_blank');
  }
  checkMobileSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return 'iOS';
    }
    return null;
  }

  checkForRouteChange() {
    this.router.events
      .subscribe((event) => {
        this.showBurgerMenu = false;
        this.closeContainers()
        document.body.style.overflow = 'auto'
      });
  }

  closeContainers(){
    this.titleArray.forEach(container => {
      container.isOpen = false;
    });
  }

  getCurrentUser() {
    this.userService.getUser
      .subscribe((user: UserModel) => {
        if (user) {
          this.currentUser = new UserModel().deserialize(user);
        } else {
          this.currentUser = null;
        }
      });
  }

  toggleBurgerMenu() {

    this.showBurgerMenu = !this.showBurgerMenu;
    if(this.showBurgerMenu){
      this.showAppDownloadSection = false
      document.body.style.overflow = 'hidden'
    }else{
      document.body.style.overflow = 'auto'
      
    }
  }


  isContainerOpen(container: any) {

    const index = this.titleArray.indexOf(container);
    if (index > -1) {
      // toggle the isOpen property of the specified element
      this.titleArray[index].isOpen = !this.titleArray[index].isOpen;
      
      // set the isOpen property of all other elements to false
    }
  }



  goToPage(page: string){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([page])
    );
    window.open(url, '_blank');
  }

  showIntercom() {
    this.helpCrunchService.showHelpCrunch();
  }

  logout() {
    this.helpCrunchService.logout();
    this.authService.logout();
    this.router.navigate(['/'])
      .then(() => {
        this.messagingService.logoutFirebase();
      });
  }

  switchDoctorInstantCallStatus() {
    this.userService.setDoctorInstantCallStatus(this.currentUser.isInstantCallEnabled)
      .subscribe(() => {
      });
  }

  downloadApp(): void {
    window.open('https://redmed.ge/download?utm_source=download+buttom&utm_medium=web&utm_id=redmed+website', '_blank');
  }

  getLanguageImg(): string {
    return this.selectedLanguage === 'ka' ? 'en' : 'ka';
  }
}
