import { Location, PlatformLocation } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from '../core/config/config';
import { RequestDoctorDialogComponent } from '../shared/dialogs/request-doctor-dialog/request-doctor-dialog.component';
import { UserModel } from '../shared/models/user.model';
import { LoadingService } from '../shared/services/loading.service';
import { ApiService } from './api.service';
import { DoctorService } from './doctor.service';
import { LanguageService } from './language.service';
import { UserService } from './user.service';
import { TbcConnectService } from "./tbc-connect.service";
import { environment } from "../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthService {

  private authorized;
  private cLang;
  private headers: any = {};
  private userLoginStatus = new BehaviorSubject(false);
  checkLoginStatus = this.userLoginStatus.asObservable();

  private ssoFrame = new BehaviorSubject<HTMLIFrameElement>(null);
  getIframe = this.ssoFrame.asObservable();
  private apiUrl = environment.apiUrl;

  constructor(private loadingService: LoadingService,
    public API: ApiService,
    private http: HttpClient,
    private LS: LanguageService,
    private angularFireAuth: AngularFireAuth,
    private RT: Router, platformLocation: PlatformLocation,
    private location: Location,
    private doctorService: DoctorService,
    private dialog: MatDialog,
    public userService: UserService,
    private tbcConnectService: TbcConnectService,
    private translateService: TranslateService) {
    this.setLogged();
    this.cLang = this.LS.getLang();
  }

  setIframe(frame) {
    this.ssoFrame.next(frame);
  }

  public checkAccess(response: any) {
    if (response.status == 401) {
      localStorage.removeItem(CONFIG.API.TAGS.USER_TOKEN);
      localStorage.removeItem(CONFIG.API.TAGS.USER_ID);
      localStorage.removeItem(CONFIG.API.TAGS.USER_NAME);
      localStorage.removeItem(CONFIG.API.TAGS.USER_TYPE);
      this.logout();
      return;
    }
    if (response.status == 400) {
      //localStorage.setItem(CONFIG.API.TAGS.USER_TOKEN, response.UpdatedToken);
    }
  }

  public checkTokenExists() {
    if (!localStorage.getItem(CONFIG.API.TAGS.USER_TOKEN)) {
      this.logout();
      return;
    }
  }

  public isLogged() {
    const token = localStorage.getItem(CONFIG.API.TAGS.USER_TOKEN);
    return this.authorized || token;
  }

  public isDoctor() {
    return this.userService.getUserType() == CONFIG.COMMON.DOCTOR_USER_TYPE;
  }

  public setLogged(token = null) {

    if (token != null) {
      this.authorized = true;
      this.userLoginStatus.next(true);
    } else {
      token = localStorage.getItem(CONFIG.API.TAGS.USER_TOKEN);
      this.authorized = token != null;
    }
  }

  public setUserData(token, userID, userName, userData, stayOnPage: boolean = false, redirectToPharma: any = null) {
    localStorage.setItem(CONFIG.API.TAGS.USER_TOKEN, token.accessToken);
    localStorage.setItem('refreshToken', token.refreshToken);
    localStorage.setItem(CONFIG.API.TAGS.USER_ID, userID);
    localStorage.setItem(CONFIG.API.TAGS.USER_NAME, userName);
    localStorage.setItem(CONFIG.API.TAGS.USER_DATA, JSON.stringify(userData));
    this.sendTokenToSSO(token);
    const userType = userData['isDoctor'] == true ? CONFIG.COMMON.DOCTOR_USER_TYPE : CONFIG.COMMON.PATIENT_USER_TYPE;
    localStorage.setItem(CONFIG.API.TAGS.USER_TYPE, userType);
    this.userLoginStatus.next(true);
    this.userService.setUserType(userType);
    if (!redirectToPharma) {
      let red = JSON.parse(localStorage.getItem('redirect'))
      if (red) {
        redirectToPharma = red;
      }
    }
    this.userService.getCurrentUser()
      .subscribe((user: UserModel) => {
        const currentUser = new UserModel().deserialize(user);
        if (user['isDoctor'] === true) {
          if (!stayOnPage) {
            this.RT.navigate(['/profile/booking']);
          }
        } else {
          if (!stayOnPage && !redirectToPharma) {
            if (localStorage.getItem('preOrder')) {
              if (localStorage.getItem('foreignerSlotsPageRoute')) {
                const prevUrl = localStorage.getItem('foreignerSlotsPageRoute');
                localStorage.removeItem('foreignerSlotsPageRoute');
                this.RT.navigateByUrl(prevUrl);
                return;
              }
              const sendData = JSON.parse(localStorage.getItem('preOrder'));
              this.loadingService.setApplicationLoading(true);
              this.API.createOrder(sendData)
                .subscribe(
                  (response: any) => {
                    this.RT.navigate([`/order`], {
                      queryParams: {
                        orderId: response.OrderId,
                        doctorId: sendData.DoctorId,
                        ServiceTypeId: sendData.ServiceTypeId,
                        SpecialityId: sendData.SpecialityId
                      }
                    }).then(() => {
                      this.loadingService.setApplicationLoading(false);
                      localStorage.removeItem('preOrder');
                    });
                  }, () => {
                    localStorage.removeItem('preOrder');
                    alert(this.translateService.instant('the-doctor-does-not-have-the-right-to-issue-an-order'));
                    this.loadingService.setApplicationLoading(false);
                  });
            } else if (localStorage.getItem('doctorRequestData')) {
              const doctorRequestData = JSON.parse(localStorage.getItem('doctorRequestData'));
              this.doctorService.requestDoctor(doctorRequestData)
                .subscribe(
                  (response: any) => {
                    this.RT.navigate(['/search'], {
                      queryParams: {
                        specId: doctorRequestData.SpecialtyId,
                        serviceType: doctorRequestData.ServiceTypeId
                      }
                    }).then(
                      () => {
                        localStorage.removeItem('doctorRequestData');
                        const dialog = this.dialog.open(RequestDoctorDialogComponent, {
                          width: '430px',
                          data: doctorRequestData
                        });
                      });
                  }, () => {
                    this.RT.navigate(['/'])
                      .then(
                        () => {
                          localStorage.removeItem('doctorRequestData');
                          const dialog = this.dialog.open(RequestDoctorDialogComponent, {
                            width: '430px',
                            data: doctorRequestData
                          });
                        });
                  });
            } else if (localStorage.getItem('referralSms')) {
              this.RT.navigate([`/profile/referral`])
                .then(
                  () => {
                    localStorage.removeItem('referralSms');
                  }
                );
            } else if (localStorage.getItem('labOrder')) {
              this.RT.navigate(['laboratory']);
            } else if (currentUser.isClinicAdmin) {
              this.RT.navigate(['clinic']);
            } else {
              this.RT.navigate(['/']);
            }
          } else if (redirectToPharma) {
            localStorage.removeItem('redirect')
            window.location.href = this.generateRedirectUrl(redirectToPharma, token.accessToken);
          }
        }
      }, () => {

      });
  }

  generateRedirectUrl(redirectUrl: string, token: string): string {
    let parsedRedirect = decodeURIComponent(redirectUrl);
    if (parsedRedirect.includes('?')) {
      return parsedRedirect + `&token=${encodeURIComponent(token)}`
    }
    return parsedRedirect + `?token=${encodeURIComponent(token)}`
  }


  public setHeaders() {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Lang', this.LS.getLang());
  }

  public login(userName: any, password?: any, code?: string) {
    let data = {
      'UserName': userName
    };
    if (password) {
      data['Password'] = password;
    }
    if (code) {
      data['Code'] = code;
    }
    this.setHeaders();
    const headers = this.headers;

    return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.AUTH), data, { headers })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public logout() {
    this.http.put(`${this.apiUrl}/auth/logout`, null).subscribe();
    this.tbcConnectService.sendLogout();
    localStorage.clear();
    this.angularFireAuth.signOut().then(res => {
    });
    this.authorized = false;
    this.RT.navigate(['/']);
    this.userService.logout();
    this.logoutSSOUser();
  }

  public rememberMe(rememberMe?) {
    localStorage.setItem(CONFIG.API.TAGS.USER_REMEMBER, rememberMe ? rememberMe : '');
  }

  public getRememberMe() {
    const rememberMe = localStorage.getItem(CONFIG.API.TAGS.USER_REMEMBER);
    return rememberMe ? rememberMe : '';
  }

  logoutSSOUser() {
    this.ssoFrame.value.contentWindow.postMessage({
      action: 'remove',
      data: 'accessToken'
    }, 'https://sso.redmed.ge');
  }

  private buildURL(controller, method, instance = 'UserUrl') {
    return CONFIG.API.REST.API_URLS[instance] + CONFIG.COMMON.DS + method;
  }

  private sendTokenToSSO(token) {
    this.ssoFrame.value.contentWindow.postMessage({
      action: 'save',
      data: token
    }, 'https://sso.redmed.ge');
  }
}
