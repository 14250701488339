import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getNotificationBadges(): Observable<any> {
    return this.http.get(`${this.apiUrl}/notification/get_notification_badges`)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  logChatNotification(orderId: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/notification/log_chat_notification`, {
      OrderId: Number.parseInt(orderId)
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getChatMessages(orderId) {
    return this.http.get(`${this.apiUrl}/orders/chat_history_availability/${orderId}`)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
