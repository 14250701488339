import { Component, OnInit } from '@angular/core';
import { SuccessService } from '../../services/success.service';

@Component({
  selector: 'app-global-success-popup',
  templateUrl: './global-success-popup.component.html',
  styleUrls: ['./global-success-popup.component.scss']
})
export class GlobalSuccessPopupComponent implements OnInit {
  successPopupText$ = this.successService.getSuccessPopupText
  failPopupText$ = this.successService.getFailPopupText
  constructor(private successService: SuccessService) { }

  

  ngOnInit(): void {
  }

}
