import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  apiUrl = environment.apiUrl;
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getOrderDocument(orderId: any, params?: HttpParams): Observable<any> {
    return this.http.get(`${this.apiUrl}/medical-documents/document/${orderId}`, {params})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  editConsultationCard(value: any, orderId): Observable<any> {
    return this.http.post(`${this.apiUrl}/medical-documents/edit-consultation-card/${orderId}`, value)
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  getDiagnosisSuggestions(params: HttpParams): Observable<any> {
    return this.http.get(`${this.apiUrl}/medical-documents/suggest-icd-10-code`, {params})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );

  }

  sendDocumentToUser(documentTypeId: number, orderId: string) {
    return this.http.post(`${this.apiUrl}/medical-documents/send-documentation-to-user/${orderId}`, {documentTypeId: documentTypeId})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  sendDocumentViaEmail(documentTypeToSend: number, orderId: string) {
    return this.http.post(`${this.apiUrl}/notification/send-documentation-email/${orderId}`, {documentTypeToSend: documentTypeToSend})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  getDocumentationEmailInfo(documentTypeToSend: number, orderId: string) {
    return this.http.post(`${this.apiUrl}/notification/documentation-email-info/${orderId}`, {documentTypeToSend: documentTypeToSend})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  editFormOneHundred(value: any, orderId: number) {
    return this.http.post(`${this.apiUrl}/medical-documents/edit-form-one-hundred/${orderId}`, value)
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  addNewDocument(documentTypeId: any, orderId) {
    return this.http.post(`${this.apiUrl}/medical-documents/add-document-for-doctor/${orderId}`, {documentTypeId: documentTypeId})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }

  downloadReceipt(downloadUrl: any) {
    return this.http.get(`${this.baseUrl}${downloadUrl}`, {responseType: 'blob'})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }
}
