<div>
    <mat-icon (click)="closeDialog()" class="X-icon">close</mat-icon>
</div>
<div class="dialog-wrapper">
  <span class="dialog-wrapper-head-text">
    {{ 'enter-one-time-code' | translate | uppercase }}
  </span>
  <div class="otp-wrapper">
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)"  [config]="config"></ng-otp-input>
    <mat-error *ngIf="wrongCode" style="margin-top: 14px;">{{'sms-code-is-incorrect' | translate}}</mat-error>
  </div>
  <div class="dialog-wrapper-resend">
    <div class="dialog-wrapper-resend-button svg-24" (click)="resendCode()" [class.rotate]="isRotating" [inlineSVG]="'assets/img/replay.svg'" ></div>
    <span class="dialog-wrapper-resend-text">{{ 'resend' | translate }}</span>
    <div *ngIf="!timeIsUp">
      <span class="dialog-wrapper-resend-text">0{{ timerValue.minutes }}:</span>
      <span class="dialog-wrapper-resend-text">{{ timerValue.seconds > 9 ? timerValue.seconds : '0'+timerValue.seconds }}</span>
    </div>
  </div>
</div>