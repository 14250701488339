<div class="container">
    <div class="row">
        <div class="w-100">
            <div *ngIf="!gotInsuredUsers" style="width: 100%" [@slideInOut]>
                <ng-container *ngTemplateOutlet="insurance"></ng-container>
            </div>
            <div *ngIf="gotInsuredUsers" style="width: 100%" [@slideInOut]>
              <ng-container *ngTemplateOutlet="insuredUsers"></ng-container>
          </div>
        </div>
    </div>
</div>        

<ng-template #insurance>
    <div class="insurance-wrapper">
      <div class="insurance-wrapper-info">
        <div (click)="back()" class="d-inline-flex align-items-center cursor-pointer p-1">
          <div [inlineSVG]="'assets/img/long-arrow-left.svg'" class="svg-24"></div>
        </div>
        <span>{{ 'insurance-information' | translate | uppercase}}</span>
        <div></div>
      </div>
      <div class="insurance-wrapper-main">
        <div class="insurance-wrapper-main-head">
          <div [inlineSVG]="'assets/img/shield-tick.svg'" class="svg-56"></div>
          <span class="insurance-wrapper-main-subtext">{{ 'enterInsuranceId' | translate }}</span>
        </div>
        <form class="insurance-wrapper-main-form" [formGroup]="formGroup" (ngSubmit)="openInsuranceProviders()">
            <div >
              <input #inputField formControlName="personalNumber" type="text"
                     [class.is-invalid]="formGroup?.touched && formGroup?.invalid && formGroup"
                     (blur)="isWriting = false"
                     class="standard-input id-number-input custom-number-input" [placeholder]="'personalNumber-placeholder' | translate">
              <!-- <div style="text-align: center;" class="id-number-error" *ngIf="formGroup?.touched && formGroup?.invalid && !isWriting"
                   [class.is-invalid]="formGroup?.touched && formGroup?.invalid">
                {{'personal-number-error' | translate}}
              </div> -->
            </div>
            <button 
              [ngClass]="{'button-invalid' : formGroup?.invalid}"
              [disabled]="clicked" 
              (submit)="openInsuranceProviders()">
              {{'next' | translate}}
            </button>
            <!-- <button (click)="openDialog()"></button> -->
          </form>
      </div>
    </div>
  </ng-template>

<ng-template #insuredUsers>
  <!-- <div class="insured-users-wrapper" *ngIf="insuredUsers">
    <div class="insured-user" *ngFor="let user of insuredUsers">
      <p>{{ user?.firstName }} {{ user?.lastName }}</p>
      <p>{{ 'personal' | translate }} N {{ user?.personalNumber }}</p>
    </div>
  </div> -->
  <div class="insured-users-wrapper">
    <div class="insured-wrapper-info">
      <div (click)="back(true)" class="d-inline-flex align-items-center cursor-pointer p-1">
        <div [inlineSVG]="'assets/img/long-arrow-left.svg'" class="svg-24"></div>
      </div>
      <span class="textt"> {{ 'insured' | translate | uppercase}}</span>
      <div></div>
    </div>
    <div class="insured-user-wrapper">
      <div style="max-width: 400px; width: 100%;">
      <div class="insured-user" *ngFor="let user of insuredUsers$ | async" style="margin-bottom:28px;">
        <p class="first-last-name">{{ user?.firstName }} {{ user?.lastName }}</p>
        <p class="personal-number">{{ 'personal' | translate }} {{ user?.personalNumber }}</p>
        <hr class="horizontal-line">
      </div>
    <button style="max-width: 400px; margin-top: 44px;" (click)="redirect()">
      {{ 'add' | translate }}
    </button>
  </div>
    </div>
  </div>
</ng-template>