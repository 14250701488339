import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  public selectedLanguage = localStorage.getItem('CurLang') || 'ka'

  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  ngOnInit() {
  }

  closeDialog(action?: boolean, onClose?: boolean) {
    if(this.data.singleButtonAction && action){
      this.dialogRef.close(this.data.singleButtonAction);
    }else{
      this.dialogRef.close();
    }
    if(this.data.offers && onClose){
      this.dialogRef.close(false);
    }
  }
}

export interface DialogData {
  message: string;
  primaryMessage?: string;
  secondaryMessage?: string;
  title?: string;
  buttonText?: string;
  needsAction: boolean;
  singleButtonAction?: boolean;
  visitTime?: any;
  decline: string;
  confirm: string;
  offers?: boolean;
}
