import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ShareModule} from '@ngx-share/core';
import {SharedModule} from '../../shared/modules/shared.module';
import { AddInsuranceComponent } from './add-insurance.component';
import { RouterModule } from '@angular/router';
import { NgOtpInputModule } from  'ng-otp-input';
import { InsuranceOtpVerifyComponent } from 'src/app/shared/dialogs/insurance-otp-verify/insurance-otp-verify.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';


const routes = [{
    path: '',
    component: AddInsuranceComponent
}]

@NgModule({
  declarations: [AddInsuranceComponent, InsuranceOtpVerifyComponent],
  imports: [
    CommonModule,
    SharedModule,
    ShareModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})

export class AddInsuranceModule {
}
