import {Injectable} from '@angular/core';

import {CONFIG} from '../core/config/config';

@Injectable()
export class LanguageService {
  public dLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;
  public cLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;

  constructor() {
  }

  getLang() {
    return this.getCachedLang();
  }

  getLangTitles() {
    return CONFIG.LANGS.LANG_DATA.LANGS_TITLES;
  }

  getCachedLang() {
    const cLang = localStorage.getItem('CurLang');
    if (cLang) {
      this.cLang = cLang;
    } else {
      this.setLang(this.dLang);
    }
    return this.cLang;
  }

  setLang(cLang) {
    this.cLang = cLang;
    // localStorage.setItem('CurLang', cLang);
  }
}
