import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'OrderStatus'})
export class OrderStatusPipe implements PipeTransform {
  transform(orderStatusId: number): string {

    const pending = [1, 2];
    const canceled = [4, 5, 6];
    const approved = [3, 8, 9, 11];
    const onMyWay = [13];
    const inProgress = [7];
    const finished = [10];
    let status;

    if (pending.find(s => s === orderStatusId)) {
      status = 'pending';
    } else if (canceled.find(s => s === orderStatusId)) {
      status = 'canceled';
    } else if (onMyWay.find(s => s === orderStatusId)) {
      status = 'on_my_way';
    } else if (inProgress.find(s => s === orderStatusId)) {
      status = 'in_progress';
    } else if (finished.find(s => s === orderStatusId)) {
      status = 'finished';
    } else {
      status = 'approved';
    }

    return status;
  }
}
