<h1 class="d-flex align-items-center justify-content-between mb-0 font-25" mat-dialog-title>
  <span class="font-weight-bold">{{data.title | translate}}</span>
  <span (click)="closeDialog(false)" [inlineSVG]="'assets/img/times1.svg'" class="svg-12 cursor-pointer"></span>
</h1>
<div id="{{data.error}}" class="font-16 py-5" mat-dialog-content>
  <span [ngClass]="{'text-success': data.isSuccess, 'text-danger': !data.isSuccess }"
        class="d-block">{{data.text | translate}}</span>
  <span *ngIf="data.amount" class="d-block">{{data.amount}}</span>
</div>
<div class="d-flex justify-content-center pb-3" mat-dialog-actions>
  <button (click)="closeDialog(false)">{{'ok' | translate}}</button>
</div>
