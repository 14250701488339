import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  searchForDoctors(searchString: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/search?${searchString}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: Error) => {
          return throwError(error);
        })
      );
  }
}
