<div class='header-navigation'>
  <div [class.invisible]="hideSearch" class="w-100 d-flex">
    <app-header-suggestions [mobileSizeScreen]="mobileSizeScreen"></app-header-suggestions>
  </div>
  <div class="wrapper">
    <ng-container *ngIf="!user && !mobileSizeScreen">
      <span
      routerLink="user/register"
      class="register"
      >
      <span>{{ "register" | translate }}</span>
    </span>
    <span routerLink="user/auth-user" class="login">
      <span>{{ "auth" | translate }}</span>
    </span>
      <!-- <mat-menu #menu="matMenu">
        <button
          class="menu-item"
          
            
          mat-menu-item
          [routerLink]="isDoctor ? 'user/register' : 'user/auth-user'"
        >
          <img src="/assets/icons/patient.png" alt="" class="imageClass" />
          <span>{{ "only-patient" | translate }}</span>
          <img src="/assets/icons/Stroke-6.png" alt="" class="img" />
        </button>
        <button
          [queryParams]="{ doctor: true }"
          class="menu-item"
          mat-menu-item
          [routerLink]="isDoctor ? 'user/register-doctor' : 'user/auth-user'"
        >
          <img src="/assets/icons/doctor.png" alt="" class="imageClass" />
          <span>{{ "only-doctor" | translate }}</span>
          <img src="/assets/icons/Stroke-6.png" alt="" class="img" />
        </button>
      </mat-menu> -->
    </ng-container>
    <ng-container *ngIf="user && !mobileSizeScreen">
      <span [routerLink]="user?.isDoctor ? 'profile/booking' : '/'"> </span>
      <span *ngIf="user?.isDoctor" routerLink="profile/doctor-services">
        <mat-icon svgIcon="stethoscope"></mat-icon>
        <span>{{ "services" | translate }}</span>
      </span>
      <span *ngIf="user?.isDoctor" routerLink="profile/doctor-calendar">
        <mat-icon svgIcon="calendar"></mat-icon>
        <span>{{ "calendar" | translate }}</span>
      </span>
      <span [matMenuTriggerFor]="bookingMenu">
          <span [inlineSVG]="'assets/img/header/calendar-small.svg'"
                class="svg-15 m-0"></span>
          <span>{{'booking' | translate}}</span>
        </span>
      <mat-menu #bookingMenu="matMenu">
        <button class="menu-item" mat-menu-item routerLink="profile/booking">
          <span>{{ "doctor's-consultation" | translate }}</span>
        </button>
        <button (click)="goToClinicPage()" class="menu-item" mat-menu-item>
          <span>{{ "clinic-bookings" | translate }}</span>
        </button>
        <!-- <button (click)="goToPharmaPage()" class="menu-item" mat-menu-item>
          <span>{{ "pharmacy-order" | translate }}</span>
        </button> -->
      </mat-menu>
      <span [matMenuTriggerFor]="menu">
          <mat-icon svgIcon='person'></mat-icon>
          <span>{{user.isDoctor ? ('account' | translate) : user.firstname}}</span>
        </span>
      <mat-menu #menu="matMenu">
        <button class="menu-item" mat-menu-item routerLink="profile/edit">
          <span>{{ "profile" | translate }}</span>
        </button>
        <button
          *ngIf="user?.isDoctor"
          class="menu-item"
          mat-menu-item
          routerLink="profile/doctor-edit"
        >
          <span>{{ "settings" | translate }}</span>
        </button>
        <button (click)="showIntercom()" class="menu-item" mat-menu-item>
          <span>{{'help' | translate}}</span>
        </button>
        <div (click)="$event.stopPropagation()" *ngIf="user.showInstantCallToggle" class="menu-item instant-call-switch"
             mat-menu-item>
          <mat-slide-toggle (change)="switchDoctorInstantCallStatus()" [(ngModel)]="user.isInstantCallEnabled"
                            color="accent" labelPosition="before">
            {{'quick-call' | translate}}
          </mat-slide-toggle>
        </div>
        <button (click)="logout()" class="menu-item" mat-menu-item>
          <span>{{'exit,-log-out' | translate}}</span>
        </button>
      </mat-menu>
    </ng-container>

    <button
    class="languageButton"
    *ngIf="!mobileSizeScreen"
    (click)="changeLanguage(getLanguageImg())"
  >
    {{ selectedLanguage == "ka" ? "ENG" : "ქარ" }}
  </button>
  </div>
<!--  <a *ngIf="!mobileSizeScreen" class='number-container' href="tel:+995 322 422 922">-->
<!--    <mat-icon svgIcon='phone'></mat-icon>-->
<!--    <span>2 422 922</span>-->
<!--  </a>-->
</div>