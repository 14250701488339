import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SeoOptimizationService {

  constructor(private meta: Meta,
              private title: Title,
              @Inject(DOCUMENT) private dom) {
  }

  setMetaTag(text: string): void {
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='description'");
    this.meta.removeTag("name='description'");
    this.meta.addTags([
      {
        property: 'og:description',
        content: text
      },
      {
        property: 'description',
        content: text
      },
      {
        name: 'description',
        content: text
      },
    ]);
  }

  setTitle(text: string): void {
    this.title.setTitle(text);
  }

  setCanonicalURL(url?: string) {
    // remove
    const canonical: any = document.querySelectorAll('link[rel="canonical"]');
    canonical[0]?.parentElement?.removeChild(canonical[0]);
    // set
    const canURL = url === undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }

}
