<!--<ng-container *ngIf="!spinnerService.spinner">-->
<!--    <div class="d-flex justify-content-between align-items-center border-bottom">-->
<!--        <span class="font-13">ინვოისი</span>-->
<!--        <div class="d-flex">-->
<!--            <button class="ml-2 d-flex align-items-center preview btn btn-link font-14" (click)="downloadInvoice()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/eye.svg'"></span> ნახვა-->
<!--            </button>-->
<!--            <button class="ml-2 d-flex align-items-center btn btn-link font-14" (click)="downloadInvoice()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/download.svg'"></span> გადმოწერა-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div *ngIf="documentsAvailability.consultationCard" class="d-flex justify-content-between align-items-center border-bottom">-->
<!--        <span class="font-13">კონსულტაციის ბარათი</span>-->
<!--        <div class="d-flex">-->
<!--            <button class="ml-2 preview btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.consultationCard.preview" (click)="previewPdfConsultationcard()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/eye.svg'"></span> ნახვა-->
<!--            </button>-->
<!--            <button class="ml-2 btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.consultationCard.download" (click)="downloadPdfConsultationcard()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/download.svg'"></span> გადმოწერა-->
<!--            </button>-->
<!--            <button class="btn btn-link font-14" disabled-->
<!--            *ngIf="documentsAvailability && !documentsAvailability.consultationCard.download && !documentsAvailability.consultationCard.preview">ამ ეტაპისთვის ფაილი არ არის დამატებული</button>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="d-flex justify-content-between align-items-center border-bottom">-->
<!--        <span class="font-13">ფორმა 100</span>-->
<!--        <div class="d-flex">-->
<!--            <button class="ml-2 preview btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.formOneHundred.preview" (click)="previewPdfFormOneHundred()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/eye.svg'"></span> ნახვა-->
<!--            </button>-->
<!--            <button class="ml-2 btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.formOneHundred.download" (click)="downloadPdfFormOneHundred()">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/download.svg'"></span> გადმოწერა-->
<!--            </button>-->
<!--            <button class="ml-2 request-doc btn btn-link font-14"-->
<!--                *ngIf="documentsAvailability && documentsAvailability.formOneHundred.requestDocument"-->
<!--                (click)="getRequsetDocument(documentsAvailability.formOneHundred.id)">დოკუმენტის მოთხოვნა</button>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div *ngIf="documentsAvailability.receiptOfPayment" class="d-flex justify-content-between align-items-center">-->
<!--        <span class="font-13">გადახდის ქვითარი</span>-->
<!--        <div class="d-flex">-->
<!--            <button class="ml-2 preview btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.receiptOfPayment.preview">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/eye.svg'"></span> ნახვა-->
<!--            </button>-->
<!--            <button class="ml-2 btn btn-link font-14" *ngIf="documentsAvailability && documentsAvailability.receiptOfPayment.download">-->
<!--                <span class="svg-12 mr-2" [inlineSVG]="'assets/img/download.svg'"></span> გადმოწერა-->
<!--            </button>-->
<!--            <button class="ml-2 request-doc btn btn-link font-14"-->
<!--                *ngIf="documentsAvailability && documentsAvailability.receiptOfPayment.requestDocument"-->
<!--                (click)="getRequsetDocument(documentsAvailability.receiptOfPayment.id)">დოკუმენტის მოთხოვნა</button>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-container>-->
<div *ngIf="spinnerService.spinner" class="mx-auto my-5 text-center">{{'loading' | translate}}...</div>


<ng-container *ngFor="let item of documentsAvailability | keyvalue">
  <div class="d-flex justify-content-between align-items-center border-bottom">
    <span class="font-13" style="width: 20%">{{item.key | document}}</span>
    <div class="d-flex" style="min-height: 61px">
      <button (click)="handleAction(item.key, 'add')" *ngIf="currentUser.isDoctor && item.value.addNew !== null"
              [disabled]="!item.value.addNew" class="ml-2 preview btn btn-link font-14">
        <span [inlineSVG]="'assets/img/plus.svg'" class="svg-12 mr-2"></span> {{'add-new' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'sendViaEmail')"
              *ngIf="item.value.sendViaEmail !== null"
              [disabled]="!item.value.sendViaEmail"
               class="ml-2 btn btn-link font-14">
        <span style="fill: #007bff" [inlineSVG]="'assets/img/email.svg'" class="svg-12 mr-2"></span> {{'send' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'preview')"
              *ngIf="item.value.preview !== null"
              [disabled]="!item.value.preview" class="ml-2 preview btn btn-link font-14">
        <span [inlineSVG]="'assets/img/eye.svg'" class="svg-12 mr-2"></span> {{'see' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'edit', item.value.sendToUser)"
              *ngIf="currentUser.isDoctor && item.value.edit !== null"
              [disabled]="!item.value.edit"
              class="ml-2 preview btn btn-link font-14">
        <span [inlineSVG]="'assets/img/pencil.svg'" class="svg-12 mr-2"></span> {{'note' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'send')" *ngIf="currentUser.isDoctor && item.value.sendToUser !== null"
              [disabled]="!item.value.sendToUser"
              class="ml-2 preview btn btn-link font-14"
              style="color: #DA1F3D">
        <span [inlineSVG]="'assets/img/paper-plane.svg'" class="svg-12 mr-2"></span> {{'send-to-patient' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'request')"
              *ngIf="!currentUser.isDoctor && item.value.requestDocument !== null"
              [disabled]="!item.value.requestDocument"
              class="ml-2 preview btn btn-link font-14"
              style="color: #DA1F3D">
        {{'request-document' | translate}}
      </button>
      <button (click)="handleAction(item.key, 'download')"
              *ngIf="item.value.download !== null"
              [disabled]="!item.value.download"
              class="ml-2 btn btn-link font-14">
        <span [inlineSVG]="'assets/img/download.svg'" class="svg-12 mr-2"></span> {{'download' | translate}}
      </button>
    </div>
  </div>
</ng-container>
