import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ErrorHandlerService} from "../services/error-handler.service";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandlerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let queryParams: HttpParams;
    queryParams = req.params;
    const token = localStorage.getItem('UserAccessToken');
    let headers: HttpHeaders = req.headers;
    headers = headers.set('Accept-Language', localStorage.getItem('CurLang'));
    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    const request = req.clone({
        headers,
        params: queryParams
      }
    );


    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      }));
  }
}
