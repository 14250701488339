<h1 class="d-flex align-items-center justify-content-between mb-0" mat-dialog-title>
  <span>{{'booking-details' | translate}}</span>
  <span (click)="(!data.freezeOrderReview) ? closeDialog() : null" [inlineSVG]="'assets/img/times1.svg'"
        [title]="((data.freezeOrderReview)?'please-rate-the-service':'') | translate"
        class="svg-12 cursor-pointer"></span>
</h1>

<div *ngIf="!orderDetail" class="text-center mt-4"> {{'loading' | translate}}..</div>

<div *ngIf="orderDetail" class="pt-0" mat-dialog-content>
  <div class="tab-container row no-gutters">
    <div (click)="(!data.freezeOrderReview) ? tab(1) : null" [class.active]="tabIndex == 1"
         [title]="((data.freezeOrderReview)?'please-rate-the-service':'') | translate"
         class="text-center col-4">
      {{'reservation' | translate}}
    </div>
    <!--გაუქმებულია ექიმის მიერ-->
    <div (click)="(orderDetail.orderStatusId!=6) ? tab(2) : null" *ngIf="userService.isDoctor() || data.isClinicAdmin"
         [class.active]="tabIndex == 2"
         [ngStyle]="{'cursor': orderDetail.orderStatusId!=6 ? 'pointer' : 'not-allowed'}"
         class="col-4 text-center">{{'payment-details' | translate}}
    </div>
    <!--მომსახურება უკუკავშირის ეტაპი -->
    <div (click)="orderDetail.isActiveReviewTab ? tab(3) : null" *ngIf="!userService.isDoctor() && !data.isClinicAdmin"
         [class.active]="tabIndex == 3"
         [ngStyle]="{'cursor': orderDetail.isActiveReviewTab ? 'pointer' : 'not-allowed'}"
         class="col-4 text-center">{{'evaluation' | translate}}
    </div>
    <div (click)="tab(4)" *ngIf="!data.isClinicAdmin" [class.active]="tabIndex == 4" class="col-4 text-center">
      {{'documentation' | translate}}
    </div>
    <div (click)="tab(5)" *ngIf="data.isClinicAdmin" [class.active]="tabIndex == 5" class="col-4 text-center">
      {{'patient-details' | translate}}
    </div>
  </div>

  <div *ngIf="tabIndex == 1">
    <div class="d-flex align-items-center">

      <ng-container [ngSwitch]="orderDetail.orderStatusId | OrderStatus">
        <ng-container *ngSwitchCase="'pending'">
          <div [inlineSVG]="'assets/img/dots.svg'"
               class="icon-status svg-12 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'canceled'">
          <div [inlineSVG]="'assets/img/times1.svg'"
               class="icon-status svg-12 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'approved'">
          <div [inlineSVG]="'assets/img/check.svg'"
               class="icon-status svg-12 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'on_my_way'">
          <div [inlineSVG]="'assets/img/userService.svg'"
               class="icon-status svg-13 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'in_progress'">
          <div [inlineSVG]="'assets/img/play.svg'"
               class="icon-status svg-12 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'finished'">
          <div [inlineSVG]="'assets/img/check.svg'"
               class="icon-status svg-12 d-flex align-items-center justify-content-center"
               title="{{ orderDetail.orderStatusName }}"></div>
        </ng-container>
        <ng-container *ngSwitchDefault>...</ng-container>
      </ng-container>
      <div>
        <b class="d-block font-14">{{ orderDetail.serviceName }}</b>
        <span *ngIf="userService.isDoctor()  || data.isClinicAdmin"
              class="text-muted d-block font-13">{{ orderDetail.patientName }}</span>
        <span *ngIf="!userService.isDoctor()  || data.isClinicAdmin"
              class="text-muted d-block font-13">{{ orderDetail.doctorName }}</span>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'status' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderDetail.orderStatusName }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'serviceType' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderDetail.serviceTypeName }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'time' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <span class="font-13 color-red">
          {{ orderDetail.appointmentDate }}, {{ orderDetail.appointmentTimeFrom }} - {{ orderDetail.appointmentTimeTo }}
        </span>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'price' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <span class="font-13 color-red">{{ orderDetail.price.toFixed(2) | number:'1.2-2' }} ₾</span>
      </div>
    </div>
    <div *ngIf="orderDetail.serviceTypeId === 2" class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'adress' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">
          {{ orderDetail.address }}
        </b>
      </div>
    </div>
    <div *ngIf="orderDetail.orderReason" class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'appointmentReason' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
                <span class="font-13">
                     {{ orderDetail.orderReason }}
                </span>
      </div>
    </div>
    <div *ngIf="orderDetail.additionalComment" class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13 d-block">{{'additional-comment' | translate}}:</span>
      </div>
      <div class="col-12">
        <b class="d-block font-14">{{ orderDetail.additionalComment }}</b>
      </div>
    </div>
    <div *ngIf="orderDetail.clinic" class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'clinic' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">
          {{ orderDetail.clinic.name }}
        </b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'reservation-number' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">
          {{ orderDetail.orderId }}
        </b>
      </div>
    </div>
    <div *ngIf="userService.isDoctor()" class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'patient-number' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <a href="tel:{{orderDetail.phone}}">
          <b class="font-14">
            {{ orderDetail.phone }}
          </b>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="tabIndex == 2" class="pb-5">
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'total-price' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <span class="font-13 color-red">{{ orderDetail.price }} ₾</span>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'fee' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <span class="font-13 color-red">- {{ orderDetail.totalCommission }} ₾</span>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'you-will-be-enrolled' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <span class="font-13 color-red">{{ orderDetail.recivePrice }} ₾</span>
      </div>
    </div>
  </div>
  <div *ngIf="tabIndex == 3" class="reviews">
    <div class="d-flex align-items-center justify-content-between review-head">
      <div *ngIf="userInfo" class="d-flex align-items-center justify-content-center">
        <figure class="m-0">
          <img loading="lazy" alt="" src="/assets/img/avatar.png">
        </figure>
        <div [ngStyle]="{'opacity': (reviewEditMode) ? 1 : 0.6, 'pointer-events': (reviewEditMode) ? 'auto' : 'none' }"
             class="d-flex">
          <div class="d-flex mr-3">
            <input [formControl]="showInitialName" [value]="false" id="fullName" name="userNameType" type="radio">
            <label class="font-15 font-weight-bold mb-0 pl-2" for="fullName">{{ userInfo.fullName }}</label>
          </div>
          <div class="d-flex">
            <input [formControl]="showInitialName" [value]="true" id="shortName" name="userNameType" type="radio">
            <label class="font-15 font-weight-bold mb-0 pl-2" for="shortName">
              {{ userInfo.firstname.charAt(0) }}. {{ userInfo.lastname.charAt(0) }}. ({{'initials' | translate}})
            </label>
          </div>
        </div>
      </div>
      <button (click)="startEditReview()"
              *ngIf="!reviewEditMode"
              [inlineSVG]="'assets/img/pencil.svg'"
              class="edit-review svg-14 d-flex align-items-center justify-content-center"
              [title]="'edit' | translate"></button>
      <button (click)="saveReview()"
              *ngIf="reviewEditMode"
              [inlineSVG]="'assets/img/check1.svg'"
              class="edit-review svg-18 d-flex align-items-center justify-content-center"
              [title]="'add-a-rating' | translate"></button>
    </div>
    <div [ngStyle]="{'opacity': (reviewEditMode) ? 1 : 0.6, 'pointer-events': (reviewEditMode) ? 'auto' : 'none' }">
      <div class="star-container d-flex align-items-center">
        <div *ngFor="let i of [0,1,2]" class="star-block">
                <span class="d-block font-14 font-weight-bold">
                    <span *ngIf="i==0">{{'was-the-doctor-responsible?' | translate}}</span>
                    <span *ngIf="i==1">{{'was-the-doctor-punctual?' | translate}}</span>
                    <span *ngIf="i==2">{{'was-the-doctor-competent?' | translate}}</span>
                </span>
          <div class="d-flex align-items-center">
                    <span (click)="selectReviewRating(i, star)" *ngFor="let star of [1,2,3,4,5]"
                          [inlineSVG]="(reviewRatingItems[i].Rating >= star) ? 'assets/img/star-full.svg' : 'assets/img/star.svg'"
                          class="svg-15 mr-2"></span>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group review-form-group">
          <textarea [formControl]="reviewComment" class="form-control textarea font-14" id="text" placeholder="Text"
                    type="text"></textarea>
          <label for="text">{{'comment' | translate}}</label>
        </div>
        <div class="form-group review-form-group">
          <textarea [formControl]="reviewAdvice" class="form-control textarea font-14" id="textRed" placeholder="Text"
                    type="text"></textarea>
          <label for="textRed">{{'write-to-redmed' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="tabIndex == 4" class="documentation">
    <app-documents-tab [orderId]="orderDetail.orderId"></app-documents-tab>
  </div>
  <div *ngIf="tabIndex == 5 && orderPatientDetails">
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'name' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.firstName }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'lastName' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.lastName }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'birthDate' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.birthDate }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'personalNumber' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.personalNumber }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'address' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.address }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'phone' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.phoneNumber }}</b>
      </div>
    </div>
    <div class="row no-gutters data-row">
      <div class="col-6">
        <span class="text-muted font-13">{{'citizenship' | translate}}:</span>
      </div>
      <div class="col-6 text-right">
        <b class="font-14">{{ orderPatientDetails.citizenship }}</b>
      </div>
    </div>
  </div>

</div>

<div *ngIf="tabIndex == 1" class="d-flex justify-content-center" mat-dialog-actions>

  <ng-container *ngIf="orderDetail && orderDetail.actionButtons">

    <ng-container *ngIf="orderDetail.actionButtons.confirm && (userService.isDoctor() || data.isClinicAdmin)">
      <button (click)="approveOrderByDoctorFromBookingModal(orderDetail.orderId)" class="primary">
        <span [inlineSVG]="'assets/img/check.svg'" class="svg-13 icon"></span>
        <span> {{'approve' | translate}} ({{ (orderDetail.confirmTime) ? orderDetail.confirmTime : '00.00' }}) </span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.onMyWay">
      <button (click)="onMyWayActionOrderFromModal(orderDetail.orderId)" class="primary">
        <span [inlineSVG]="'assets/img/userService.svg'" class="svg-13 icon"></span>
        <span>{{'onMyWay' | translate}}</span>
      </button>
    </ng-container>

    <!-- finish button -->
    <ng-container *ngIf="orderDetail.actionButtons.finishOrder && (userService.isDoctor()  || data.isClinicAdmin)">
      <button (click)="completeOrderByDoctorFromBookingModal(orderDetail.orderId)" class="finish">
        <span [inlineSVG]="'assets/img/check.svg'" class="svg-12 icon"></span>
        <span>{{'finish' | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.finishOrder && !userService.isDoctor()">
      <button (click)="completeOrderByPatientFromBookingModal(orderDetail.orderId)" class="finish">
        <span [inlineSVG]="'assets/img/check.svg'" class="svg-12 icon"></span>
        <span>{{'finish' | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.startService">
      <button (click)="serviceInProgressFromBookingModal(orderDetail.orderId)" class="primary">
        <span [inlineSVG]="'assets/img/play.svg'" class="svg-12 icon"></span>
        <span>{{'start' | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.audioCall || orderDetail.actionButtons.videoCall">
      <button (click)="startCallFromBookingModal(orderDetail)" class="primary">
        <span [inlineSVG]="'assets/img/phone.svg'" class="svg-16 icon call"></span>
        <span>{{'call' | translate}} {{(userService.isDoctor() ? 'to-the-patient' : 'to-the-doctor') | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.chat">
      <button (click)="startChatFromBookingModal(orderDetail.orderId)" class="secondary">
        <span [inlineSVG]="'assets/img/comment.svg'" class="svg-16 icon"></span>
        <span>{{(userService.isDoctor() || data.isClinicAdmin ? 'write-to-patient' : 'write-to-doctor') | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.chatForDoctors">
      <button (click)="startChatFromBookingModal(orderDetail.orderId, true)" class="secondary">
        <span [inlineSVG]="'assets/img/comment.svg'" class="svg-16 icon foreign"></span>
        <span>{{'write-to-patient' | translate}}</span>
      </button>
    </ng-container>

    <!--    <ng-container *ngIf="orderDetail.actionButtons.invoiceDownload">-->
    <!--      <button (click)="downloadInvoice()" class="secondary">-->
    <!--        <span [inlineSVG]="'assets/icons/pdf.svg'" class="svg-16 icon"></span>-->
    <!--        <span>ინვოისის ჩამოტვირთვა</span>-->
    <!--      </button>-->
    <!--    </ng-container>-->

    <ng-container *ngIf="orderDetail.actionButtons.support">
      <button (click)="openSupportFacebookUrl($event)" class="warning">
        <span [inlineSVG]="'assets/img/heart-broken.svg'" class="svg-18 icon"></span>
        <span>{{'help' | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.reject">
      <button (click)="rejectOrderByDoctorFromBookingModal(orderDetail.orderId)" class="light">
        <span [inlineSVG]="'assets/img/times1.svg'" class="svg-13 icon"></span>
        <span>{{'cancellation' | translate}}</span>
      </button>
    </ng-container>

    <ng-container *ngIf="orderDetail.actionButtons.cancel">
      <button (click)="cancelOrderFromBookingModal(orderDetail.orderId)" class="light">
        <span [inlineSVG]="'assets/img/times1.svg'" class="svg-13 icon"></span>
        <span>{{'cancellation' | translate}}</span>
      </button>
    </ng-container>

  </ng-container>
</div>
<div *ngIf="!data.isClinicAdmin" class="share-block">
  <div>
    <p>{{"share,-if-you-like-redmed's-service" | translate}}</p>
    <span>{{"by-pressing-buttonyou-can-share-your-opinion-about-redmed-with-your-friends.-shared-informationd-does-not-contain-details-of-your-reservation" | translate}}</span>
  </div>
  <button class="share-doctor" matRipple shareButton="facebook"
          url="{{baseUrl}}?ref=order">
    <mat-icon>share</mat-icon>
    {{'share' | translate}}
  </button>
</div>
