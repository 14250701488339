import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import * as moment from "moment";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ApiService } from "../../../../../services";

@Component({
  selector: "app-header-suggestions",
  templateUrl: "./header-suggestions.component.html",
  styleUrls: ["./header-suggestions.component.scss"],
})
export class HeaderSuggestionsComponent implements OnInit {
  public specialties: any[] = [];
  public doctors: {
    name: string;
    userId: number;
  }[] = [];
  public clinics: any[] = [];
  public providers: any[] = [];
  @Output() closeInput = new EventEmitter();
  @ViewChild("suggestionInput", { static: false })
  suggestionInput: ElementRef<HTMLInputElement>;
  suggestionFormControl = new FormControl(null);
  @Input() mobileSizeScreen: boolean = false;

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {}

  getCommonSuggestions() {
    if (this.suggestionFormControl.value) {
      return;
    }
    this.subscribeToSearchInput();
    this.apiService
      .getSuggestions(`text=&serviceTypeId=1`)
      .subscribe((response: any) => {
        this.specialties = response.Specialties;
        this.doctors = response.Doctors;
        this.providers = response.providers;
      });
  }

  goSearch(
    specId?: number,
    detailed: boolean = false,
    name?: any,
    clinic?: any,
    provider?: any
  ) {
    const data = this.suggestionFormControl.value;
    let searchDate = moment().format("DD-MM-YYYY");
    this.router.navigate(["/search"], {
      queryParams: {
        serviceType: 1,
        keyword:
          specId || clinic
            ? null
            : this.doctors.length && name
            ? name
            : this.doctors.length
            ? data
            : null,
        date: searchDate,
        specId:
          name || clinic
            ? 0
            : this.specialties.length && specId
            ? specId
            : specId === 0
            ? 0
            : 0,
        clinic: clinic ? clinic.id : null,
        providerId: provider ? provider.id : null,
      },
    });
  }

  subscribeToSearchInput() {
    this.suggestionFormControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        if (value.length < 3) {
          this.doctors = [];
          this.specialties = [];
          this.providers = [];
          if (value.length === 0) {
            this.getCommonSuggestions();
          }
          return;
        }
        this.apiService
          .getSuggestions(`text=${value}&serviceTypeId=1`)
          .subscribe((response: any) => {
            this.specialties = response["Specialties"];
            this.doctors = response["Doctors"];
            this.clinics = response["clinics"];
            this.providers = response["providers"];
          });
      });
  }

  emitCloseSuggestionInput($event: FocusEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    setTimeout(() => {
      this.closeInput.emit();
    }, 200);
  }
}
