import {HttpParams} from '@angular/common/http';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {FormOneHundredModel} from '../../models/form-one-hundred.model';
import {DocumentationService} from '../../services/documentation.service';
import {LoadingService} from '../../services/loading.service';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import {DatePipe} from "@angular/common";
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-form-one-hundred',
  templateUrl: './form-one-hundred.component.html',
  styleUrls: ['./form-one-hundred.component.scss']
})
export class FormOneHundredComponent implements OnInit {
  formOneHundredGroup: FormGroup;
  filteredOptions = [];
  isFormSaved = true;
  @ViewChild('appliedToDoctorAt')
  appliedToDoctorAt: ElementRef;
  public editorModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }]
      ]
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                formOneHundred: FormOneHundredModel,
                orderId: number,
                send: boolean
              },
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<FormOneHundredComponent>,
              private loadingService: LoadingService,
              private _snackBar: MatSnackBar,
              private documentationService: DocumentationService,
              private translate: TranslateService) {
    this.formOneHundredGroup = formBuilder.group({
      documentIssuedBy: formBuilder.control(null),
      documentDestinationInfo: formBuilder.control(null),
      fullName: formBuilder.control(null),
      address: formBuilder.control(null),
      occupationAndPosition: formBuilder.control(null),
      appliedToDoctorAt: formBuilder.control(null),
      sentToHospitalAt: formBuilder.control(null),
      placedInHospitalAt: formBuilder.control(null),
      dischargedFromHospitalAt: formBuilder.control(null),
      assessmentFinalDiagnosis: formBuilder.control([]),
      assessmentFinalDiagnosisControl: formBuilder.control(null),
      pastDiseases: formBuilder.control(null),
      subjectiveBriefAnamnesis: formBuilder.control(null),
      objectiveDiagnosticExaminationsConsultations: formBuilder.control(null),
      diseaseProgression: formBuilder.control(null),
      treatmentCarriedOut: formBuilder.control(null),
      conditionSendingToHospital: formBuilder.control(null),
      conditionDischargeFromHospital: formBuilder.control(null),
      planningMedicalRecommendations: formBuilder.control(null),
      physicianSpecialist: formBuilder.control(null),
      documentIssuedAt: formBuilder.control(null)
    });
  }

  ngOnInit() {
    this.formOneHundredGroup.get('assessmentFinalDiagnosisControl').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value && value.length > 2) {
          this._filter(value).subscribe(data => {
            this.filteredOptions = data;
            this.filterOptions();
          });
        }
      });
    this.formOneHundredGroup.patchValue(this.data.formOneHundred);
    if (this.data.formOneHundred.appliedToDoctorAt) {
      this.formOneHundredGroup.get('appliedToDoctorAt').setValue(moment(this.data.formOneHundred.appliedToDoctorAt, 'DD/MM/YYYY').toDate());
    }
    if (this.data.formOneHundred.sentToHospitalAt) {
      this.formOneHundredGroup.get('sentToHospitalAt').setValue(moment(this.data.formOneHundred.sentToHospitalAt, 'DD/MM/YYYY').toDate());
    }
    if (this.data.formOneHundred.placedInHospitalAt) {
      this.formOneHundredGroup.get('placedInHospitalAt').setValue(moment(this.data.formOneHundred.placedInHospitalAt, 'DD/MM/YYYY').toDate());
    }
    if (this.data.formOneHundred.dischargedFromHospitalAt) {
      this.formOneHundredGroup.get('dischargedFromHospitalAt').setValue(moment(this.data.formOneHundred.dischargedFromHospitalAt, 'DD/MM/YYYY').toDate());
    }

    this.checkForChanged();
  }

  fillDateFields() {
    if (this.formOneHundredGroup.get('appliedToDoctorAt').value) {
      this.formOneHundredGroup.get('appliedToDoctorAt').setValue(moment(this.formOneHundredGroup.get('appliedToDoctorAt').value, 'DD/MM/YYYY').toDate());
    }
    if (this.formOneHundredGroup.get('sentToHospitalAt').value) {
      this.formOneHundredGroup.get('sentToHospitalAt').setValue(moment(this.formOneHundredGroup.get('sentToHospitalAt').value, 'DD/MM/YYYY').toDate());
    }
    if (this.formOneHundredGroup.get('placedInHospitalAt').value) {
      this.formOneHundredGroup.get('placedInHospitalAt').setValue(moment(this.formOneHundredGroup.get('placedInHospitalAt').value, 'DD/MM/YYYY').toDate());
    }
    if (this.formOneHundredGroup.get('dischargedFromHospitalAt').value) {
      this.formOneHundredGroup.get('dischargedFromHospitalAt').setValue(moment(this.formOneHundredGroup.get('dischargedFromHospitalAt').value, 'DD/MM/YYYY').toDate());
    }
  }

  checkForChanged() {
    this.formOneHundredGroup.valueChanges
      .subscribe(
        () => {
          this.isFormSaved = false;
        });
  }

  addDiagnosisToData($event: MatAutocompleteSelectedEvent) {
    let assessmentPreliminaryDiagnosisArray = this.formOneHundredGroup.get('assessmentFinalDiagnosis').value;
    if (!assessmentPreliminaryDiagnosisArray) {
      assessmentPreliminaryDiagnosisArray = [];
    }
    assessmentPreliminaryDiagnosisArray.push($event.option.value);
    const selectedDiagnosis = this.filteredOptions.find(option => option.id === $event.option.value);
    this.data.formOneHundred.assessmentFinalDiagnosisFullString +=
      (this.data.formOneHundred.assessmentFinalDiagnosisFullString.length ? `╟` : ``) + `${selectedDiagnosis.code} - ${selectedDiagnosis.name}`;
    this.formOneHundredGroup.get('assessmentFinalDiagnosisControl').setValue(null, {emitEvent: false});
    this.formOneHundredGroup.get('assessmentFinalDiagnosis').setValue(assessmentPreliminaryDiagnosisArray);
    this.filteredOptions.splice(this.filteredOptions.indexOf(selectedDiagnosis), 1);
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  downloadDocument() {
    this.editFormOneHundred('preview');
  }

  sendToPatient() {
    this.editFormOneHundred('send');
  }

  editFormOneHundred(action: string = null, closeAfterSave: boolean = false) {
    // const preliminaryDiagnosis = this.formOneHundredGroup.get('assessmentPreliminaryDiagnosis').value;
    this.loadingService.setApplicationLoading(true);
    if (this.formOneHundredGroup.get('appliedToDoctorAt').value) {
      this.formOneHundredGroup.get('appliedToDoctorAt').setValue(moment(this.formOneHundredGroup.get('appliedToDoctorAt').value).format('DD/MM/yyyy'));
    }
    if (this.formOneHundredGroup.get('sentToHospitalAt').value) {
      this.formOneHundredGroup.get('sentToHospitalAt').setValue(moment(this.formOneHundredGroup.get('sentToHospitalAt').value).format('DD/MM/yyyy'));
    }
    if (this.formOneHundredGroup.get('placedInHospitalAt').value) {
      this.formOneHundredGroup.get('placedInHospitalAt').setValue(moment(this.formOneHundredGroup.get('placedInHospitalAt').value).format('DD/MM/yyyy'));
    }
    if (this.formOneHundredGroup.get('dischargedFromHospitalAt').value) {
      this.formOneHundredGroup.get('dischargedFromHospitalAt').setValue(moment(this.formOneHundredGroup.get('dischargedFromHospitalAt').value).format('DD/MM/yyyy'));
    }

    this.documentationService.editFormOneHundred(this.formOneHundredGroup.value, this.data.orderId)
      .subscribe((response: any) => {
        this.loadingService.setApplicationLoading(false);
        this.isFormSaved = true;
        if (action) {
          this.dialogRef.close(action);
        } else if (closeAfterSave) {
          this.dialogRef.close();
        }
        this._snackBar.open(this.translate.instant('form-100-has-been-updated-successfully'), '', {
          duration: 3000,
          verticalPosition: 'bottom'
        });
        this.fillDateFields();
      }, () => {
        this.loadingService.setApplicationLoading(false);
        this.fillDateFields();
      });
  }

  private _filter(value: string): Observable<string[]> {
    const filterValue = value.toLowerCase();
    let params = new HttpParams();
    params = params.append('text', filterValue);
    return this.documentationService.getDiagnosisSuggestions(params)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  private filterOptions() {
    this.formOneHundredGroup.get('assessmentFinalDiagnosis').value.forEach(diagnosisId => {
      this.filteredOptions.splice(this.filteredOptions.findIndex(val => val.id === diagnosisId), 1);
    });
  }

  closeConsultationCard() {
    if (!this.isFormSaved) {
      const dialog = this.dialog.open(InfoDialogComponent, {
        data: {
          message: 'do-you-want-to-save-and-close-the-form?',
          needsAction: true
        }
      });
      dialog.afterClosed()
        .subscribe(
          (result) => {
            if (result) {
              this.editFormOneHundred(null, true);
            } else {
              this.closeDialog();
            }
          });
    } else {
      this.closeDialog();
    }
  }

  clearDiagnosis() {
    this.formOneHundredGroup.get('assessmentFinalDiagnosis').setValue([]);
    this.formOneHundredGroup.get('assessmentFinalDiagnosisControl').setValue(null);
    this.data.formOneHundred.assessmentFinalDiagnosisFullString = '';
  }

  handlePaste(event: ClipboardEvent) {
    event.preventDefault(); // Prevents the default paste behavior.
    // Retrieves the text from the clipboard.
    let text = event.clipboardData.getData('text/plain');
    // Directly replace '&amp;nbsp;' with a regular space.
    text = text.replace(/&amp;nbsp;/g, ' ');
    // Inserts the modified text into the editor.
    document.execCommand('insertText', false, text);
  }
}
