<h1 class="d-flex align-items-center justify-content-between mb-0 font-25" mat-dialog-title>
  <span class="font-weight-bold">{{data.title | translate}}</span>
  <span (click)="closeDialog(false)" [inlineSVG]="'assets/img/times1.svg'" class="svg-12 cursor-pointer"></span>
</h1>
<div class="font-16 py-5" mat-dialog-content>
  <span class="d-block text-danger">{{data.text | translate}}</span>
  <span *ngIf="data.amount" class="d-block">{{'cancellation-fee' | translate}}: {{data.amount}} ₾</span>
</div>
<div *ngIf="!data.error" class="d-flex justify-content-center pb-3" mat-dialog-actions>
  <button (click)="closeDialog(false)">{{'no' | translate}}</button>
  <button (click)="cancelOrderConfirmation(data.orderId)">{{'yes' | translate}}</button>
</div>
<div *ngIf="data.error" class="d-flex justify-content-center pb-3" mat-dialog-actions>
  <button (click)="closeDialog(false)">{{'approve' | translate}}</button>
</div>
