import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {connect, createLocalVideoTrack, Room} from 'twilio-video';
import {environment} from '../../../environments/environment';
import {InformationModalComponent} from '../../components/information-modal/information-modal.component';
import {UserService} from '../../services';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  private apiUrl = environment.apiUrl;
  private room: Room;

  private localVideoTrack = new BehaviorSubject(null);
  getLocalVideoTrack = this.localVideoTrack.asObservable();

  private participantArray = new BehaviorSubject(null);
  getParticipantArray = this.participantArray.asObservable();

  private videoCallParticipant = new BehaviorSubject(null);
  getVideoCallParticipant = this.videoCallParticipant.asObservable();


  constructor(private http: HttpClient,
              public dialogRef: MatDialog,
              private userService: UserService) {
  }

  startVideoCall(orderId: number | string) {
    this.getTwilioVideoAccessToken()
      .subscribe(
        (response: any) => {
          response = <{ token: string }>response;
          this.createRoom(response.token, orderId);
        });
  }

  setLocalVideoTrack(track) {
    this.localVideoTrack.next(track);
  }

  endUpCall() {
    if (this.room) {
      this.room.disconnect();
      this.room = null;
    }
  }

  messageInformationModal(message: string, isSuccess?: boolean): void {
    this.dialogRef.open(InformationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'information',
        text: message,
        isSuccess: isSuccess
      }
    });
  }

  private getTwilioVideoAccessToken(): Observable<any> {
    return this.http.get(`${this.apiUrl}/orders/call_token`)
      .pipe(
        map((token: string) => {
          return token;
        }),
        catchError((error: Error) => {
          return throwError(error);
        })
      );
  }

  private createRoom(token: any, orderId: string | number) {
    const connectRoomParams: { name: string, audio: boolean, video: any } = {
      name: String(orderId),
      audio: false,
      video: {width: 640}
    };
    navigator.mediaDevices.enumerateDevices()
      .then(
        (devices: any[]) => {
          if (!devices.find(device => device.kind === 'videoinput')) {
            delete connectRoomParams.video;
            connectRoomParams['video'] = false;
          }
          connect(token, connectRoomParams).then(room => {
            this.invokeRoomEvents(room);
            this.room = room;
            if (connectRoomParams.video) {
              this.createLocalVideoTrack();
            }
          }, () => {
            this.messageInformationModal('error-during-call');
          });
        });
  }

  private invokeRoomEvents(room: Room) {
    room.on('participantConnected', participant => {
      this.videoCallParticipant.next(participant);
    });

    room.on('participantDisconnected', participant => {
      this.endUpCall();
    });
    room.participants.forEach(participant => {
      this.videoCallParticipant.next(participant);
    });
    room.on('disconnected', participant => {
      const videoTracks = Array.from(participant.localParticipant.videoTracks.values())
        .map(publication => publication.track);
      videoTracks.forEach(publication => {
        const attachedElements = publication.detach();
        attachedElements.forEach(element => element.remove());
      });
      this.endUpCall();
    });
  }

  private createLocalVideoTrack() {
    createLocalVideoTrack({width: 250}).then(track => {
      this.setLocalVideoTrack(track);
    }, (error) => {

    });
  }
}
