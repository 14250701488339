import {ScrollingModule} from '@angular/cdk/scrolling';
import {DatePipe, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeKa from '@angular/common/locales/ka';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';

import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
/*  */
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ShareModule} from '@ngx-share/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateModule,
  TranslateService,
  TranslateStore
} from '@ngx-translate/core';

import 'moment/locale/ka';
import {InViewportModule} from 'ng-in-viewport';
import {IntercomModule} from 'ng-intercom';
import {FileSaverModule} from 'ngx-filesaver';
import {NgxIbanModule} from 'ngx-iban';
import {LottieModule, LottieTransferState} from 'ngx-lottie';
import {QuillModule} from 'ngx-quill';
/* plugins */
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BookingModalComponent} from './components/booking-modal/booking-modal.component';
import {DocumentsTabComponent} from './components/booking-modal/documents-tab/documents-tab.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {DeactivationSmsModalComponent} from './components/deactivation-sms-modal/deactivation-sms-modal.component';
import {IncomingCallDialogComponent} from './components/incoming-call-dialog/incoming-call-dialog.component';
import {InformationModalComponent} from './components/information-modal/information-modal.component';
import {OrderCreateTimeoutModalComponent} from './components/order-create-timeout-modal/order-create-timeout-modal.component';
import {PendingModalComponent} from './components/pending-modal/pending-modal.component';
import {NoDocumentationDialogComponent} from './dialogs/no-documentation-dialog/no-documentation-dialog.component';
import {OtpSmsDialogComponent} from './dialogs/otp-sms-dialog/otp-sms-dialog.component';
import {PasswordDialogComponent} from './dialogs/password-dialog/password-dialog.component';
import {SuccessDialogComponent} from './dialogs/success-dialog/success-dialog.component';

import {AuthGuard, DoctorGuard, PatientGuard} from './guards';
import * as Helpers from './helpers/';
import {NumericDirective} from './helpers/numeric.directive';
import {I18nModule} from './i18n/i18n.module';
import {OrderStatusPipe} from './pipes/order-status.pipe';
import {SanitizePipe} from './pipes/sanitize.pipe';
/*  */
/*  */
import * as Services from './services/';
import {UserService} from './services/';
import {BackwardService} from './services/backward.service';
import {DoctorService} from './services/doctor.service';
import {PageManipulationUtilsService} from './services/page-manipulation-utils.service';
import {ProductService} from './services/product.service';
import {RegionService} from './services/region.service';
import {SearchService} from './services/search.service';
import {SpecialtyService} from './services/specialty.service';
import {DoctorSlotDialogComponent} from './shared/components/doctor-slot-dialog/doctor-slot-dialog.component';
import {DownloadAppModule} from './shared/components/download-app/download-app.module';
import {InProgressCallComponent} from './shared/components/in-progress-call/in-progress-call.component';
import {InvoiceDialogComponent} from './shared/components/invoice-dialog/invoice-dialog.component';
import {PromoCodeComponent} from './shared/components/promo-code/promo-code.component';
import {RedmedLoadingComponent} from './shared/components/redmed-loading/redmed-loading.component';
import {ConsultationCardComponent} from './shared/dialogs/consultation-card/consultation-card.component';
import {CovidWarningComponent} from './shared/dialogs/covid-warning/covid-warning.component';
import {FormOneHundredComponent} from './shared/dialogs/form-one-hundred/form-one-hundred.component';
import {InsuranceProviderDialogComponent} from './shared/dialogs/insurance-provider-dialog/insurance-provider-dialog.component';
import {InsuranceUserPolicyDialogComponent} from './shared/dialogs/insurance-user-policy-dialog/insurance-user-policy-dialog.component';
import {InsuranceUserTokenVerifyDialogComponent} from './shared/dialogs/insurance-user-token-verify-dialog/insurance-user-token-verify-dialog.component';
import {PromoPasswordDialogComponent} from './shared/dialogs/promo-password-dialog/promo-password-dialog.component';
import {PromoSuccessDialogComponent} from './shared/dialogs/promo-success-dialog/promo-success-dialog.component';
import {RequestDoctorDialogComponent} from './shared/dialogs/request-doctor-dialog/request-doctor-dialog.component';
import {SendDocumentViaEmailDialogComponent} from './shared/dialogs/send-document-via-email-dialog/send-document-via-email-dialog.component';
import {DecimalDirective} from './shared/directives/decimal.directive';
import {MobileNumberDirective} from './shared/directives/mobile-number.directive';
import {HttpTokenInterceptor} from './shared/Interceptors/http.interceptor';
import {MessagingService} from './shared/messaging.service';
import {SharedModule} from './shared/modules/shared.module';
import {DocumentPipe} from './shared/pipes/document.pipe';
import {ErrorHandlerService} from './shared/services/error-handler.service';
import {InvoiceService} from './shared/services/invoice.service';
import {LoadingService} from './shared/services/loading.service';
import {NotificationService} from './shared/services/notification.service';
import {OrderService} from './shared/services/order.service';
import {ReferralService} from './shared/services/referral.service';
import {TwilioService} from './shared/services/twilio.service';
import {VideoCallService} from './shared/services/video-call.service';
import {TbcConnectService} from "./services/tbc-connect.service";
import {HelpCrunchService} from './shared/services/help-crunch.service';
import {OrderSuccessDialogComponent} from "./dialogs/order-success-dialog/order-success-dialog.component";
import {CalendarDialogComponent} from './dialogs/calendar-dialog/calendar-dialog.component';
import {StepperService} from './services/stepper.service';
import {ChooseResearchDialogComponent} from "./dialogs/choose-research-dialog/choose-research-dialog.component";
import {catchError} from "rxjs/operators";
import {forkJoin, of} from "rxjs";
import { GlobalSuccessPopupComponent } from './shared/dialogs/global-success-popup/global-success-popup.component';
import { AddInsuranceComponent } from './pages/add-insurance/add-insurance.component';
import { AddInsuranceModule } from './pages/add-insurance/add-insurance.module';
import { NgOtpInputModule } from 'ng-otp-input';
import * as moment from 'moment-timezone';

moment.tz.setDefault('Asia/Tbilisi');
registerLocaleData(localeKa);

export function playerFactory() {
  return import('lottie-web');
}

export function initializeApp(userService: UserService) {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (localStorage.getItem('UserAccessToken')) {
        userService.getCurrentUser()
          .subscribe(
            (user: any) => {
              return resolve(true);
            },
            (error: Error) => {
              localStorage.removeItem('UserAccessToken');
              return resolve(false);
            }
          );
      } else {
        return resolve();
      }
    });
  };
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return ``;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SendDocumentViaEmailDialogComponent,
    BookingModalComponent,
    ConfirmationModalComponent,
    InformationModalComponent,
    OrderCreateTimeoutModalComponent,
    GlobalSuccessPopupComponent,
    OrderStatusPipe,
    DecimalDirective,
    MobileNumberDirective,
    RedmedLoadingComponent,
    PendingModalComponent,
    DeactivationSmsModalComponent,
    IncomingCallDialogComponent,
    InProgressCallComponent,
    InsuranceUserTokenVerifyDialogComponent,
    InsuranceProviderDialogComponent,
    InsuranceUserPolicyDialogComponent,
    InvoiceDialogComponent,
    DoctorSlotDialogComponent,
    PromoCodeComponent,
    RequestDoctorDialogComponent,
    PromoPasswordDialogComponent,
    PromoSuccessDialogComponent,
    InsuranceProviderDialogComponent,
    SanitizePipe,
    SuccessDialogComponent,
    OtpSmsDialogComponent,
    PasswordDialogComponent,
    NoDocumentationDialogComponent,
    DocumentsTabComponent,
    NumericDirective,
    DocumentPipe,
    ConsultationCardComponent,
    FormOneHundredComponent,
    CovidWarningComponent,
    OrderSuccessDialogComponent,
    CalendarDialogComponent,
    ChooseResearchDialogComponent
  ],
  imports: [
    // IntercomModule.forRoot({
    //   appId: 'y7fltka2', // from your Intercom config
    //   // appId: 'n0blsllf', // from your Intercom config
    //   updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    // }),
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserTransferStateModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AddInsuranceModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule.forRoot({
      player: playerFactory,
      useCache: true
    }),
    /* plugins */
    QuillModule.forRoot(),
    NgxIbanModule,
    BrowserAnimationsModule,
    // NoopAnimationsModule,
    MatButtonModule,
    MatListModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatIconModule,
    MatRadioModule,
    ScrollingModule,
    MatRippleModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot(),
    MatExpansionModule,
    MatBadgeModule,
    InViewportModule,
    MatButtonToggleModule,
    FileSaverModule,
    MatExpansionModule,
    ShareModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    SharedModule,
    DownloadAppModule,
    I18nModule,
    MatSidenavModule,
    NgOtpInputModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [UserService],
      multi: true
    },
    {
      provide: MissingTranslationHandler,
      useClass: MyMissingTranslationHandler
    },
    LottieTransferState,
    TwilioService,
    SearchService,
    ProductService,
    RegionService,
    SpecialtyService,
    TranslateService,
    TranslateStore,
    Helpers.RouterHelper,
    Services.LanguageService,
    Services.AuthService,
    Services.ApiService,
    UserService,
    AuthGuard,
    DoctorGuard,
    PatientGuard,
    MatDialog,
    BookingModalComponent,
    DoctorService,
    BackwardService,
    PageManipulationUtilsService,
    LoadingService,
    MessagingService,
    VideoCallService,
    InvoiceService,
    OrderService,
    ErrorHandlerService,
    SpecialtyService,
    NotificationService,
    ReferralService,
    TbcConnectService,
    HelpCrunchService,
    StepperService,
    DatePipe,
    {provide: LOCALE_ID, useValue: 'ka-GE'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {provide: MAT_DATE_LOCALE, useValue: 'ka-GE'}
  ],
  exports: [
    SanitizePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
