import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ReferralModel} from "../models/referral.model";

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getUserReferralCodes(): Observable<ReferralModel | ReferralModel[]> {
    return this.http.get(`${this.apiUrl}/referral/codes_of_referrer_to_invite_others`)
      .pipe(
        map((response: ReferralModel | ReferralModel[]) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  regenerateUserReferralCode(oldReferral: { ReferralCode: string }): Observable<string> {
    // const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.post(`${this.apiUrl}/referral/regenerate_referral_code`, oldReferral)
      .pipe(
        map((response: string) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getUserReferralHistory(): Observable<any> {
    return this.http.get(`${this.apiUrl}/referral/user_benefit_history_web`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getUsableCodesForUser(): Observable<any> {
    return this.http.get(`${this.apiUrl}/referral/usable_codes_for_current_user`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  validateReferralCode(value: any) {
    return this.http.post(`${this.apiUrl}/referral/validate_referral_code`, value)
      .pipe(
        map((response: string) => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
