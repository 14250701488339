<div [formGroup]="formOneHundredGroup" class="consultation-card__container">
  <button (click)="closeConsultationCard()" class="close-button" mat-icon-button>
    <mat-icon>clear</mat-icon>
  </button>
  <div class="consultation-card__heading">
    <img loading="lazy" [src]="'assets/img/logo.svg'" alt="logo" class="logo cursor-pointer mr-3">
  </div>
  <div class="consultation-card__redmed-titles"></div>
  <div class="consultation-card__document-title">
    {{'notice' | translate}} <br> {{'about-the-state-of-health' | translate}}
  </div>
  <p class="document-title required">
    {{'1.-name-of-the-institution-issuing-the-certificate-or-last-name,-first-name,-specialty-granted-by-the-state-certificate,-number-of-the-state-certificate-and/or-contact-information-of-the-doctor-specialist' | translate}}
  </p>
  <textarea cols="30" formControlName="documentIssuedBy" id="clinicName" name="" rows="10"></textarea>
  <p class="document-title required">{{'2.-name-of-the-institution,-address-where-the-notification-is-sent' | translate}}</p>
  <textarea cols="30" formControlName="documentDestinationInfo" name="" rows="10"></textarea>
  <div class="patient-info__container">
    <span class="required">{{'3.-name-and-surname-of-the-patient' | translate}}</span>
    <input formControlName="fullName" type="text">
  </div>

  <div class="patient-info__container">
    <span class="required">{{'4.-date-of-birth-(number/month/year)' | translate}}</span>
    <input [value]="data.formOneHundred.birthDate" readonly type="text">
  </div>

  <div class="patient-info__container ">
    <span class="required">{{'5.-personal-number-(to-be-filled-out-in-the-case-of-a-person-who-has-reached-the-age-of-16)' | translate}}</span>
    <input [value]="data.formOneHundred.personalNumber" readonly type="text">
  </div>

  <div class="patient-info__container">
    <span class="required">{{'6.-address' | translate}}</span>
    <input formControlName="address" type="text">
  </div>

  <p class="document-title required">
    {{'7.-workplace-and-position-(in-the-case-of-a-pupil/student-–-​​the-name-of-the-educational-institution/school-and-class/course-where-he/she-studies)' | translate}}
  </p>
  <textarea cols="30" formControlName="occupationAndPosition" id="workPlace" name="" rows="10"></textarea>

  <p class="document-title">{{'8.-dates:' | translate}}</p>
  <div class="patient-info__container">
    <span class="required">{{'a)-reffering-to-doctor' | translate}}</span>
    <div style="width: calc(100% - 250px)">
      <input (click)="appliedToDoctorAt.open()" [matDatepicker]="appliedToDoctorAt" style="margin-left: 0px"
             formControlName="appliedToDoctorAt" placeholder="{{'date'|translate}}" type="text">
      <mat-datepicker #appliedToDoctorAt></mat-datepicker>
    </div>
  </div>
  <div class="patient-info__container">
    <span>{{'b)-sending-to-hospital' | translate}}</span>
    <div style="width: calc(100% - 250px)">
      <input (click)="sentToHospitalAt.open()" [matDatepicker]="sentToHospitalAt" style="margin-left: 0px"
             formControlName="sentToHospitalAt" placeholder="{{'date'|translate}}" type="text">
      <mat-datepicker #sentToHospitalAt></mat-datepicker>
    </div>
  </div>
  <div class="patient-info__container">
    <span>{{'c)-hospitalization' | translate}}</span>
    <div style="width: calc(100% - 250px)">
      <input (click)="placedInHospitalAt.open()" [matDatepicker]="placedInHospitalAt" style="margin-left: 0px"
             formControlName="placedInHospitalAt" placeholder="{{'date'|translate}}" type="text">
      <mat-datepicker #placedInHospitalAt></mat-datepicker>
    </div>
  </div>
  <div class="patient-info__container">
    <span>{{'d)-discharge' | translate}}</span>
    <div style="width: calc(100% - 250px)">
      <input (click)="dischargedFromHospitalAt.open()" [matDatepicker]="dischargedFromHospitalAt" style="margin-left: 0px"
             formControlName="dischargedFromHospitalAt" placeholder="{{'date'|translate}}" type="text">
      <mat-datepicker #dischargedFromHospitalAt></mat-datepicker>
    </div>
  </div>

  <p class="document-title required">
    {{'9.-conclusion-on-the-state-of-health-or-complete-diagnosis-(main-disease,-co-morbidities,-complications)-indicating-the-appropriate-icd-10-code' | translate}}
  </p>
  <div class="consultation-card__diagnosis-details">
    <mat-form-field appearance="outline" class="example-full-width">
      <input [matAutocomplete]="auto"
             aria-label="Assessment Diagnosis"
             formControlName="assessmentFinalDiagnosisControl"
             matInput
             [placeholder]="'diagnosis' | translate"
             type="text">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addDiagnosisToData($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
          {{option.code}} - {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span (click)="clearDiagnosis()" class="clear-diagnosis">{{'clear' | translate}}</span>
    <div class="assessment-diagnosis-area">
      <span *ngFor="let diagnosis of data.formOneHundred.assessmentFinalDiagnosisFullString.split('╟')">
        {{diagnosis}}
      </span>
    </div>
  </div>

  <p class="document-title required">{{'10. Transferred diseases' | translate}}</p>
  <textarea cols="30" formControlName="pastDiseases" name="" rows="10"></textarea>

  <p class="document-title required">{{'brief-anamnesis' | translate}}</p>
  <textarea cols="30" formControlName="subjectiveBriefAnamnesis" name="" rows="10"></textarea>

  <p class="document-title required">{{'conducted-diagnostic-examinations-and-consultations' | translate}}</p>
  <textarea cols="30" formControlName="objectiveDiagnosticExaminationsConsultations" name="" rows="10"></textarea>

  <p class="document-title required">{{'course-of-illness' | translate}}</p>
  <textarea cols="30" formControlName="diseaseProgression" name="" rows="10"></textarea>

  <p class="document-title">{{'performed-treatment' | translate}}</p>
  <textarea cols="30" formControlName="treatmentCarriedOut" name="" rows="10"></textarea>


  <p class="document-title">{{'condition-when-sent-to-hospital' | translate}}</p>
  <textarea cols="30" formControlName="conditionSendingToHospital" name="" rows="10"></textarea>

  <p class="document-title">{{'condition-upon-discharge-from-hospital' | translate}}</p>
  <textarea cols="30" formControlName="conditionDischargeFromHospital" name="" rows="10"></textarea>

  <p class="document-title required">{{'medical-and-labor-recommendations' | translate}}</p>
  <quill-editor formControlName="planningMedicalRecommendations" [modules]="editorModules" format="html" (paste)="handlePaste($event)" [preserveWhitespace]="true" placeholder=""></quill-editor>

  <p class="document-title required">{{'medical-doctor-(doctor-specialist)' | translate}}</p>
  <textarea cols="30" formControlName="physicianSpecialist" name="" rows="10"></textarea>

  <div class="consultation-card__signature">
    <span>{{"doctor's-name-and-surname" | translate}}</span>
    <span>{{data.formOneHundred.physicianSpecialist}}</span>
  </div>
  <div class="consultation-card__actions">
    <button (click)="downloadDocument()" [disabled]="isFormSaved" matRipple>
      <span [inlineSVG]="'assets/img/eye.svg'" class="svg-12 mr-2"></span> {{'see' | translate}}
    </button>
    <button (click)="editFormOneHundred()" [disabled]="isFormSaved" matRipple>{{'save' | translate}}</button>
    <button (click)="closeDialog()" matRipple>{{'cancel' | translate}}</button>
    <button (click)="sendToPatient()" [disabled]="!data.send" matRipple><span
        [inlineSVG]="'assets/img/paper-plane.svg'"
        class="svg-12 mr-2"></span> {{'send-to-patient' | translate}}
    </button>
  </div>
</div>

