import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { catchError, map, publishReplay, refCount } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SpecialtyService {
  apiUrl = environment.apiUrl;
  private allSpecialties: Observable<any> = this.http.get<any>(`${this.apiUrl}/specialty/all`)
    .pipe(
      map(user => user?.data),
      publishReplay(1),
      refCount());

  constructor(private http: HttpClient,
    private route: ActivatedRoute) {
  }

  getPopularSpecialties(isInsurance: boolean = false): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/specialty/popular?is-insurance-bubble=${isInsurance}`)
      .pipe(
        map(user => user.data),
        publishReplay(1),
        refCount());
  }

  getAllSpecialties(searchText: string = '', clinicId: string = '', isInsurance: boolean = false): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/specialty/all?search=${searchText}&clinic-id=${clinicId}&is-insurance-bubble=${isInsurance}`)
      .pipe(
        map(user => user?.data.map(item => ({
          ...item,
          id: item?.specialityId
        }))),
        publishReplay(1),
        refCount());
  }

  getAllAdminSpecialties(searchText: string = ''): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/specialty/admin-specialty?search=${searchText}`)
      .pipe(
        map(user => user?.data.map(item => ({
          ...item,
          id: item?.specialityId
        }))),
        publishReplay(1),
        refCount());
  }

  getSpecialtyDescription(specialtyId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/specialty/specialty_description/${specialtyId}`)
      .pipe(
        map(result => {
          return result
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
  }

  getAllSpecialtiesForAdmin(searchText: string = ''): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/specialty/admin-specialty?search=${searchText}`)
      .pipe(
        map(user => user?.data.map(item => ({
          ...item,
          id: item?.specialityId
        }))),
        publishReplay(1),
        refCount());
  }

}
