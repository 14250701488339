import { Injectable } from "@angular/core";
import { CONFIG } from "../core/config/config";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { LanguageService } from "./language.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AddPatientModel } from "../models/add-patient.model";
import { AddUserOrderPhoneNumberModel } from "../models/add-user-order-phone-number.model";
import { GetDoctorServiceByDoctorId } from "../models/get-doctor-service-by-doctor-id.model";
import { environment } from "src/environments/environment";
import { TbcConnectService } from "./tbc-connect.service";

@Injectable()
export class ApiService {
  private headers: any = {};
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private LS: LanguageService,
    private tbcConnectService: TbcConnectService
  ) {
    this.setHeaders();
  }

  public setHeaders() {
    this.headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set(
        "Authorization",
        (localStorage.getItem(CONFIG.API.TAGS.USER_TOKEN)
          ? "Bearer " + localStorage.getItem(CONFIG.API.TAGS.USER_TOKEN)
          : ""
        ).toString()
      )
      .set("Lang", this.LS.getLang());
  }

  public registerUser(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.REGISTER_USER
      ),
      data,
      { headers }
    );
  }

  public forgotPassword(phoneNumber: any, password: any, smsCode: any) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.patch(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.FORGOT_PASSWORD
      ),
      {
        phonenumber: phoneNumber,
        password: password,
        smsCode: smsCode,
      },
      { headers }
    );
  }

  /* check if user is doctor and is published */
  public checkDoctor() {
    this.setHeaders();
    const headers = this.headers;
    const url =
      CONFIG.API.REST.API_URLS["ApiUrl"] +
      "/" +
      CONFIG.API.METHODS.USERS +
      "/" +
      CONFIG.API.METHODS.CHECK_DOCTOR;
    return this.http.get(url, { headers });
  }

  public changePassword(oldPassword: string, newPassword: string) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.patch(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.CHANGE_PASSWORD
      ),
      {
        NewPassword: newPassword,
        OldPassword: oldPassword,
      },
      { headers }
    );
  }

  public sendSms(phoneNumber, index, isDoctor = false, recaptcha?: string) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.SEND_SMS),
      {
        phoneNumber: phoneNumber,
        index: index,
        isDoctor: isDoctor,
        platform: "web",
      },
      {
        headers: {
          ...headers,
          "g-recaptcha-response": recaptcha ? recaptcha : "",
        },
      }
    );
  }

  public sendSmsLogin(username: any) {
    this.setHeaders();
    let params = new HttpParams();
    params = params.append("username", username);
    return this.http.post(
      `${this.apiUrl}/users/v2/send-sms`,
      {},
      { params: params }
    );
  }

  public getCities() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.GET_CITIES),
      { headers }
    );
  }

  public getSpecialties() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_SPECIALTIES,
        "ApiUrl"
      ),
      { headers }
    );
  }

  public getAvailableSpecialties() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_AVAILABLE_SPECIALTIES
      ),
      {},
      { headers }
    );
  }

  public getGenders() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.GET_GENDERS),
      { headers }
    );
  }

  public getLanguages() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_LANGUAGE
      ),
      { headers }
    );
  }

  public getUserInfo() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_USER_INFO
      ),
      "",
      { headers }
    );
  }

  public checkPhone(phoneNumber, index, isDoctor = false, isRecovery = false) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.CHECK_PHONE),
      {
        phoneNumber: phoneNumber,
        index: index,
        isDoctor: isDoctor,
        isRecovery: isRecovery,
      },
      { headers }
    );
  }

  public checkEmail(email) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.CHECK_EMAIL),
      {
        Email: email,
      },
      { headers }
    );
  }

  public updateUser(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.UPDATE_USER),
      data,
      { headers }
    );
  }

  public getDoctorGeneralInfo() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_INFO
      ),
      {},
      { headers }
    );
  }

  public getDoctorAboutInfo() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_ABOUT_INFO
      ),
      {},
      { headers }
    );
  }

  public getDoctorEducation() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_EDUCATION
      ),
      {},
      { headers }
    );
  }

  public getDoctorWorkExperience() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_WORK
      ),
      {},
      { headers }
    );
  }

  public getDoctorStatus() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_STATUS
      ),
      {},
      { headers }
    );
  }

  public getDoctorSubmitStatus() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.CHECK_DOCTOR_STATUS
      ),
      null,
      { headers }
    );
  }

  public getDoctorSpecialties(params?: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_SPECIALTIES
      ),
      {},
      {
        headers,
        params,
      }
    );
  }

  public addDoctorSpeciality(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_SPECIALITY
      ),
      { DoctorSpeciality: data },
      { headers }
    );
  }

  public addDoctorGeneralInfo(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_INFO
      ),
      data,
      { headers }
    );
  }

  public addDoctorAboutInfo(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_ABOUT
      ),
      data,
      { headers }
    );
  }

  public editDoctorInfo(id , data){
    this.setHeaders()
    const headers = this.headers
    return this.http.put(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_DOCTOR_ABOUT
        +
        '/'
        +
        id
      ),
      data,
      { headers }
    );
  }

  public addDoctorWorkExperience(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_WORK
      ),
      data,
      { headers }
    );
  }

  public addDoctorEducation(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_EDUCATION
      ),
      data,
      { headers }
    );
  }

  public submitDoctorProfile() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.SUBMIT_DOCTOR_PROFILE
      ),
      null,
      { headers }
    );
  }

  public getCountry() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.GET_COUNTRY),
      { headers }
    );
  }

  public getEducationLevel() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_EDUCATION_LEVEL
      ),
      { headers }
    );
  }

  public deleteDoctorEducation(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.DELETE_DOCTOR_EDUCATION
      ),
      {
        doctorEducationId: id,
      },
      { headers }
    );
  }

  public deleteDoctorWorkExperience(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.DELETE_DOCTOR_WORK
      ),
      {
        workExperienceId: id,
      },
      { headers }
    );
  }

  public editDoctorWorkExperience(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_DOCTOR_WORK
      ),
      data,
      { headers }
    );
  }

  public editDoctorEducation(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_DOCTOR_EDUCATION
      ),
      data,
      { headers }
    );
  }

  public getServiceGroup(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_SERVICE_GROUP,
        "ProductUrl"
      ) +
        "?specialityId=" +
        id,
      { headers }
    );
  }

  public getServiceCategory(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_SERVICE_CATEGORY,
        "ProductUrl"
      ) +
        "?serviceGroupId=" +
        id,
      { headers }
    );
  }

  public getServiceType() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_SERVICE_TYPE,
        "ProductUrl"
      ),
      { headers }
    );
  }

  public getServiceType1(id?: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    console.warn(
      CONFIG.API.REST.API_URLS["ApiUrl"] +
        "/" +
        CONFIG.API.METHODS.SERVICE_TYPE_BY_USER +
        "/" +
        id
    );
    return this.http.get(
      CONFIG.API.REST.API_URLS["ApiUrl"] +
        "/" +
        CONFIG.API.METHODS.SERVICE_TYPE_BY_USER +
        "/" +
        id,
      { headers }
    );
  }

  public getSpecialityServiceConfig(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_SERVICE_SPECIALITY_CONFIG,
        "ProductUrl"
      ),
      data,
      { headers }
    );
  }

  public getDoctorServices(params?: HttpParams): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_DOCTOR_SERVICES,
          "ProductUrl"
        ),
        null,
        { headers, params }
      )
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  public getAllServices() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_ALL_SERVICES,
        "ProductUrl"
      ),
      { headers }
    );
  }

  public addDoctorService(data, params: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      data,
      {
        headers,
        params,
      }
    );
  }

  public editDoctorService(data, params: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      data,
      {
        headers,
        params,
      }
    );
  }

  public pauseDoctorService(id, endDate, params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.PAUSE_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      {
        DoctorServiceId: id,
        EndDate: endDate,
      },
      { headers, params }
    );
  }

  public stopDoctorService(id, params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.STOP_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      {
        DoctorServiceId: id,
      },
      { headers, params }
    );
  }

  public activeDoctorService(id, params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ACTIVE_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      {
        DoctorServiceId: id,
      },
      { headers, params }
    );
  }

  public deleteDoctorService(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.DELETE_DOCTOR_SERVICE,
        "ProductUrl"
      ),
      {
        DoctorServiceId: id,
      },
      { headers }
    );
  }

  public getDoctorCalendars(params?: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_CALENDAR_DATA,
        "ProductUrl"
      ),
      {},
      {
        headers,
        params,
      }
    );
  }

  public addDoctorCalendar(data: any, params?: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_CALENDAR,
        "ProductUrl"
      ),
      data,
      {
        headers,
        params,
      }
    );
  }

  public editDoctorCalendar(data: any, params?: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_CALENDAR,
        "ProductUrl"
      ),
      data,
      {
        headers,
        params,
      }
    );
  }

  public pauseDoctorCalendar(id, pauseType, params) {
    this.setHeaders();
    const headers = this.headers;
    const url =
      pauseType === 1
        ? CONFIG.API.METHODS.PAUSE_CALENDAR_TODAY
        : CONFIG.API.METHODS.PAUSE_CALENDAR_WEEK;
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, url, "ProductUrl"),
      {
        DoctorScheduleId: id,
      },
      { headers, params }
    );
  }

  public pauseDoctorCalendarManual(id,params) {
    this.setHeaders();
    const headers = this.headers;
    const url = CONFIG.API.METHODS.PAUSE_CALENDAR_MANUAL 
    return this.http.post(
      this.buildURL(CONFIG.API.CONTROLLERS.API, url, "ProductUrl"),
      {
        DoctorScheduleId: id,
      },
      { headers, params }
    );
  }

  public stopDoctorCalendar(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.STOP_CALENDAR,
        "ProductUrl"
      ),
      {
        DoctorScheduleId: id,
      },
      { headers }
    );
  }

  public activeDoctorCalendar(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ACTIVE_CALENDAR,
        "ProductUrl"
      ),
      {
        DoctorScheduleId: id,
      },
      { headers }
    );
  }

  public deleteDoctorCalendar(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.DELETE_CALENDAR,
        "ProductUrl"
      ),
      {
        DoctorScheduleId: id,
      },
      { headers }
    );
  }

  public getLocations() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_USER_ADDRESS_DATA,
        "UserUrl"
      ),
      {},
      { headers }
    );
  }

  public addLocation(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ADD_USER_ADDRESS,
        "UserUrl"
      ),
      data,
      { headers }
    );
  }

  public editLocation(data) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.EDIT_USER_ADDRESS,
        "UserUrl"
      ),
      data,
      { headers }
    );
  }

  public deleteLocation(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.DELETE_USER_ADDRESS,
        "UserUrl"
      ),
      {
        AddressId: id,
      },
      { headers }
    );
  }

  public setMainLocation(id) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.SET_MAIN_USER_ADDRESS,
        "UserUrl"
      ),
      {
        AddressId: id,
      },
      { headers }
    );
  }

  public getDoctorPublicProfile(params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_PUBLIC_PROFILE,
        "UserUrl"
      ),
      {
        headers,
        params,
      }
    );
  }

  public getDoctorRatingByCriterion(params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_RATING_CRITERION,
        "ProductUrl"
      ),
      {
        headers: headers,
        params: params,
      }
    );
  }

  public getDoctorRatingAndComments(params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_RATING_AND_COMMENTS,
        "ProductUrl"
      ),
      {
        headers: headers,
        params: params,
      }
    );
  }

  public getDoctorSumRating(params) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_SUM_RATING,
        "ProductUrl"
      ),
      {
        headers: headers,
        params: params,
      }
    );
  }

  createOrder(data: any, isDoctra: boolean = false, hasInsurance?: boolean) {
    this.setHeaders();
    let url
    if(isDoctra){
      hasInsurance ? url = CONFIG.API.METHODS.CREATE_DOCTRA_ORDER + '?is-insurance-bubble=true' : url = CONFIG.API.METHODS.CREATE_DOCTRA_ORDER
    }else {
      hasInsurance ? url = CONFIG.API.METHODS.CREATE_ORDER + '?is-insurance-bubble=true' : url = CONFIG.API.METHODS.CREATE_ORDER
    }
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API, url,
        "ApiUrl"
      ),
      data,
      {
        headers: headers,
      }
    );
  }

  // createOrder(data: any) {
  //   this.setHeaders();
  //   const headers = this.headers;
  //   return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.CREATE_ORDER, 'ProductUrl'), data, {
  //     headers: headers
  //   });
  // }

  getOrderDates(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.ORDER_DATES,
        "ApiUrl"
      ),
      { headers }
    );
  }

  getOrdersByDate(params: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_ORDERS_BY_DATE,
          "ApiUrl"
        ) + params,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getOrderReasons(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_ORDER_REASONS,
          "ProductUrl"
        ),
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addOrderReason(data: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_ORDER_REASON,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addOrderQuestions(data: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_ORDER_QUESTION,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDraftOrder(data: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_DRAFT_ORDER,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addPatient(data: AddPatientModel): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_PATIENT,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getInsurance(): Observable<any> {
    // this.setHeaders();
    // const headers = this.headers;
    // return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.GET_INSURANCE, 'ProductUrl'), {
    //   headers: headers
    // }).pipe(
    //     map(response => {
    //         return response;
    //     })
    // );
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.APP_DATA,
        CONFIG.API.METHODS.GET_INSURANCE,
        "ProductUrl"
      ),
      { headers }
    );
  }

  addInsurance(data: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_INSURANCE,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addUserOrderPhoneNumber(data: AddUserOrderPhoneNumberModel): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_USER_ORDER_PHONE_NUMBER,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addOrderAddress(data: {
    AddressId: number;
    OrderId: number;
  }): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_ORDER_ADDRESS,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addOrderDescription(data: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_ORDER_DESCRIPTION,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDoctorIban() {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.APP_DATA,
        CONFIG.API.METHODS.GET_DOCTOR_IBAN,
        "ProductUrl"
      ),
      null,
      { headers }
    );
  }

  addDoctorIban(data: { Iban: any }) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_DOCTOR_IBAN,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getUserAllOrder(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_USER_ALL_ORDER,
        "UserUrl"
      ),
      {},
      { headers }
    );
  }

  getDoctorAllOrderResp(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_ALL_ORDER_RESP,
        "UserUrl"
      ),
      {},
      { headers }
    );
  }

  makePaymentTest(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.MAKE_PAYMENT_TEST,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getOrderStatusList(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.APP_DATA,
        CONFIG.API.METHODS.GET_ORDER_STATUS_LIST,
        "ProductUrl"
      ),
      { headers }
    );
  }

  getUserOrderDetail(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_USER_ORDER_DETAIL,
        "ApiUrl"
      ) + `/${data.OrderId}`,
      { headers }
    );
  }

  getUserOrderDetailForSuccess(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_USER_ORDER_DETAIL,
        "ApiUrl"
      ) +
        "/" +
        data,
      { headers }
    );
  }

  getDoctorOrderDetail(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.GET_DOCTOR_ORDER_DETAIL,
        "ApiUrl"
      ) + `/${data.OrderId}`,
      { headers }
    );
  }

  // cancelOrderByDoctor(data: any): any {
  //   this.setHeaders();
  //   const headers = this.headers;
  //   return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.CANCEL_ORDER_BY_DOCTOR, 'ProductUrl'), data, {
  //     headers: headers
  //   }).pipe(
  //     map(response => {
  //       return response;
  //     })
  //   );
  // }
  //
  // cancelOrderByPatient(data: any): any {
  //   this.setHeaders();
  //   const headers = this.headers;
  //   return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.CANCEL_ORDER_BY_PATIENT, 'ProductUrl'), data, {
  //     headers: headers
  //   }).pipe(
  //     map(response => {
  //       return response;
  //     })
  //   );
  // }
  cancelOrder(params: string): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .delete(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.CANCEL_ORDER,
          "ApiUrl"
        ) + params,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  approveOrderByDoctor(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.APPROVE_ORDER_BY_DOCTOR,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  rejectOrderByDoctor(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.REJECT_ORDER_BY_DOCTOR,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  imCalling(params: any) {
    this.setHeaders();
    const headers = this.headers;
    return this.http.post(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.IM_CALLING,
        "ApiUrl"
      ) + params,
      {},
      { headers }
    );
  }

  getUserFile(fileId: any): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    let params = new HttpParams();
    params = params.append("fileId", fileId);
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_USER_FILE,
          "UserUrl"
        ),
        {
          headers: headers,
          params: params,
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  changeOrderActionDoctorOnMyWay(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.CHANGE_ORDER_ACTION_DOCTOR_ON_MY_WAY,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  changeOrderActionServiceInProgress(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.CHANGE_ORDER_ACTION_SERVICE_IN_PROGRESS,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // completeOrderByDoctor(data: any): any {
  //   this.setHeaders();
  //   const headers = this.headers;
  //   return this.http.post(this.buildURL(CONFIG.API.CONTROLLERS.API, CONFIG.API.METHODS.COMPLETE_ORDER_BY_DOCTOR, 'ProductUrl'), data, {
  //     headers: headers
  //   }).pipe(
  //     map(response => {
  //       return response;
  //     })
  //   );
  // }
  completeOrderByDoctor(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.COMPLETE_ORDER_BY_DOCTOR,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  completeOrderByPatient(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.COMPLETE_ORDER_BY_PATIENT,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDoctorServiceByDoctorId(data: GetDoctorServiceByDoctorId): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_DOCTOR_SERVICE_BY_DOCTOR_ID,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addOrderPrice(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_ORDER_PRICE,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  canPatientChatCall(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_PATIENT_CHAT_CALL,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getCallAccessToken(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.CALL_TOKEN,
        "ApiUrl"
      ),
      { headers }
    );
  }

  addRaitingByPatient(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_RATING_BY_PATIENT,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  GetUserReviewByOrderId(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_USER_REVIEW_BY_ORDER_ID,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  checkReviewNeededOrders(): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.REVIEW_NEEDED,
        "ApiUrl"
      ),
      { headers }
    );
  }

  getMyCards(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.CARDS,
        "ApiUrl"
      ),
      { headers }
    );
  }

  getMyTransactions(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.TRANSACTIONS,
        "ApiUrl"
      ),
      { headers }
    );
  }

  addCard(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.CARDS,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  checkPromo(params: string): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http.get(
      this.buildURL(
        CONFIG.API.CONTROLLERS.API,
        CONFIG.API.METHODS.CHECK_PROMO,
        "ApiUrl"
      ) + params,
      { headers }
    );
  }

  finishOrder(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.FINISH_ORDER,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getDoctorAction(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_DOCTOR_ACTION,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPatientAction(data: any): any {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_PATIENT_ACTION,
          "ProductUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  addBankCard(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.ADD_BANK_CARD,
          "PaymentUrl"
        ),
        null,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPopularSpecialties(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_POPULAR_SPECIALITIES,
          "ProductUrl"
        ),
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPopularDoctors(): Observable<any> {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.GET_POPULAR_DOCTORS,
          "ProductUrl"
        ),
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchForDoctors(searchString?: string) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SEARCH,
          "ApiUrl"
        ) + searchString,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchDoctorsSlotsForSpec(params: HttpParams) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SEARCH_SPEC_SLOTS,
          "ApiUrl"
        ),
        {
          headers,
          params,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  searchDoctorsSlotsByDate(searchString?: string) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SEARCH_SLOTS_BY_DATE,
          "ApiUrl"
        ) + searchString,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getSuggestions(value: string) {
    // get object from string
    var search = JSON.parse(
      '{"' +
        decodeURI(value)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
    this.tbcConnectService.sendSearch(search?.text);
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SEARCH_SUGGEST,
          "ApiUrl"
        ) + value,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  removeCard(cardId: number) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .delete(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.CARDS,
          "ApiUrl"
        ) + `/${cardId}`,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  sendDownloadUrl(data: { phone: string }) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SEND_DOWNLOAD_URL,
          "ApiUrl"
        ),
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  setUserToken(param: any) {
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .post(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.SAVE_TOKEN,
          "ApiUrl"
        ),
        param,
        {
          headers: headers,
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getBanners() {
    const selectedLanguage = localStorage.getItem('CurLang')
    this.setHeaders();
    const headers = this.headers;
    return this.http
      .get(
        this.buildURL(
          CONFIG.API.CONTROLLERS.API,
          CONFIG.API.METHODS.BANNERS,
          "ApiUrl"
        ),
        {
          headers: headers,
        }
      )
      .pipe(
        map((data: any) => {
          const filteredBanners = data.data.map((banner: any) => {
            const filteredImages = banner.images.filter((image: any) => {
              return image.locale === selectedLanguage;
            });
  
            return {
              ...banner,
              images: filteredImages,
            };
          });
          console.log(filteredBanners);
          filteredBanners.sort((a, b) => {
            return a.sortNumber < b.sortNumber ? -1 : 1;
          });
  
          return filteredBanners;
        })
      );
  }

  private buildURL(controller, method, instance = "UserUrl") {
    return CONFIG.API.REST.API_URLS[instance] + CONFIG.COMMON.DS + method;
  }
}
