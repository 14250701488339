<div class="cookie">
    <div class="wrapper" *ngIf="selectedLanguage === 'ka'">
        <div style="margin-top: 20px; margin-left: 40px;">
            <h1 style="color: white;  font-size: 14px !important; font-weight: bold; ">მზა ჩანაწერები - Cookies</h1>
            <p style="color: white; font-size: 14px !important;">ვებგვერდის ოპტიმიზაციისთვის ჩვენ ვიყენებთ "cookie" ფაილებს. გასააქტიურლებად დააჭირე ღილაკზე "ვეთანხმები" . დეტალური ინფორმაციის სანახავად
            გაეცანი <a style="color:white; text-decoration: underline;" routerLink="/cookies-policy"
            target="_blank">მზა ჩანაწერების პოლიტიკას</a> </p>
        </div>
        <button class="btn" (click)="dialog()">პარამეტრები</button> 
        <button class="alt-btn" (click)="onAgree()">ვეთანხმები</button>
        <button class="btn-exit" style="margin-bottom: 16px" (click)="close()">x</button>
    </div>
    <div class="wrapper" *ngIf="selectedLanguage === 'en'">
        <div style="margin-top: 20px; margin-left: 40px;">
            <h1 style="color: white;  font-size: 14px !important; font-weight: bold; ">Cookies</h1>
            <p style="color: white; font-size: 14px !important;">We use cookies and similar technologies to enable services and functionality on our site.  By clicking on accept, you agree to our use of such technologies. <a style="color:white; text-decoration: underline;" routerLink="/cookies-policy"
            target="_blank">See Сookies Policy.</a> </p>
        </div>
        <button class="btn" (click)="dialog()">Options</button> 
        <button class="alt-btn" (click)="onAgree()">Accept</button>
        <button class="btn-exit" style="margin-bottom: 16px" (click)="close()">x</button>
    </div>

</div>