<span (click)="closeDialog()" [inlineSVG]="'assets/img/times1.svg'"
      class="svg-15 cursor-pointer"></span>
<!-- <h1 class="text-center d-flex align-items-center justify-content-between" mat-dialog-title>
  <span *ngIf="data.message">{{'notification' | translate}}</span>
</h1> -->
<div class="dialog-wrapper">

  <div class="dialog-wrapper-body">
    <div *ngIf="data?.title && data?.title === 'success' && !data?.offers">
      <div class="dialog-wrapper-resend-button svg-122" [inlineSVG]="'assets/img/success.svg'" ></div>
    </div>
    <div *ngIf="data?.title && data?.title === 'failure' && !data?.offers">
      <div class="dialog-wrapper-resend-button svg-122" [inlineSVG]="'assets/img/failure.svg'" ></div>
    </div>
    <div *ngIf="data?.offers">
      <div class="dialog-wrapper-resend-button svg-122" [inlineSVG]="'assets/img/info-popup.svg'" ></div>
    </div>

    <div *ngIf="data?.offers">
      <div style="font-weight: bold;">{{'importantMessage' | translate | uppercase }}</div>
    </div>

    <div *ngIf="data?.message && !data?.visitTime" class="message message-main" mat-dialog-content>
      {{ data.message | translate }}
    </div>
    <div *ngIf="data?.message && data?.visitTime" class="message message-main" mat-dialog-content>
      {{ data.message | translate }} <br> 
      <span style="color: #1A2738; font-weight: bold;">{{data?.visitTime | date : 'dd MMM. yyyy - HH:mm' : '' : selectedLanguage}}</span>
    </div>
    <div *ngIf="data?.offers" class="message message-main" mat-dialog-content>
      {{'IAgreeToReceive' | translate}} <a routerLink="/offers"
      target="_blank">{{'offers'|translate}}</a> {{'tailoredToMe'|translate}}
    </div>
    <div class="message primary-message" *ngIf="data?.primaryMessage" mat-dialog-content>
      {{ data.primaryMessage | translate | uppercase}}
    </div>
    <div class="message secondary-message" *ngIf="data?.secondaryMessage" mat-dialog-content>
      {{ data.secondaryMessage | translate }}
    </div>

    <button (click)="closeDialog(true)" *ngIf="data?.buttonText" class="button" 
      [ngClass]="{ 'success-button': data?.title === 'success', 'failure-button': data?.title === 'failure' }">
      {{ data.buttonText | translate}}
    </button>
  </div>


  <div *ngIf="data.needsAction && !data?.offers" class="d-flex align-items-center justify-content-center mb-3" mat-dialog-actions>
    <button (click)="closeDialog(null,true)" >{{ data?.decline ? data.decline  : 'no'|translate }}</button>
    <button [mat-dialog-close]="true">{{ data?.confirm ? data.confirm : 'yes'|translate }}</button>
  </div>

  <div *ngIf="data.needsAction && data?.offers" style="flex-direction: column;" class="d-flex align-items-center justify-content-center mb-3" mat-dialog-actions>
    <button [mat-dialog-close]="true" style="background-color: #da1f3d; width: 190px;color: white;" >{{ 'yesAgree'|translate }}</button>
    <button (click)="closeDialog(null,true)"  style="background-color: white; width: 190px;color: #da1f3d;">{{ 'notAgree'|translate }}</button>
  </div>
</div>

