import {Deserializable} from './deserializable.interface';

export class UserModel implements Deserializable {
  public userId: number;
  public firstname: string;
  public lastname: string;
  public email: string;
  public phoneNumber: string;
  public authId: string;
  public authorities: Authority[];
  public isDoctor: boolean;
  public userStatus: boolean;
  public createDate: string;
  public clinics: Clinics[];
  public genderId: number;
  public systemLang: number;
  public fullName: string;
  public isInstantCallEnabled: boolean;
  public showInstantCallToggle: boolean;
  public refereeCode: string;
  public hasPassword: boolean;
  public commetChatId?: any;
  public birthDate?: any;
  public personalNumber?: any;
  public preferredLanguage?: PreferredLanguage;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  get isClinicAdmin() {
    return !!this.authorities.find(auth => auth.name === 'CLINIC_ADMIN')
  }
}

export interface PreferredLanguage {
  id: number,
  name: string
}

export interface Authority {
  id: number;
  name: string;
}

export interface Clinics {
  id: number;
  name: string;
  iban?: string;
  homeVisitEnabled: boolean
}
