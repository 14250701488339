import {AgmCoreModule} from '@agm/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateService, TranslateStore} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {SwiperModule} from 'swiper/angular';
import {ConfirmDialogComponent, DatePickerComponent, ServiceModalComponent, SmsModalComponent} from '../../components';
import {FooterComponent} from '../../components/footer/footer.component';
import {HeaderComponent} from '../../components/header/header.component';
import {LocationModalComponent} from '../../components/location-modal/location-modal.component';
import {SearchBarComponent} from '../../components/search-bar/search-bar.component';
import {I18nModule} from '../../i18n/i18n.module';
import {AddCalendarComponent} from '../../pages';
import {NavComponent} from '../../pages/profile-page/nav/nav.component';
import {TimeslotPipe} from '../../pipes/timeslot.pipe';
import {CalendarComponent} from '../components/calendar/calendar.component';
import {DoctorSearchResultComponent} from '../components/doctor-search-result/doctor-search-result.component';
import {TimeSlotComponent} from '../components/doctor-search-result/time-slot/time-slot.component';
import {RatingStarsComponent} from '../components/rating-stars/rating-stars.component';
import {InfoDialogComponent} from '../dialogs/info-dialog/info-dialog.component';
import {KeepHtmlPipe} from '../pipes/keep-html.pipe';
import {ServiceTypePipe} from '../pipes/service-type.pipe';
import {TimePipe} from '../pipes/time.pipe';
import { RedmedHeaderComponent } from '../components/redmed-header/redmed-header.component';
import { HeaderNavigationComponent } from '../components/redmed-header/header-navigation/header-navigation.component';
import { HeaderSuggestionsComponent } from '../components/redmed-header/header-navigation/header-suggestions/header-suggestions.component';
import { RedmedFooterComponent } from '../components/redmed-footer/redmed-footer.component';
import {FireworkComponent} from "../../components/firework/firework.component";
import {MatSelectModule} from "@angular/material/select";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {CustomPaginator} from "../services/CustomPaginatorConfiguration";
import {NgxCaptchaModule} from "ngx-captcha";
import { MatInputModule } from '@angular/material/input';
import { TimezoneDatePipe } from '../pipes/time-zone.pipe';
import { BlogQuickViewComponent } from 'src/app/pages/main-page/blog-quick-view/blog-quick-view.component';
import { CookieDialogComponent } from '../components/cookie-dialog/cookie-dialog.component';
import { CookieAlertComponent } from '../components/cookie-alert/cookie-alert.component';


@NgModule({
  declarations: [
    SearchBarComponent,
    RatingStarsComponent,
    HeaderComponent,
    FooterComponent,
    DoctorSearchResultComponent,
    CookieDialogComponent,
    CookieAlertComponent,
    TimeSlotComponent,
    TimePipe,
    KeepHtmlPipe,
    ServiceTypePipe,
    NavComponent,
    DatePickerComponent,
    ServiceModalComponent,
    AddCalendarComponent,
    CalendarComponent,
    TimeslotPipe,
    SmsModalComponent,
    LocationModalComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    RedmedHeaderComponent,
    HeaderNavigationComponent,
    HeaderSuggestionsComponent,
    RedmedFooterComponent,
    FireworkComponent,
    TimezoneDatePipe,
    BlogQuickViewComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule.forRoot(),
    SwiperModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    RouterModule,
    MatMenuModule,
    MatDatepickerModule,
    NgSelectModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatBadgeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCkH95QT8Pa-Fs5mZgPVmGgWwJx_qDL3GA',
      libraries: ['places']
    }),
    MatAutocompleteModule,
    MatSlideToggleModule,
    OverlayModule,
    MatSelectModule,
    NgxCaptchaModule,
  ],
  providers: [
    TranslateService,
    TranslateStore,
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new CustomPaginator(translateService).getPaginatorIntl()
    }
  ],
  exports: [
    InlineSVGModule,
    SwiperModule,
    SearchBarComponent,
    MatTooltipModule,
    RatingStarsComponent,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    I18nModule,
    MatDatepickerModule,
    NgSelectModule,
    MatIconModule,
    DoctorSearchResultComponent,
    CookieDialogComponent,
    CookieAlertComponent,
    MatRippleModule,
    TimeSlotComponent,
    MatButtonModule,
    TimePipe,
    KeepHtmlPipe,
    ServiceTypePipe,
    NavComponent,
    DatePickerComponent,
    ServiceModalComponent,
    AddCalendarComponent,
    CalendarComponent,
    MatExpansionModule,
    MatFormFieldModule,
    MatBadgeModule,
    TimeslotPipe,
    SmsModalComponent,
    LocationModalComponent,
    InfoDialogComponent,
    RedmedHeaderComponent,
    RedmedFooterComponent,
    FireworkComponent,
    MatSelectModule,
    NgxCaptchaModule,
    BlogQuickViewComponent
  ]
})
export class SharedModule {
}
