import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard, DoctorGuard } from './guards';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'main' },
  {
    path: 'clinic',
    loadChildren: () => import('./clinic/clinic.module').then(m => m.ClinicModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main-page/main-page.module').then(m => m.MainPageModule)
  },
  {
    path: 'insurance-deep-link',
    loadChildren: () => import('./pages/main-page/main-page.module').then(m => m.MainPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search-page/search-page.module').then(m => m.SearchPageModule)
  },
  {
    path: 'specialoba/:specialtyName',
    loadChildren: () => import('./pages/search-page/search-page.module').then(m => m.SearchPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'doctor-terms',
    loadChildren: () => import('./pages/doctor-terms/doctor-terms.module').then(m => m.DoctorTermsModule)
  },
  {
    path: 'userterms',
    loadChildren: () => import('./pages/user-terms/user-terms.module').then(m => m.UserTermsModule)
  },
  {
    path: 'cookies-policy',
    loadChildren: () => import('./pages/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then(m => m.OffersModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./shared/components/download-app/download-app.module').then(m => m.DownloadAppModule)
  },
  {
    path: 'download-doctor',
    loadChildren: () => import('./shared/components/download-doctor-app/download-doctor-app.module').then(m => m.DownloadDoctorAppModule)
  },
  {
    path: 'download-doctor',
    loadChildren: () => import('./shared/components/download-doctor-app/download-doctor-app.module').then(m => m.DownloadDoctorAppModule)
  },
  {
    path: 'user/auth',
    loadChildren: () => import('./pages/user-page/login-page/login-page.module').then(m => m.LoginPageModule)
  },
  {
    path: 'user/auth-user',
    loadChildren: () => import('./pages/user-page/user-login-page/user-login-page.module').then(m => m.UserLoginPageModule)
  },
  {
    path: 'user/auth-user/:insurance',
    loadChildren: () => import('./pages/user-page/user-login-page/user-login-page.module').then(m => m.UserLoginPageModule)
  },
  {
    path: 'user/register',
    loadChildren: () => import('./pages/user-page/register-page/register-page.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'user/register/:insurance',
    loadChildren: () => import('./pages/user-page/register-page/register-page.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'user/register-doctor',
    loadChildren: () => import('./pages/user-page/register-doctor-page/register-doctor-page.module').then(m => m.RegisterDoctorPageModule)
  },
  {
    path: 'user/recover',
    loadChildren: () => import('./pages/user-page/recovery-page/recovery-page.module').then(m => m.RecoveryPageModule)
  },
  {
    path: 'eqimi/:doctorName/:id',
    loadChildren: () => import('./pages/doctor-page/doctor-page.module').then(m => m.DoctorPageModule)
  },
  {
    path: 'eqimi/:doctorName/:id/:clinic',
    loadChildren: () => import('./pages/doctor-page/doctor-page.module').then(m => m.DoctorPageModule)
  },
  {
    path: 'eqimi/:id',
    loadChildren: () => import('./pages/doctor-page/doctor-page.module').then(m => m.DoctorPageModule)
  },
  {
    path: 'blog-new/:id',
    loadChildren: () => import('./pages/blog-detail-new-page/blog-detail-new-page.module').then(m => m.BlogDetailNewPageModule),
  },
  {
    path: 'blog-new/:blogName/:id',
    loadChildren: () => import('./pages/blog-detail-new-page/blog-detail-new-page.module').then(m => m.BlogDetailNewPageModule),
  },
  {
    path: 'blog-list',
    loadChildren: () => import('./pages/blog-list-page/blog-list-page.module').then(m => m.BlogListPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/order-page/order-page.module').then(m => m.OrderPageModule)
  },
  {
    path: 'order-with-insurance',
    loadChildren: () => import('./pages/order-with-insurance/order-with-insurance.module').then(m => m.OrderWithInsuranceModule)
  },
  {
    path: 'success/:orderId',
    loadChildren: () => import('./pages/order-success-page/order-success-page.module').then(m => m.OrderSuccessPageModule)
  },
  {
    path: 'success/:type/:orderId',
    loadChildren: () => import('./pages/order-success-page/order-success-page.module').then(m => m.OrderSuccessPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact-page/contact-page.module').then(m => m.ContactPageModule)
  },
  {
    path: 'personal-data-officer',
    loadChildren: () => import('./pages/personal-data-officer/personal-data-officer.module').then(m => m.PersonalDataOfficerModule)
  },
  {
    path: 'profile/delete',
    loadChildren: () => import('./pages/delete-page/delete-page.module').then(m => m.DeletePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about-page/about-page.module').then(m => m.AboutPageModule)
  },
  {
    path: 'ekimtankonsultacia',
    loadChildren: () => import('./landing/app-landing/app-landing.module').then(m => m.AppLandingModule)
  },
  {
    path: 'ufaso_videorcheva',
    loadChildren: () => import('./landing/free-advice/free-advice.module').then(m => m.FreeAdviceModule)
  },
  {
    path: 'sameano-eqoskopia',
    loadChildren: () => import('./landing/sameano-landing/sameano-landing.module').then(m => m.SameanoLandingModule)
  },
  {
    path: 'redmed-terapevti',
    loadChildren: () => import('./landing/terapevti-landing/terapevti-landing.module').then(m => m.TerapevtiLandingModule)
  },
  {
    path: 'redmed-pediatri',
    loadChildren: () => import('./landing/pediatri-landing/pediatri-landing.module').then(m => m.PediatriLandingModule)
  },
  {
    path: 'endokrinologis-konsultacia',
    loadChildren: () => import('./landing/endokrinologi-landing/endokrinologi-landing.module').then(m => m.EndokrinologiLandingModule)
  },
  {
    path: 'covid-19',
    loadChildren: () => import('./landing/covid-landing/covid-landing.module').then(m => m.CovidLandingModule)
  },
  {
    path: 'laboratory',
    loadChildren: () => import('./landing/laboratory/laboratory.module').then(m => m.LaboratoryModule)
  },
  {
    path: 'daureke-eqims-axlave',
    loadChildren: () => import('./landing/terapevti-free/terapevti-free.module').then(m => m.TerapevtiFreeModule)
  },
  {
    path: 'covid-19-clinic',
    loadChildren: () => import('./landing/covid-clinic/covid-clinic.module').then(m => m.CovidClinicModule)
  },
  {
    path: 'TBCI-COVCLINIC',
    loadChildren: () => import('./landing/covid-redmed-program/covid-redmed-program.module').then(m => m.CovidRedmedProgramModule)
  },
  {
    path: 'SPACE-X-REDMED',
    loadChildren: () => import('./landing/space-landing/space-landing.module').then(m => m.SpaceLandingModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset-page/password-reset-page.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'activate-promo/:campaign',
    loadChildren: () => import('./landing/promo-landing/promo-landing.module').then(m => m.PromoLandingModule)
  },
  {
    path: 'search-step',
    loadChildren: () => import('./pages/search-stepper/search-stepper.module').then(m => m.SearchStepperModule)
  },
  {
    path: 'add-insurance',
    loadChildren: () => import('./pages/add-insurance/add-insurance.module').then(m => m.AddInsuranceModule)
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./pages/site-map/site-map.module').then(m => m.SiteMapModule)
  },
  {
    path: 'profile',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/profile-page/edit-page/edit-page.module').then(m => m.EditPageModule)
      },
      {
        path: 'doctor-edit',
        loadChildren: () => import('./pages/profile-page/doctor-edit-page/doctor-edit-page.module').then(m => m.DoctorEditPageModule),
        canActivate: [DoctorGuard]
      },
      {
        path: 'doctor-services',
        loadChildren: () => import('./pages/profile-page/doctor-services-page/doctor-services-page.module').then(m => m.DoctorServicesPageModule),
        canActivate: [DoctorGuard]
      },
      {
        path: 'doctor-calendar',
        loadChildren: () => import('./pages/profile-page/doctor-calendar-page/doctor-calendar-page.module').then(m => m.DoctorCalendarPageModule),
        canActivate: [DoctorGuard]
      },
      {
        path: 'address',
        loadChildren: () => import('./pages/profile-page/address-page/address-page.module').then(m => m.AddressPageModule)
      },
      {
        path: 'cards',
        loadChildren: () => import('./pages/profile-page/cards-page/cards-page.module').then(m => m.CardsPageModule)
      },
      {
        path: 'doctor-iban',
        loadChildren: () => import('./pages/profile-page/doctor-iban-page/doctor-iban-page.module').then(m => m.DoctorIbanPageModule)
      },
      {
        path: 'promo',
        loadChildren: () => import('./pages/profile-page/promo-codes-page/promo-codes-page.module').then(m => m.PromoCodesPageModule)
      },
      {
        path: 'wallet-points',
        loadChildren: () => import('./pages/profile-page/wallet-points-page/wallet-points-page.module').then(m => m.WalletPointsPageModule)
      },
      {
        path: 'booking',
        loadChildren: () => import('./pages/booking-page/booking-page.module').then(m => m.BookingPageModule)
      },
      {
        path: 'referral',
        loadChildren: () => import('./pages/profile-page/referral-page/referral-page.module').then(m => m.ReferralPageModule)
      },
      {
        path: 'insurance',
        loadChildren: () => import('./pages/profile-page/insurance-page/insurance-page.module').then(m => m.InsurancePageModule)
      },
      {
        path: 'referral-doctor',
        loadChildren: () => import('./pages/profile-page/referral-doctor-page/referral-doctor-page.module').then(m => m.ReferralDoctorPageModule)
      },
      {
        path: 'authorization',
        loadChildren: () => import('./pages/profile-page/authorization/authorization.module').then(m => m.AuthorizationModule)
      },
      {
        path: 'sms-language',
        loadChildren: () => import('./pages/profile-page/sms-language-page/sms-language-page.module').then(m => m.SmsLanguagePageModule)
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () => import('./pages/profile-page/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'chooseDoctorBySpecialty',
    loadChildren: () => import('./pages/choose-doctor-by-specialty/choose-doctor-by-specialty.module').then(m => m.ChooseDoctorBySpecialtyModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error-page/error-page.module').then(m => m.ErrorPageModule)
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: QuicklinkStrategy
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
