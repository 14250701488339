import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SeoOptimizationService } from '../../services/seo-optimization.service';
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from "src/app/services";
import { TbcConnectService } from 'src/app/services/tbc-connect.service';
@Component({
  selector: 'app-cookie-alert',
  templateUrl: './cookie-alert.component.html',
  styleUrls: ['./cookie-alert.component.scss']
})
export class CookieAlertComponent implements OnInit {

  @Output() show = new EventEmitter<boolean>();
  @Output() openDialog = new EventEmitter<boolean>();
  @Output() agree = new EventEmitter<boolean>();

  public selectedLanguage: string;
  // Method to emit the value
  sendValue(value: boolean) {
    this.show.emit(value);
  }

  sendDialogValue(value: boolean){
    this.openDialog.emit(value);
  }

  agreeValue(value: boolean) {
    this.agree.emit(value);
  }
  constructor(private seoOptimizationService: SeoOptimizationService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private tbcConnectService: TbcConnectService) { }

  ngOnInit(): void {
    this.selectedLanguage = this.languageService.getLang();
  }

  close(){
    this.sendValue(false);
    // this.tbcConnectService.setTbcConnectStatus(false).subscribe();
  }

  dialog(){
    this.sendDialogValue(true);
  }

  onAgree(){
    this.agreeValue(true);
    // this.tbcConnectService.setTbcConnectStatus(true).subscribe();
  }
}
