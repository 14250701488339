import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import * as SendBird from 'sendbird';
import {environment} from '../../environments/environment';
import {UserModel} from '../shared/models/user.model';
import {LoadingService} from '../shared/services/loading.service';
import {NotificationService} from '../shared/services/notification.service';
import {OrderService} from '../shared/services/order.service';
import {TwilioService} from '../shared/services/twilio.service';
import {UserService} from './user.service';


@Injectable({
  providedIn: 'root'
})
export class SendBirdChatService {
  sb: any;
  private getChatMessages$ = new Subject<any>();
  private addChatMessage$ = new Subject<any>();
  private secondMemberOnlineStatus$ = new Subject<any>();
  private loadingChatFileMessage$ = new Subject<any>();
  private currentChannel = new BehaviorSubject(null);
  getActiveGroupChannel = this.currentChannel.asObservable();
  private user: any;

  constructor(private loadingService: LoadingService,
              private router: Router,
              private userService: UserService,
              private twilio: TwilioService,
              private orderService: OrderService,
              private notificationService: NotificationService,
              private toastrService: ToastrService) {
    this.sb = new SendBird({'appId': '90D1685D-D2FB-4316-9212-322F025EB975'});
    this.userService.getUser
      .subscribe(
        (user: any) => {
          if (user) {
            this.user = user;
          }
        }
      );
  }

  getMessages(): Observable<any> {
    return this.getChatMessages$.asObservable();
  }

  addMessage(): Observable<any> {
    return this.addChatMessage$.asObservable();
  }

  loadingChatFileMessage(): Observable<any> {
    return this.loadingChatFileMessage$.asObservable();
  }

  changeSecondMemberOnlineStatus(): Observable<any> {
    return this.secondMemberOnlineStatus$.asObservable();
  }

  checkAndUpdateSecondMemberOnlineStatus(groupChannel: any, userId: string): void {
    const secondMember = groupChannel.members.find(m => m.userId !== userId);
    if (secondMember && secondMember.connectionStatus === 'online') {
      this.secondMemberOnlineStatus$.next(true);
    }
  }

  connectToSendBird(currentUser: UserModel) {
    this.sb.connect(currentUser.phoneNumber, (user, error) => {
      if (error) return;
      if (currentUser.fullName !== user.nickname) {
        this.sb.updateCurrentUserInfo(currentUser.fullName, user.profileUrl, (user, error) => {
          if (error) return;
          this._createChannelHandler();
        });
      } else {
        this._createChannelHandler();
      }
    });
  }

  joinChat(userId: string, channelUrl: string): void {
    this.sb.connect(userId, (user, error) => {
      if (error) return;
      this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
        if (error) return;
        this.currentChannel.next(groupChannel);
        this.checkAndUpdateSecondMemberOnlineStatus(groupChannel, userId);
        const prevMessageListQuery = groupChannel.createPreviousMessageListQuery();
        prevMessageListQuery.limit = 30;
        prevMessageListQuery.load((messages, error) => {
          if (error) return;
          this.getChatMessages$.next(messages);
        });
      });
    });
  }

  sendMessageText(messageText: string, channelUrl: string): void {
    this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => {
      if (error) {
        console.error(error);
        return;
      }
      groupChannel.sendUserMessage(messageText, null, null, (message, error) => {
        if (error) {
          console.error(error);
          return;
        }
        this.checkAndUpdateSecondMemberOnlineStatus(groupChannel, message.sender.userId);
        this.addChatMessage$.next(message);
        this.notificationService.logChatNotification(environment.production ? channelUrl.split('_')[1] : channelUrl.split('_')[2])
          .subscribe(() => {
          });
      });
    });
  }


  sendFileMassage(file: any, channelUrl: string): void {

    this.loadingChatFileMessage$.next(true);

    const that = this;

    this.sb.GroupChannel.getChannel(channelUrl, function(groupChannel, error) {
      if (error) {
        that.loadingChatFileMessage$.next(false);
        return;
      }

      groupChannel.sendFileMessage(file, function(fileMessage, error) {
        if (error) {
          that.loadingChatFileMessage$.next(false);
          return;
        }

        that.loadingChatFileMessage$.next(false);

        // that.checkAndUpdateSecondMemberOnlineStatus(groupChannel, message.sender.userId);s
        that.addChatMessage$.next(fileMessage);
      });
    });
  }


  _createChannelHandler() {
    const handler = new this.sb.ChannelHandler();
    handler.onChannelChanged = groupChannel => {
      // this.currentChannel = groupChannel;
    };
    handler.onUserJoined = (groupChannel, user) => {
      //
    };
    handler.onUserLeft = (groupChannel, user) => {
      //
    };
    handler.onChannelHidden = groupChannel => {
      //
    };
    handler.onMessageReceived = (groupChannel, message) => {
      this.addChatMessage$.next(message);
      if (!this.currentChannel.value || this.currentChannel.value !== groupChannel) {

        this.toastrService.info(message.message, `${message._sender.nickname}`, {
          disableTimeOut: true,
          closeButton: true
        }).onTap.pipe(take(1)).subscribe(() =>
          this.handleClick(message.channelUrl)
        );
      }
    };

    this.sb.addChannelHandler('#MyChannel', handler);
  }

  disconnectFromChanel() {
    this.currentChannel.next(null);
  }


  private handleClick(channelUrl: string) {
    const channelUrlDatas = channelUrl.split('_');
    const orderId = channelUrlDatas[channelUrlDatas.length - 1];
    this.orderService.getOrderById(orderId, this.user)
      .subscribe(
        (response: any) => {
          if (response.hasOwnProperty('result')) {
            this.router.navigate(['profile/booking'])
              .then(
                () => {
                  this.twilio.makeChatAction(true, response.result, channelUrl);
                }
              );
          }
        });
  }
}
