import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {CONFIG} from '../core/config/config';

import {UserService} from '../services';

@Injectable()
export class PatientGuard {

  private cLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;
  private userType = CONFIG.COMMON.DEFAULT_USER_TYPE;

  constructor(private userService: UserService, private router: Router) {
    this.userType = this.userService.getUserType();
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userType = this.userService.getUserType();
    if (this.userType == CONFIG.COMMON.PATIENT_USER_TYPE) {
      return true;
    }
    this.router.navigate(['/error']);
    return false;
  }

}

@Injectable()
export class DoctorGuard {

  private cLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;
  private userType = CONFIG.COMMON.DEFAULT_USER_TYPE;

  constructor(private userService: UserService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userType = this.userService.getUserType();
    if (this.userType == CONFIG.COMMON.DOCTOR_USER_TYPE) {
      return true;
    }
    this.router.navigate(['/error']);
    return false;
  }
}
