import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";

declare var _paq: any;

@Injectable({
  providedIn: 'root'
})
export class TbcConnectService {
  private userIsAgreed: boolean | null;
  private apiUrl = environment.apiUrl;


  constructor(private http: HttpClient,) {
  }

  sendUserLogin(user): void {
    if (!this.userIsAgreed){
      return;
    }
    _paq.push([
      'userLogin',
      user,
      true
    ]);
  }

  sendCategoryView(mobileQuery: MediaQueryList): void {
    _paq.push([
      'categoryView',
      mobileQuery.matches ? 'smartphone' : 'technics'
    ]);
  }

  sendPageView(): void {
    _paq.push(['pageView']);
  }

  sendLogout(): void {
    if (!this.userIsAgreed) {
      return;
    }
    _paq.push(['logout']);
  }

  sendSearch(value): void {
    if (!value) {
      return;
    }
    _paq.push([
      'search',
      value,
    ]);
  }

  sendOrder(orderID: string, grandTotal: number): void {
    if (!this.userIsAgreed) {
      return;
    }
    _paq.push([
      'order',
      orderID,
      grandTotal,
      'card'
    ]);
  }

  sendPurchase(): void {
    if (!this.userIsAgreed) {
      return;
    }
    _paq.push(['purchase']);
  }

  getTbcConnectStatus(): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/v2/tbc-connect/consent`).pipe(
      map((tbcConnect: any) => {
        this.userIsAgreed = tbcConnect?.data?.agreed;
        return tbcConnect;
      })
    );
  }

  setTbcConnectStatus(status: boolean): Observable<any> {
    if (typeof status !== 'boolean'){
      return of(null);
    }
    let params = new HttpParams();
    params = params.append('agreed', status.toString());
    return this.http.post(`${this.apiUrl}/users/v2/tbc-connect/consent`, null, {params});
  }


}
