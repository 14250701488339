<div class="cookie">
    <div class="container" *ngIf="selectedLanguage === 'ka'">
        <div style="display: flex; justify-content: space-between;">
            <h1 class="head-text">{{"cookie პოლიტიკა" | uppercase}}</h1>
            <img (click)="close()" class="image" src="assets/icons/x-exit-icon.png" alt="">
        </div>
        <p class="main-text">
            ჩვენ ვიყენებთ Cookie-ს და სხვადასხვა ციფრულ ინსტრუმენტს რომ დავაკვირდეთ
            ჩვენს ვებ-გვერდზე მომხმარებლების ქცევას რათა დავრწმუნდეთ, რომ გთავაზობთ
            საუკეთესო გამოცდილებას ჩვენს ვებ-გვერდზე ყოფნისას და შევძლოთ
            მუდმივად გავაუმჯობესოთ მომსახურების ხარისხი. წესების და პირობების სრულად სანახავად
            ეწვიეთ <a style="color:#da1f3d; " routerLink="/cookies-policy"
            target="_blank">ბმულს.</a>
        </p>
        <div style="margin-top: 20px">
            <div class="elem">
                <h2>GENERAL</h2>
                <mat-slide-toggle style="margin-right: 40px;"[checked]="true" [disabled]="true" color="warn"></mat-slide-toggle>
            </div>
            <div class="elem">
                <h2>PREFERENCES</h2>
                <mat-slide-toggle style="margin-right: 40px;" color="warn" [checked]="preferencesToggleValue" (change)="changePreference($event)"></mat-slide-toggle>
            </div>
            <div class="elem">
                <h2>PERFORMANCE</h2>
                <mat-slide-toggle style="margin-right: 40px;" color="warn" [checked]="performanceToggleValue" (change)="changePerfomance($event)"></mat-slide-toggle>
            </div>
        </div>
        <div style="margin-right:40px">
            <button class="btn" (click)="save()">დამახსოვრება</button>
        </div>
    </div>
    <div class="container" *ngIf="selectedLanguage === 'en'">
        <div style="display: flex; justify-content: space-between;">
            <h1 class="head-text">{{"cookie პოლიტიკა" | uppercase}}</h1>
            <img (click)="close()" class="image" src="assets/icons/x-exit-icon.png" alt="">
        </div>
        <p class="main-text">
            We use cookies and various digital tools to monitor our website. On page user behavior to ensure we offer the best experience while on our website and to be able to constantly improve the quality of service. <a style="color:white; text-decoration: underline;" routerLink="/userterms"
            target="_blank">See Privacy Policy.</a>
        </p>
        <div style="margin-top: 20px">
            <div class="elem">
                <h2>GENERAL</h2>
                <mat-slide-toggle style="margin-right: 40px;"[checked]="true" [disabled]="true" color="warn"></mat-slide-toggle>
            </div>
            <div class="elem">
                <h2>PREFERENCES</h2>
                <mat-slide-toggle style="margin-right: 40px;" color="warn" [checked]="preferencesToggleValue" (change)="changePreference($event)"></mat-slide-toggle>
            </div>
            <div class="elem">
                <h2>PERFORMANCE</h2>
                <mat-slide-toggle style="margin-right: 40px;" color="warn" [checked]="performanceToggleValue" (change)="changePerfomance($event)"></mat-slide-toggle>
            </div>
        </div>
        <div style="margin-right:40px">
            <button class="btn" (click)="save()">Save</button>
        </div>
    </div>
</div>