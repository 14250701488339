import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {InlineSVGModule} from 'ng-inline-svg';
import {DownloadAppRoutingModule} from './download-app-routing.module';
import {DownloadAppComponent} from './download-app.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [DownloadAppComponent],
  exports: [
    DownloadAppComponent
  ],
  imports: [
    CommonModule,
    DownloadAppRoutingModule,
    InlineSVGModule,
    ReactiveFormsModule,
    TranslateModule
  ]
})

export class DownloadAppModule {
}
