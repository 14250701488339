import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ConsultationCardModel} from '../../models/consultation-card.model';
import {DocumentationService} from '../../services/documentation.service';
import {LoadingService} from '../../services/loading.service';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import * as QuillNamespace from 'quill';
import {TranslateService} from "@ngx-translate/core";
const Quill: any = QuillNamespace;

@Component({
  selector: 'app-consultation-card',
  templateUrl: './consultation-card.component.html',
  styleUrls: ['./consultation-card.component.scss']
})
export class ConsultationCardComponent implements OnInit {
  filteredOptions = [];
  filteredFinalOptions = [];
  public editorModules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }]
      ]
    }
  };
  consultationCardForm: FormGroup;

  isFormSaved = true;
  public selectedLanguage = localStorage.getItem('CurLang') || 'ka';

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                consultationCard: ConsultationCardModel,
                orderId: number,
                send: boolean
              },
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ConsultationCardComponent>,
              private loadingService: LoadingService,
              private _snackBar: MatSnackBar,
              private documentationService: DocumentationService,
              private translate: TranslateService) {
    this.consultationCardForm = formBuilder.group({
      subjectiveBriefAnamnesis: formBuilder.control(null),
      objectiveDiagnosticExaminationsConsultations: formBuilder.control(null),
      assessmentPreliminaryDiagnosis: formBuilder.control([]),
      assessmentPreliminaryDiagnosisControl: formBuilder.control(null),
      assessmentFinalDiagnosis: formBuilder.control([]),
      assessmentFinalDiagnosisControl: formBuilder.control(null),
      finalDiagnosisMoveToForm025: formBuilder.control(null),
      planningAssignedExaminations: formBuilder.control(null),
      planningAssignedTreatment: formBuilder.control(null),
      minutesAfterInjury: formBuilder.control(null),
      hasInjury: formBuilder.control(null),
      injuryRoadPedestrian: formBuilder.control(null),
      injuryRoadBicycle: formBuilder.control(null),
      injuryRoadVehicle: formBuilder.control(null),
      injuryBurnThermal: formBuilder.control(null),
      injuryBurnChemical: formBuilder.control(null),
      injuryBurnElectrical: formBuilder.control(null),
      injuryBurnFrostbite: formBuilder.control(null),
      injuryWoundFirearm: formBuilder.control(null),
      injuryWoundColdWeapon: formBuilder.control(null),
      injuryWoundHousehold: formBuilder.control(null),
      injuryWoundIndustrial: formBuilder.control(null),
      injuryWoundSelfHarm: formBuilder.control(null),
      injuryWoundIntentional: formBuilder.control(null),
      injuryMechanismFall: formBuilder.control(null),
      injuryMechanismCrush: formBuilder.control(null),
      injuryMechanismOther: formBuilder.control(null),
      physicianSpecialist: formBuilder.control(null),
      otherChecked: formBuilder.control(null)
    });
  }

  ngOnInit() {
    this.consultationCardForm.get('assessmentPreliminaryDiagnosisControl').valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
      if (value && value.length > 2) {
        this._filter(value).subscribe(data => {
          this.filteredOptions = data;
          this.filterOptions();
        });
      }
    });

    this.consultationCardForm.get('assessmentFinalDiagnosisControl').valueChanges
        .pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(value => {
      if (value && value.length > 2) {
        this._filter(value).subscribe(data => {
          this.filteredFinalOptions = data;
          this.filterOptions();
        });
      }
    });

    this.consultationCardForm.patchValue(this.data.consultationCard);
    this.consultationCardForm.get('otherChecked').setValue(!!this.data.consultationCard.injuryMechanismOther);
    this.checkForChanged();
  }

  checkForChanged() {
    this.consultationCardForm.valueChanges
        .subscribe(
            () => {
              this.isFormSaved = false;
              if (!this.consultationCardForm.get('otherChecked').value) {
                this.consultationCardForm.get('injuryMechanismOther').setValue(null, {emitEvent: false});
              }
            });
  }

  editConsultationCard(action: string = null, closeAfterSave: boolean = false) {

    this.loadingService.setApplicationLoading(true);
    this.documentationService.editConsultationCard(this.consultationCardForm.value, this.data.orderId)
        .subscribe((response: any) => {
          this.loadingService.setApplicationLoading(false);
          this.isFormSaved = true;
          if (action) {
            this.dialogRef.close(action);
          } else if (closeAfterSave) {
            this.dialogRef.close();
          }
          this._snackBar.open(this.translate.instant('the-consultation-card-has-been-updated-successfully'), '', {
            duration: 3000,
            verticalPosition: 'bottom'
          });
        }, () => {
          this.loadingService.setApplicationLoading(false);
        });
  }

  addDiagnosisToData($event: MatAutocompleteSelectedEvent) {
    let assessmentPreliminaryDiagnosisArray = this.consultationCardForm.get('assessmentPreliminaryDiagnosis').value;
    if (!assessmentPreliminaryDiagnosisArray) {
      assessmentPreliminaryDiagnosisArray = [];
    }
    assessmentPreliminaryDiagnosisArray.push($event.option.value);
    const selectedDiagnosis = this.filteredOptions.find(option => option.id === $event.option.value);
    this.data.consultationCard.assessmentPreliminaryDiagnosisFullString +=
        (this.data.consultationCard.assessmentPreliminaryDiagnosisFullString.length ? `╟` : ``) + `${selectedDiagnosis.code} - ${selectedDiagnosis.name}`;
    this.consultationCardForm.get('assessmentPreliminaryDiagnosisControl').setValue(null, {emitEvent: false});
    this.consultationCardForm.get('assessmentPreliminaryDiagnosis').setValue(assessmentPreliminaryDiagnosisArray);
    this.filteredOptions.splice(this.filteredOptions.indexOf(selectedDiagnosis), 1);
  }

  addFinalDiagnosisToData($event: MatAutocompleteSelectedEvent) {
    let assessmentFinalDiagnosisArray = this.consultationCardForm.get('assessmentFinalDiagnosis').value;
    if (!assessmentFinalDiagnosisArray) {
      assessmentFinalDiagnosisArray = [];
    }
    assessmentFinalDiagnosisArray.push($event.option.value);
    const selectedDiagnosis = this.filteredFinalOptions.find(option => option.id === $event.option.value);
    this.data.consultationCard.assessmentFinalDiagnosisFullString +=
        (this.data.consultationCard.assessmentFinalDiagnosisFullString.length ? `╟` : ``) + `${selectedDiagnosis.code} - ${selectedDiagnosis.name}`;
    this.consultationCardForm.get('assessmentFinalDiagnosisControl').setValue(null, {emitEvent: false});
    this.consultationCardForm.get('assessmentFinalDiagnosis').setValue(assessmentFinalDiagnosisArray);
    this.filteredFinalOptions.splice(this.filteredFinalOptions.indexOf(selectedDiagnosis), 1);
  }

  closeDialog(data?: any) {
    this.dialogRef.close(data);
  }

  downloadDocument() {
    this.editConsultationCard('preview');
  }

  sendToPatient() {
    this.editConsultationCard('send');
  }

  private _filter(value: string): Observable<string[]> {
    const filterValue = value.toLowerCase();
    let params = new HttpParams();
    params = params.append('text', filterValue);
    return this.documentationService.getDiagnosisSuggestions(params)
        .pipe(
            map(data => {
              return data;
            })
        );
  }

  private filterOptions() {
    this.consultationCardForm.get('assessmentPreliminaryDiagnosis').value.forEach(diagnosisId => {
      this.filteredOptions.splice(this.filteredOptions.findIndex(val => val.id === diagnosisId), 1);
    });
  }

  closeConsultationCard() {
    if (!this.isFormSaved) {
      const dialog = this.dialog.open(InfoDialogComponent, {
        data: {
          message: 'do-you-want-to-save-and-close-the-form?',
          needsAction: true
        }
      });
      dialog.afterClosed()
          .subscribe(
              (result) => {
                if (result) {
                  this.editConsultationCard(null, true);
                } else {
                  this.closeDialog();
                }
              });
    } else {
      this.closeDialog();
    }
  }

  clearDiagnosis(clearFinal: boolean = false) {
    if (clearFinal) {
      this.consultationCardForm.get('assessmentPreliminaryDiagnosis').setValue([]);
      this.consultationCardForm.get('assessmentPreliminaryDiagnosisControl').setValue(null);
      this.data.consultationCard.assessmentPreliminaryDiagnosisFullString = '';
    } else {
      this.consultationCardForm.get('assessmentFinalDiagnosis').setValue([]);
      this.consultationCardForm.get('assessmentFinalDiagnosisControl').setValue(null);
      this.data.consultationCard.assessmentFinalDiagnosisFullString = '';
    }
  }
}
