
<div class="app-download-section" *ngIf="showAppDownloadSection">
  <div class="get_the_app ng-star-inserted">
    <div class="container-fluid">
      <div class="row get_app">
        <div class="col-1 pl-1 pr-0 align-self-center text-center">
          <i class="fas fa-times close_gray align-middle"></i>
          <mat-icon
            class="icon-remove close_gray align-middle"
            (click)="showAppDownloadSection = false"
            >clear</mat-icon
          >
        </div>
        <div class="col-3 align-self-center">
          <div class="box">
            <img alt="" src="assets/img/app-logo.png" />
          </div>
        </div>

        <div class="col-4 p-0 align-self-center">
          <div class="row">
            <div class="col-12 text-left h-13">
              <div class="title">REDMED APP</div>
            </div>
            <div class="col-12">
              <mat-icon class="icon-star">star</mat-icon>
              <mat-icon class="icon-star">star</mat-icon>
              <mat-icon class="icon-star">star</mat-icon>
              <mat-icon class="icon-star">star</mat-icon>
              <mat-icon class="icon-star">star_half</mat-icon>
            </div>
            <div class="col-12">
              <div class="desc">Get on the {{ getStoneName }} Store</div>
            </div>
          </div>
        </div>
        <div class="col-4 pl-0 align-self-center text-center">
          <button class="btn btn-sm btn_get_app" (click)="downloadApp()">
            GET THE APP
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="app-download-section" *ngIf="showAppDownloadSection">-->
<!--  <div class="container">-->
<!--    <div class="app-download-section&#45;&#45;box">-->

<!--      <div class="d-flex justify-content-start align-items-center">-->
<!--        <mat-icon class="icon-remove mr-4" (click)="showAppDownloadSection = false">clear</mat-icon>-->
<!--        <img src="assets/img/app-logo.png"-->
<!--             alt="" class="mr-4">-->
<!--        <div>-->
<!--          <p class="app-download-section&#45;&#45;box__title">Redmed App</p>-->
<!--          <div class="rating">-->
<!--            <mat-icon class="icon-star">star</mat-icon>-->
<!--            <mat-icon class="icon-star">star</mat-icon>-->
<!--            <mat-icon class="icon-star">star</mat-icon>-->
<!--            <mat-icon class="icon-star">star</mat-icon>-->
<!--            <mat-icon class="icon-star">star_border</mat-icon>-->
<!--          </div>-->
<!--          <small>Get on the {{getStoneName}} store</small>-->
<!--        </div>-->
<!--      </div>-->

<!--      <a href="https://cutt.ly/mGDTG0W" type="button" class="btn w-initial h-auto">get the app</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="wrapper-all">
<header
  #triggerMenu="cdkOverlayOrigin"
  cdkOverlayOrigin
  class="redmed-header d-flex header-border align-items-center"
>
  <div class="container d-flex justify-content-between">
    <a
      [routerLink]="currentUser?.isDoctor ? 'profile/booking' : '/'"
      class="redmed-logo"
    >
      <mat-icon svgIcon="logo"></mat-icon>
    </a>
    <app-header-navigation
      [mobileSizeScreen]="mobileSizeScreen"
      [user]="currentUser"
    ></app-header-navigation>
    <ng-container *ngIf="mobileSizeScreen">
      <button (click)="toggleBurgerMenu()" mat-icon-button class="cursor">
        <mat-icon *ngIf="!showBurgerMenu">menu</mat-icon>
        <img class="cursor" *ngIf="showBurgerMenu" src="../../../../assets/icons/x-icon.png" alt="">
      </button>
      <ng-template
        (backdropClick)="toggleBurgerMenu()"
        [cdkConnectedOverlayOpen]="showBurgerMenu"
        [cdkConnectedOverlayOrigin]="triggerMenu"
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        cdkConnectedOverlayHasBackdrop
      >
        <div class="burger-menu" [ngClass]="{'burger-menu-login' : !currentUser}">
          <mat-expansion-panel
            
            class="mat-elevation-z0"
            hideToggle
          >

          </mat-expansion-panel>
          <div class="auth-wrapper" *ngIf="!currentUser" (click)="changeLanguage(getLanguageImg())">
            <button
              class="languageButton height-class"
              *ngIf="mobileSizeScreen"
              
            >
              {{ selectedLanguage == "ka" ? "ENG" : "ქარ" }}
            </button>
            <button class="auth-button" routerLink="user/auth-user">{{ "auth" | translate }}</button>
            <button class="register-button" routerLink="user/register">{{ "register" | translate }}</button>
          <!-- <div
            *ngIf="!currentUser"
            class="burger-menu-item align-items-center"
            [routerLink]="currentUser?.isDoctor ? 'profile/booking' : '/'"
          >
            <span
              [inlineSVG]="'assets/img/header/home-small.svg'"
              class="svg-15 mr-2"
            ></span>
            <span>{{ "home" | translate }}</span>
          </div> -->
          
        </div>
          <mat-expansion-panel
            *ngIf="currentUser"
            class="mat-elevation-z0"
            hideToggle
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="align-items-center">
                <span
                  [inlineSVG]="'assets/img/header/profile-small.svg'"
                  class="svg-15 mr-2"
                ></span>
                <span>{{
                  currentUser.isDoctor
                    ? ("account" | translate)
                    : currentUser.firstname
                }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-item" routerLink="profile/edit">
              <span>{{ "profile" | translate }}</span>
            </button>
            <button
              *ngIf="currentUser?.isDoctor"
              class="menu-item"
              routerLink="profile/doctor-edit"
            >
              <span>{{ "settings" | translate }}</span>
            </button>
            <button (click)="showIntercom()" class="menu-item">
              <span>{{ "help" | translate }}</span>
            </button>
            <div
              (click)="$event.stopPropagation()"
              *ngIf="currentUser.showInstantCallToggle"
              class="menu-item instant-call-switch"
            >
              <mat-slide-toggle
                (change)="switchDoctorInstantCallStatus()"
                [(ngModel)]="currentUser.isInstantCallEnabled"
                color="accent"
                labelPosition="before"
              >
                {{ "quick-call" | translate }}
              </mat-slide-toggle>
            </div>
            <button (click)="logout()" class="menu-item">
              <span>{{ "exit,-log-out" | translate }}</span>
            </button>
          </mat-expansion-panel>

          <div
            *ngIf="currentUser?.isDoctor"
            class="burger-menu-item"
            routerLink="profile/doctor-services"
          >
            <mat-icon
              style="margin-right: 8px"
              svgIcon="stethoscope"
            ></mat-icon>
            <span>{{ "services" | translate }}</span>
          </div>
          <div
            *ngIf="currentUser?.isDoctor"
            class="burger-menu-item"
            routerLink="profile/doctor-calendar"
          >
            <mat-icon style="margin-right: 8px" svgIcon="calendar"></mat-icon>
            <span>{{ "calendar" | translate }}</span>
          </div>
          <div
            *ngIf="currentUser"
            class="burger-menu-item"
            routerLink="profile/booking"
          >
            <span
              [inlineSVG]="'assets/img/header/calendar-small.svg'"
              class="svg-15 mr-2"
            ></span>
            <span>{{ "booking" | translate }}</span>
          </div>
        <div
          *ngIf="currentUser"
          class="burger-menu-item"
        >
        <span>
          <button
            class="languageButton"
            *ngIf="mobileSizeScreen"
            (click)="changeLanguage(getLanguageImg())"
            [ngClass]="{'rounder' : currentUser}"
          >
            {{ selectedLanguage == "ka" ? "ENG" : "ქარ" }}
          </button>
        </span>
        </div>
          
          <div class="footer-wrapper" *ngIf="!currentUser" #matExpansionPanel>
            <mat-expansion-panel
            class="mat-elevation-z0"
            hideToggle
          >
            <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[0])">
              <mat-panel-title class="align-items-center info-title" >
                <span>{{titleArray[0].title | translate}}</span>
                <span><img src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[0].isOpen}"></span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <button class="menu-item" [queryParams]="{doctor: true}" routerLink="user/auth-user">
              <span>{{'auth' | translate}}</span>
            </button>
            <button
              class="menu-item"
              routerLink="user/register-doctor"
            >
              <span>{{'register' | translate}}</span>
            </button>
            <button (click)="onAppDownloadClick()" class="menu-item">
              <span>{{'download-doctor-app' | translate}}</span>
            </button>
          </mat-expansion-panel>
          <mat-expansion-panel
          class="mat-elevation-z0"
          hideToggle
        >
          <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[1])">
            <mat-panel-title class="align-items-center info-title" >
              <span>{{titleArray[1].title | translate}}</span>
              <span><img src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[1].isOpen}"></span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <button class="menu-item" routerLink="about">
            <span>{{'about' | translate}}</span>
          </button>
          <button
            class="menu-item"
            (click)="scrollToHowItWorksSection()"
          >
            <span>{{'howItWorks' | translate}}</span>
          </button>
          <button routerLink="blog-list" *ngIf="selectedLanguage === 'ka'" class="menu-item">
            <span>{{'blog' | translate}}</span>
          </button>
          <button routerLink="contact" class="menu-item">
            <span>{{'contact' | translate}}</span>
          </button>
        </mat-expansion-panel>
        <mat-expansion-panel
        class="mat-elevation-z0 lastOne"
        hideToggle
      >
        <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[2])">
          <mat-panel-title class="align-items-center info-title" >
            <span>{{titleArray[2].title | translate}}</span>
            <span><img  src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[2].isOpen}"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <button class="menu-item" (click)="goToPage('doctor-terms')">
          <span>{{'only-doctor' | translate}}</span>
        </button>
        <button
          class="menu-item"
          (click)="goToPage('userterms')"
        >
          <span>{{'only-patient' | translate}}</span>
        </button>

      </mat-expansion-panel>
          </div>
          <button class="app-button" *ngIf="!currentUser" (click)="onOpenAppClick()">
            {{ 'application' | translate }}
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</header>
</div>