export enum OrderStatusEnum {
  waitingForDoctorApprove = 2,
  doctorApproved = 3,
  doctorRejected = 4,
  patientCanceled = 5,
  doctorCanceled = 6,
  inProgress = 7,
  feedBack = 8,
  transferedToHelpGroup = 9,
  finished = 10,
  waitingForPatientApproval = 11,
  abandoned = 12,
  onWay = 13
}
