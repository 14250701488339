import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  downloadOrderInvoice(params: any, orderId: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('patientPn', params.patientPn);
    queryParams = queryParams.append('payerPn', params.payerPn);
    queryParams = queryParams.append('payerFullName', params.payerFullName);
    queryParams = queryParams.append('orderedByPn', params.orderedByPn);
    return this.http.get(`${this.apiUrl}/orders/invoice/${orderId}`, {params: queryParams, responseType: 'blob'})
      .pipe(
        map((response: any) => {
          return response
        }),
        catchError((error: Error) => {
          return throwError(error);
        })
      );
  }
}
