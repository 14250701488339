import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {UserService} from "../../services";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private router: Router, private userService: UserService) {
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.router.navigate([''])
        .then(() => {
          this.userService.logout();
        });
    }
  }
}
