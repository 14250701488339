import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService, UserService} from '../services';

import {CONFIG} from "../core/config/config";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class AuthGuard {

  private cLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;

  constructor(private authService: AuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private userService: UserService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.getCurrentUser()
      .pipe(
        map((user: any) => {
          return true;
        }),
        catchError(() => {
          if (state.url === '/profile/referral') {
            return this.router.navigate([`/user/auth`])
              .then(
                () => {
                  localStorage.setItem('referralSms', 'referralSms')
                  return false;
                });
          } else {
            return this.router.navigate([''])
              .then(
                () => {
                  return false;
                });
          }
        })
      );
    // this.authService.setLogged();
    // if (this.authService.isLogged()) {
    //   return true;
    // }
    // this.router.navigate([this.cLang + '/user/auth']);
    // return false;
  }

}
