import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DoctorService {
  apiUrl = environment.apiUrl;

  private selectedDoctorData = new BehaviorSubject(null);
  getSelectedDoctorData = this.selectedDoctorData.asObservable();
  private popularSpecialties: Observable<any> = this.http.get(`${this.apiUrl}/products/v2/GetPopularDoctor`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());



  constructor(private http: HttpClient) {
  }

  setSelectedDoctorData(data: any) {
    this.selectedDoctorData.next(data);
  }

  getAllDoctors(): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/doctors/all`)
    .pipe(
      map((response: any) => response),
      catchError((error: Error) => throwError(error))
    );
  }

  getAllActivateDoctors(): Observable<any> {
    return this.http.get(`${this.apiUrl}/users/doctors/count`)
    .pipe(
      map((response: any) => response),
      catchError((error: Error) => throwError(error))
    );
  }

  getPopularDoctors(): Observable<any> {
    return this.popularSpecialties;
  }



  requestDoctor(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/search/request_doctor`, data)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  waitForRequestedDoctor(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/search/request_doctor_notify_me`, data)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getRequestOtherSearchResult(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/search/request_doctor_log_search`, data)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

}
