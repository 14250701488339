import {MediaMatcher} from '@angular/cdk/layout';
import {isPlatformBrowser} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {CONFIG} from './core/config/config';
import {RouterHelper} from './helpers';
import {OrderModel} from './models/order.model';
import {AuthService, UserService} from './services';
import {SendBirdChatService} from './services/send-bird-chat.service';
import {MessagingService} from './shared/messaging.service';
import {UserModel} from './shared/models/user.model';
import {TwilioService} from './shared/services/twilio.service';
import {TbcConnectService} from "./services/tbc-connect.service";
import {HelpCrunchService} from "./shared/services/help-crunch.service";
import {Location} from '@angular/common';
import {LoadingService} from "./shared/services/loading.service";
declare let gtag:Function;
declare let fbq:Function;

declare var HelpCrunch: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  public langs: any;
  public cLang = CONFIG.LANGS.LANG_DATA.DEFAULT_LANG;
  public langsTitles: any;
  message: any;
  public show: boolean;
  public wasClosed: boolean;
  public allertShow: boolean;
  public preference: boolean;
  public performance: boolean;
  callInProgress = false;
  isVideo: boolean;
  currentOrder: OrderModel;
  caller: string;
  currentUser: UserModel;
  @ViewChild('frame') ssoFrame: ElementRef<HTMLIFrameElement>;
  mobileQuery: MediaQueryList;
  hideHeader: boolean;
  headerHiddenRoutes = [
    'privacypolicy',
    'cookies-policy',
    'doctor-terms',
    'userterms',
    'offers',
    'ekimtankonsultacia',
    'ufaso_videorcheva',
    'sameano-eqoskopia',
    'TBCI-COVCLINIC',
    'redmed-terapevti',
    'redmed-pediatri',
    'endokrinologis-konsultacia',
    'gaige-martivad'
  ];
  showFirework: boolean;
  private listenerCustomerContainerApp: any;
  private readonly mobileQueryListener: () => void;
  private selectedLanguage = localStorage.getItem('CurLang') || 'ka';

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,
    private RH: RouterHelper,
    private RT: Router,
    private ART: ActivatedRoute,
    public meta: Meta,
    private messagingService: MessagingService,
    private userService: UserService,
    private twilioService: TwilioService,
    private authService: AuthService,
    private sendBird: SendBirdChatService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private tbcConnectService: TbcConnectService,
    private helpCrunchService: HelpCrunchService,
    private location: Location,
    public loadingService: LoadingService,
    private title: Title) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    this.RT.events.subscribe((evt: RouterEvent) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // gtag('config','UA-{ID}',{'page_path' : evt.url});
      fbq('track', 'PageView');
      this.addLanguageIntoUrl();
      if (isPlatformBrowser(this.platformId)) {
        if (!localStorage.getItem('firework') && (evt.url === '/' || evt.url === '/ka')) {
          localStorage.setItem('firework', 'true');
          this.showFirework = true;
          setTimeout(() => {
            this.showFirework = false;
          }, 3500)
        }
        this.tbcConnectService.sendPageView();
        window.scrollTo(0, 0);
      }
    });
  }
  ngOnInit(): void {
    // const sessionValue = sessionStorage.getItem('isFirstTime');
    // if (sessionValue === null) {
    //   // If the value doesn't exist, it means it's the first time in this session
    //   this.allertShow = true;
    //   // Set the value in SessionStorage
    //   sessionStorage.setItem('isFirstTime', 'true');
    // } else {
    //   // If the value exists, parse it to boolean
    //   this.allertShow = sessionValue === 'false';
    // }

    this.allertShow=true;
    console.log(this.allertShow)
    this.checkAlertStatus();
    console.log(this.allertShow)
  }


  ngAfterViewInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.selectedLanguage = urlParams.get('selectedLanguage') || localStorage.getItem('CurLang') || 'ka';
    this.loadingService.setApplicationLoading(true);
    this.translate.getTranslation(this.selectedLanguage).subscribe(res => {
      this.loadingService.setApplicationLoading(false, true);


      setTimeout(() => {
        this.helpCrunchService.setHelpCrunchLanguage();

        const usr = {
          company: 'no'
        };
        HelpCrunch('updateUser', usr);

        HelpCrunch('onChatClose', (s) => {
          const user = {
            company: 'no'
          };
          HelpCrunch('updateUser', user);
        });

        if (isPlatformBrowser(this.platformId) && environment.production) {
          this.title.setTitle(this.translate.instant('doctor-online-consultation') + ' | REDMED - ' + this.translate.instant('doctor-with-you'));
          this.initHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
        this.tbcConnectService.sendCategoryView(this.mobileQuery);
        this.userService.getUser
          .subscribe(
            (user: UserModel) => {
              if (isPlatformBrowser(this.platformId)) {
                if (user) {
                  this.currentUser = new UserModel().deserialize(user);
                  if (this.currentUser.isClinicAdmin) {
                    this.RT.navigate(['clinic']);
                  }
                  this.messagingService.requestPermission(user.userId);
                  this.messagingService.receiveMessage();
                  this.initTwilio(user);
                  this.sendBird.connectToSendBird(user);
                  this.helpCrunchService.initHelpCrunchUser(user);
                } else {
                  this.twilioService.destroyDevice();
                }
              }
            });
        this.message = this.messagingService.currentMessage;

      }, 500);
    });

    // if (isPlatformBrowser(this.platformId)) {
    //   this.triggerAppOpen();
    // }

    if (isPlatformBrowser(this.platformId)) {
      this.ssoFrame.nativeElement.onload = () => {
        this.authService.setIframe(this.ssoFrame.nativeElement);
        this.checkUserSsoStatus();
      };
    }
  }

  checkUserSsoStatus() {
    if (!this.listenerCustomerContainerApp) {
      this.listenerCustomerContainerApp = this.getUserSsoToken.bind(this);
      this.addSsoMessageListener();
      this.ssoFrame.nativeElement.contentWindow.postMessage({
        action: 'check',
        data: 'accessToken'
      }, 'https://sso.redmed.ge');
    }
  }

  handleShowChange(value){
    this.show=value;
  }

  handleShowAllertChange(value){
    this.allertShow=value;
  }

  handleOpenDialog(value){
    this.show=value;
  }

  handlePreference(value){
    this.preference=value;
  }

  handlePerformance(value){
    this.performance=value;
  }

  handleAgree(value){
    if(value){
      const agreementTimestamp = Date.now();
      localStorage.setItem('alertAgreementTimestamp', agreementTimestamp.toString());
      this.allertShow = false;
    }
  }

  checkAlertStatus() {
    const agreementTimestamp = localStorage.getItem('alertAgreementTimestamp');
    if (agreementTimestamp) {
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      const agreementDate = new Date(parseInt(agreementTimestamp, 10));
      if (agreementDate.getTime() >= twoMonthsAgo.getTime()) {
        this.allertShow = false;
      }
    }
  }

  getUserSsoToken(event) {
    if (event.origin === 'https://sso.redmed.ge' && event.data.action === 'check') {
      if (event.data.data.remove) {
        this.removeSsoMessageListener();
        this.userService.logout();
        this.messagingService.logoutFirebase();
      }
    }
  }

  addSsoMessageListener() {
    window.addEventListener('message', this.listenerCustomerContainerApp, false);
  }

  removeSsoMessageListener() {
    window.removeEventListener('message', this.listenerCustomerContainerApp, false);
  }

  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return 'iOS';
    }
    return null;
  }

  goToStore() {
    if (this.getMobileOperatingSystem() === 'Android') {
      window.location.replace('market://details?id=ge.redmed.patient');
    } else if (this.getMobileOperatingSystem() === 'iOS') {

    }
  }

  modifyHeader() {
    this.hideHeader = new RegExp(this.headerHiddenRoutes.join('|')).test(this.RT.url.split('?')[0]);
  }

  private initHotJar(h, o, t, j, a?, r?) {
    h.hj = h.hj || function() {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
    h._hjSettings = {hjid: 1797206, hjsv: 6};
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  }

  private initTwilio(user) {
    this.twilioService.initTwilioDevice(user);
    this.twilioService.checkCallProgress
      .subscribe((progress: { inProgress: boolean, isAudio: boolean, order: OrderModel, caller?: string }) => {
        if (progress) {
          this.callInProgress = progress.inProgress;
          if (progress.order) {
            if (progress.order.hasOwnProperty('doctorName') && progress.order.doctorName) {
              this.caller = progress.order.doctorName;
            } else {
              this.caller = progress.order.patientName;
            }
          }
          // this.caller = progress.caller;
          this.isVideo = !progress.isAudio;
          this.currentOrder = progress.order;
        } else {
          this.callInProgress = false;
          this.isVideo = false;
          this.currentOrder = null;
          this.caller = null;
        }
      });
  }

  private addLanguageIntoUrl(): void {
    const url = this.RT.createUrlTree([], {relativeTo: this.ART, queryParams: {...this.ART.queryParams['value'], selectedLanguage: this.selectedLanguage}}).toString();
    localStorage.setItem('CurLang', this.selectedLanguage);
    console.log(url);
    this.location.replaceState(url);
  }
}
