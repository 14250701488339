<h1 class="d-flex align-items-center justify-content-between mb-0 font-25" mat-dialog-title>
  <span class="font-weight-bold">{{'reservation-request' | translate}}</span>
  <span (click)="closeDialog()" [inlineSVG]="'assets/img/times1.svg'" class="svg-12 cursor-pointer"></span>
</h1>
<div class="font-16 py-5 request-info" mat-dialog-content>
  <span>{{'if-you-want-to-get-advice-quickly,-select-now' | translate}}</span>
  <span (click)="getOtherAvailableDoctors()" class="show-available-doctors">{{'from-the-list-of-available-doctors' | translate}}</span>
  <span>{{'your-request-has-been-sent-to-the-doctor-and-you-will-receive-an-answer-within-3-days' | translate}}</span>
</div>
<div class="d-flex justify-content-center pb-3" mat-dialog-actions>
  <button (click)="waitForDoctor()" matRipple>{{"i-will-wait-for-the-doctor's-calendar" | translate}}</button>
</div>
