<input #suggestionInput (focusin)="getCommonSuggestions()"
       (focusout)="emitCloseSuggestionInput($event)"
       (keydown.enter)="goSearch()" [formControl]="suggestionFormControl"
       [matAutocomplete]="auto"
       autocomplete="off" class="standard-input" id="search"
       matAutocompletePosition="below" [placeholder]="'doctor,-specialty,-clinic,-insurance' | translate"
       type="text" [style.max-width]="mobileSizeScreen ? '100%' : '60%'">
<mat-autocomplete #auto="matAutocomplete" class="panel-class">
  <div *ngIf="specialties.length" class="suggestion-title">{{'specialty'|translate}}</div>
  <mat-option (click)="goSearch(speciality.SpecialtyId)"
              *ngFor="let speciality of specialties">{{speciality.SpecialityName}}</mat-option>

  <div *ngIf="doctors.length" class="suggestion-title">{{'doctors'|translate}}</div>
  <mat-option (click)="goSearch(0, false, doctor.userId)" *ngFor="let doctor of doctors">
    <div class="name font-weight-bold"><span>{{doctor.name}}</span></div>
  </mat-option>

  <div *ngIf="clinics.length" class="suggestion-title">{{'provider'|translate}}</div>
  <mat-option (click)="goSearch(0, false, null, clinic)" *ngFor="let clinic of clinics">
    <div class="name font-weight-bold"><span>{{clinic.name}}</span></div>
  </mat-option>

  <div *ngIf="providers.length" class="suggestion-title">{{'insuranceProvider'|translate}}</div>
  <mat-option (click)="goSearch(0, false, null, null, provider)" *ngFor="let provider of providers">
    <div class="name font-weight-bold"><span>{{provider.name}}</span></div>
  </mat-option>
</mat-autocomplete>
