import {HttpParams} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as FileSaver from 'file-saver';
import {UserService} from 'src/app/services';
import {SpinnerService} from 'src/app/services/spinner.service';
import {InvoiceDialogComponent} from 'src/app/shared/components/invoice-dialog/invoice-dialog.component';
import {environment} from 'src/environments/environment';
import {DocumentsAvailabilityForDoctorModel} from '../../../interfaces/documents-availability-for-doctor-interface';
import {DocumentsAvailabilityForUserInterface} from '../../../interfaces/documents-availability-for-user-interface';
import {ConsultationCardComponent} from '../../../shared/dialogs/consultation-card/consultation-card.component';
import {FormOneHundredComponent} from '../../../shared/dialogs/form-one-hundred/form-one-hundred.component';
import {UserModel} from '../../../shared/models/user.model';
import {DocumentationService} from '../../../shared/services/documentation.service';
import {LoadingService} from '../../../shared/services/loading.service';
import {SuccessDialogComponent} from "../../../dialogs/success-dialog/success-dialog.component";
import {SendDocumentViaEmailDialogComponent} from "../../../shared/dialogs/send-document-via-email-dialog/send-document-via-email-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-documents-tab',
  templateUrl: './documents-tab.component.html',
  styleUrls: ['./documents-tab.component.scss']
})
export class DocumentsTabComponent implements OnInit {

  /* variables */
  @Input() orderId: string;
  public documentsAvailability: any | DocumentsAvailabilityForDoctorModel | DocumentsAvailabilityForUserInterface;
  private apiUrl = environment.apiUrl;
  currentUser: UserModel;

  constructor(
    private _userService: UserService,
    private _dialog: MatDialog,
    public spinnerService: SpinnerService,
    private loadingService: LoadingService,
    private _snackBar: MatSnackBar,
    private documentationService: DocumentationService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this._userService.getUser
      .subscribe(
        (user) => {
          if (user) {
            this.currentUser = new UserModel().deserialize(user);
            this.getDocumentsAvailabilityForUser();
          }
        });
  }

  public downloadInvoice(): void {
    const dialog = this._dialog.open(InvoiceDialogComponent, {
      data: {
        orderId: this.orderId
      }
    });
  }

  /* getRequestDocument */
  public getRequestDocument(documentTypeId: string): void {
    let data = {
      documentTypeId: documentTypeId
    };
    this.loadingService.setApplicationLoading(true);
    this._userService.getRequestDocument(this.orderId, data).subscribe(
      result => {
        this.loadingService.setApplicationLoading(false);
        this.getDocumentsAvailabilityForUser();
      },
      error => {
        this.loadingService.setApplicationLoading(false);
        console.log(error);
      }
    );
  }

  /* get document availability for user */
  public getDocumentsAvailabilityForUser(): void {
    this.loadingService.setApplicationLoading(true);
    this._userService.getDocumentsAvailability(this.orderId, this._userService.isDoctor()).subscribe(
      (result) => {
        this.documentsAvailability = result;
        this.spinnerService.spinner = false;
        this.loadingService.setApplicationLoading(false);

      },
      error => {
        console.log(error.error);
        this.spinnerService.spinner = false;
        this.loadingService.setApplicationLoading(false);
      });
  }

  /* form 100 preview */
  public previewPdfFormOneHundred(): void {
    this.loadingService.setApplicationLoading(true);
    this._userService.previewPdfFormOneHundred(this.documentsAvailability.formOneHundred.id).subscribe(
      (result: any) => {
        this.loadingService.setApplicationLoading(false);
        this.download(result, 'ფორმა_100', 'pdf');
      }, () => {
        this.loadingService.setApplicationLoading(false);
      });
  }

  /* form 100 download */
  public downloadPdfFormOneHundred(): void {
    this.loadingService.setApplicationLoading(true);
    this._userService.downloadPdfFormOneHundred(this.documentsAvailability.formOneHundred.id).subscribe(
      (result: any) => {
        this.loadingService.setApplicationLoading(false);
        this.zip(result, 'ფორმა_100', 'zip');
      }, () => {
        this.loadingService.setApplicationLoading(false);
      });
  }

  /* consultant card preview */
  public previewPdfConsultationcard(): void {
    this.loadingService.setApplicationLoading(true);
    this._userService.previewPdfConsultationcard(this.documentsAvailability.consultationCard.id).subscribe(
      (result: any) => {
        this.download(result, 'კონსულტაციის_ბარათი', 'pdf');
        this.loadingService.setApplicationLoading(false);
      }, () => {
        this.loadingService.setApplicationLoading(false);
      });
  }

  /* consultant card download */
  public downloadPdfConsultationcard(): void {
    this.loadingService.setApplicationLoading(true);
    this._userService.downloadPdfConsultationcard(this.documentsAvailability.consultationCard.id).subscribe(
      (result: any) => {
        this.zip(result, 'კონსულტაციის_ბარათი', 'zip');
        this.loadingService.setApplicationLoading(false);
      }, () => {
        this.loadingService.setApplicationLoading(false);
      }
    );
  }

  handleAction(item: string, action: string, send: boolean = false) {
    if (item === 'consultationCard') {
      if (action === 'edit') {
        this.editConsultationCard(send);
      } else if (action === 'preview') {
        this.previewPdfConsultationcard();
      } else if (action === 'download') {
        this.downloadPdfConsultationcard();
      } else if (action === 'send') {
        this.sendDocumentationToUser(5);
      } else if (action === 'request') {
        this.getRequestDocument('5');
      } else if (action === 'add') {
        this.addNewDocument(5);
      } else if (action === 'sendViaEmail') {
        this.sendViaEmail(1);
      }
    } else if (item === 'formOneHundred') {
      if (action === 'edit') {
        this.editFormOneHundred(send);
      } else if (action === 'preview') {
        this.previewPdfFormOneHundred();
      } else if (action === 'download') {
        this.downloadPdfFormOneHundred();
      } else if (action === 'send') {
        this.sendDocumentationToUser(8);
      } else if (action === 'request') {
        this.getRequestDocument('8');
      } else if (action === 'add') {
        this.addNewDocument(8);
      } else if (action === 'sendViaEmail') {
        this.sendViaEmail(2);
      }
    } else if (item === 'invoice') {
      this.downloadInvoice();
    } else if (item === 'receiptOfPayment') {
      if (action === 'request') {
        this.getRequestDocument('9');
      } else if (action === 'download') {
        this.downloadReceipt(false);
      } else if (action === 'preview') {
        this.downloadReceipt(true);
      } else if (action === 'sendViaEmail') {
        this.sendViaEmail(3);
      }
    }
  }

  editConsultationCard(send: boolean = false) {
    let params = new HttpParams();
    params = params.append('documentTypeId', '5');
    this.documentationService.getOrderDocument(this.orderId, params)
      .subscribe(
        (response: any) => {
          const dialog = this._dialog.open(ConsultationCardComponent, {
            data: {
              consultationCard: response.data,
              orderId: this.orderId,
              send: send
            },
            height: '90vh'
          });
          dialog.afterClosed()
            .subscribe(
              (result) => {
                if (result) {
                  if (result === 'preview') {
                    this.previewPdfConsultationcard();
                  } else if (result === 'send') {
                    this.sendDocumentationToUser(5);
                  }
                }
              }
            );
        }
      );
  }

  /* preview */
  private download(file: any, fileName: string, type: string) {
    let blob: any = new Blob([file], {type: `application/${type}`});
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(file);
    link.download = `${fileName}.${type}`; // forma_100.pdf
    link.click();
    window.URL.revokeObjectURL(url);
  }

  /* generate zip */
  private zip(file: any, fileName: string, type: string) {
    let binaryData = [];
    binaryData.push(file);
    let blob: any = new Blob(binaryData, {type: `application/${type}`});
    FileSaver.saveAs(blob, `${fileName}.${type}`);
  }

  addNewDocument(documentTypeId) {
    this.documentationService.addNewDocument(documentTypeId, this.orderId)
      .subscribe(
        (response: any) => {
          this.getDocumentsAvailabilityForUser();
          if (documentTypeId === 8) {
            this.editFormOneHundred();
          } else if (documentTypeId === 5) {
            this.editConsultationCard();
          }
        }
      );
  }

  private sendDocumentationToUser(documentTypeId: number) {
    this.loadingService.setApplicationLoading(true);
    this.documentationService.sendDocumentToUser(documentTypeId, this.orderId)
      .subscribe(
        (response: any) => {
          this.loadingService.setApplicationLoading(false);
          this._snackBar.open(this.translateService.instant('documet-has-been-successfully-sent'), '', {
            duration: 3000,
            verticalPosition: 'bottom'
          });
          this.getDocumentsAvailabilityForUser();
        }, () => {
          alert(this.translateService.instant('please-fill-required-fields'));
          this.loadingService.setApplicationLoading(false);
        }
      );
  }

  private editFormOneHundred(send: boolean = false) {
    let params = new HttpParams();
    params = params.append('documentTypeId', '8');
    this.documentationService.getOrderDocument(this.orderId, params)
      .subscribe(
        (response: any) => {
          const dialog = this._dialog.open(FormOneHundredComponent, {
            data: {
              formOneHundred: response.data,
              orderId: this.orderId,
              send: send
            },
            height: '90vh'
          });
          dialog.afterClosed()
            .subscribe(
              (result) => {
                if (result) {
                  if (result === 'preview') {
                    this.previewPdfFormOneHundred();
                  } else if (result === 'send') {
                    this.sendDocumentationToUser(8);
                  }
                }

              }
            );
        }
      );
  }

  private downloadReceipt(isPreview: boolean) {
    const link = isPreview ? this.documentsAvailability.receiptOfPayment.previewUrl : this.documentsAvailability.receiptOfPayment.downloadUrl;
    this.documentationService.downloadReceipt(this.documentsAvailability.receiptOfPayment.downloadUrl)
      .subscribe((result) => {
        this.download(result, 'გადახდის_ქვითარი', 'pdf');
      });
  }

  sendViaEmail(documentType: number) {

    this.loadingService.setApplicationLoading(true);
    this.documentationService.getDocumentationEmailInfo(documentType, this.orderId).subscribe(
      (data: any) => {
        if (data) {
          this.loadingService.setApplicationLoading(false);
          const dialogRef = this._dialog.open(SendDocumentViaEmailDialogComponent, {
            width: '520px',
            data : {
              message: data.data.infoText,
              email: data.data.email,
              orderId: this.orderId,
              documentType: documentType
            }
          })
        }
      }, error => {
        this.loadingService.setApplicationLoading(false);
        console.error(error)
      }
    );
    // this.documentationService.sendDocumentViaEmail(number, this.orderId)
    //   .subscribe(
    //     (response: any) => {
    //       this.loadingService.setApplicationLoading(false);
    //       this._snackBar.open('დოკუმენტი წარმატებით გაიგზავნა', '', {
    //         duration: 3000,
    //         verticalPosition: 'bottom'
    //       });
    //       this.getDocumentsAvailabilityForUser();
    //     }, () => {
    //       alert('გთხოვთ შეავსოთ სავალდებულო ველები');
    //       this.loadingService.setApplicationLoading(false);
    //     }
    //   );
  }
}
