<h1 class="font-25 font-wight-bold align-items-center">
    <span class="d-flex justify-content-between">
        <span class="font-wight-bold uppercase">{{'download-the-invoice' | translate}}</span>
        <span (click)="closeDialog()" class="svg-14 cursor-pointer" [inlineSVG]="'assets/img/times1.svg'"></span>
    </span>
</h1>
<div mat-dialog-content>
  <form [formGroup]="invoiceFormGroup">
    <div class="form-group">
      <input autocomplete="new-password" class="standard-input"
             formControlName="patientPn"
             maxlength="11" [placeholder]="'patients-p/n' | translate">
    </div>
    <div class="form-group">
      <input autocomplete="new-password" class="standard-input"
             formControlName="payerPn"
             maxlength="11" [placeholder]="'p/n-of-the-payer' | translate">
    </div>
    <div class="form-group">
      <input autocomplete="new-password" class="standard-input"
             formControlName="payerFullName"
             [placeholder]="'payers-name,-surname' | translate">
    </div>
    <div class="form-group">
      <input autocomplete="new-password" class="standard-input"
             formControlName="orderedByPn"
             maxlength="11" [placeholder]="'customer-p/n' | translate">
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
  <button (click)="closeDialog()" class="w-initial px-4">{{'cancel' | translate}}</button>
  <button matRipple class="w-initial px-4" [disabled]="downloadInProgress || invoiceFormGroup.invalid"
          (click)="downloadInvoice()">
    {{'download' | translate}}
  </button>
</div>
