import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../services";

@Component({
  selector: 'app-incoming-call-dialog',
  templateUrl: './incoming-call-dialog.component.html',
  styleUrls: ['./incoming-call-dialog.component.scss']
})
export class IncomingCallDialogComponent implements OnInit {
  currentUser: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<IncomingCallDialogComponent>,
              private userService: UserService) {
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUser
      .subscribe(
        (user: any) => {
          this.currentUser = user;
        }
      );
  }

  answerCall(answer: boolean) {
    this.dialogRef.close(answer);
  }

  updateUrl($event) {
    $event.target.src = 'assets/photos/avatar-3.png';
  }
}
