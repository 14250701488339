import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'document',
  pure: true
})
export class DocumentPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'consultationCard':
        return this.translateService.instant('consultation-card');
      case 'invoice':
        return this.translateService.instant('invoice');
      case 'formOneHundred':
        return this.translateService.instant('form-100');
      case 'receiptOfPayment':
        return this.translateService.instant('payment-receipt');
    }
    return null;
  }

}
