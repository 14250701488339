import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map, publishReplay, refCount} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  apiUrl = environment.apiUrl;
  private cities: Observable<any> = this.http.get(`${this.apiUrl}/users/v2/GetCities`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());
  private languages: Observable<any> = this.http.get(`${this.apiUrl}/users/v2/GetLanguage`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());

  constructor(private http: HttpClient) {
  }

  getCities(): Observable<any> {
    return this.cities;
  }

  getLanguages(): Observable<any> {
    return this.cities;
  }
}
