import * as moment from 'moment';
import {Moment} from 'moment';

export function momentGeoTimeParser(value?: Moment, pure?: boolean): number {
  if (pure) {
    return value.valueOf() - getZoneDiffTime();
  }
  const geoTimeString = new Date().toLocaleString("ka-GE", {timeZone: "Asia/Tbilisi"});
  const geoTime = new Date(geoTimeString);
  const now = new Date();
  const centralTime = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds()).getTime();
  const diffBetweenDates = now.getTime() - (value ? value.valueOf() : moment().valueOf());
  // const centralTime = (now.getTime() + now.getTimezoneOffset() * 60 * 1000);
  if (!value) {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Tbilisi') {
      return now.getTime();
    }
    if (moment().isSame(moment(geoTime), 'day')) {
      return geoTime.getTime() - (geoTime.getTime() - moment().valueOf());
    }
    return geoTime.getTime() - (moment().valueOf() - geoTime.getTime()) - diffBetweenDates;
  }
  const argumentTime = value.valueOf();
  const differenceTime = (centralTime - argumentTime) + 14400000;
  if (Intl.DateTimeFormat().resolvedOptions().timeZone !== 'Asia/Tbilisi') {
    if (moment(geoTimeString).isSame(moment(), 'day')) {
      const current = moment();
      const localOffset = current.utcOffset();
      const centralOffset = current.utcOffset();
      const diffInMinutes = localOffset - centralOffset;

      console.log('diff minutes: ' + diffInMinutes);
      console.log('diff time: ' + differenceTime);
      console.log('central time: ' + centralTime);
      console.log('argument time: ' + argumentTime);
      console.log('return time: ' + (argumentTime + diffBetweenDates));
      if (differenceTime < 480000) {
        return argumentTime + diffBetweenDates - differenceTime;
      }
      return argumentTime + diffBetweenDates;
    }
  }
  return value.valueOf();
}

export function getZoneDiffTime(): number {
  const current = moment();
  const localOffset = current.utcOffset();
  const centralOffset = current.utcOffset();
  const diffInMinutes = localOffset - centralOffset;
  return diffInMinutes * 60 * 1000;
}
