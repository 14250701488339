import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessService {

  private successPopupText = new BehaviorSubject<any>(null);
  private failPopupText = new BehaviorSubject<any>(null);
  getSuccessPopupText = this.successPopupText.asObservable();
  getFailPopupText = this.failPopupText.asObservable();

  constructor() {
  }

  openSuccessPopup(title: string, time: number){
    this.successPopupText.next(title)
    this.closeSuccessPopup(time)
  }

  openFailPopup(title: string, time: number){
    this.failPopupText.next(title)
    this.closeFailPopup(time)
  }

  closeSuccessPopup(time: number){
    setTimeout(() => {
      this.successPopupText.next(null)
    }, time)
  }

  closeFailPopup(time: number){
    setTimeout(() => {
      this.failPopupText.next(null)
    }, time)
  }

}
