<h1 class="text-center d-flex align-items-center justify-content-between" mat-dialog-title>
  <span></span>
  <span (click)="closeDialog()" [inlineSVG]="'assets/img/times1.svg'"
        class="svg-15 d-flex align-items-center justify-content-center"></span>
</h1>
<div class="mb-4 text-center" mat-dialog-content>
  {{ data.message }} <br> <!-- ???translate??? -->
  <b>{{ data.email }}</b>
</div>
<div class="d-flex align-items-center justify-content-center mb-3" mat-dialog-actions>
  <button (click)="sendToEmail()"  style="width: 260px !important;">{{'send' | translate}}</button>
</div>
