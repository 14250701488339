export const environment = {
  baseUrl: 'https://redmed.ge',
  apiUrl: 'https://redmed.ge/web/api',
  production: true,
  firebase: {
    apiKey: "AIzaSyAJZozlM1AFwf_Uj8oaEZ04IXJA3baMauM",
    authDomain: "redmedgeorgia.firebaseapp.com",
    databaseURL: "https://redmedgeorgia.firebaseio.com",
    projectId: "redmedgeorgia",
    storageBucket: "redmedgeorgia.appspot.com",
    messagingSenderId: "210926652680",
    appId: "1:210926652680:web:6e277a2698bac4ef62b0bd"
  }
};
