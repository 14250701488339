<div *ngIf="!hide">
  <div *ngIf="innerWidth < 768 && showDownloadFreezer && !isDownload" class="app-block justify-content-center">
    <div (click)="hideDownload()" class="close">X</div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 d-none d-lg-flex align-items-end">
          <img loading="lazy" alt="" class="w-100" src="/assets/img/download.png">
        </div>
        <div class="col-lg-5 d-flex align-items-center justify-content-center justify-content-start py-5">
          <div>
            <h1 class="font-30 font-weight-bold uppercase text-white">{{'doctor-with-you' | translate}}, <br> {{'download-app' | translate}}</h1>
            <div class="d-flex stores">
              <a href="https://apps.apple.com/us/app/redmed/id1483844703" target="_blank">
                <img loading="lazy" alt="" class="mr-3" src="/assets/photos/appstore.png">
              </a>
              <a href="https://play.google.com/store/apps/developer?id=Redmed" target="_blank">
                <img loading="lazy" alt="" src="/assets/photos/playstore.png">
              </a>
            </div>
            <div>
              <span class="d-flex text font-14 text-white">{{'get-download-link' | translate}}</span>
              <div class="form-row">
                <div class="col-lg-8 position-relative mb-lg-0 mb-3">
                  <label class="phone-index font-14" for="phone">+995 |</label>
                  <input [formControl]="phoneControl" class="standard-input" id="phone" [placeholder]="'phone-number' | translate"
                         type="tel">
                </div>
                <div class="col-lg-4">
                  <button (click)="sendDownloadLink()" class="bg-white text-dark" matRipple>{{'send' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="isDownload" class="app-block d-flex align-items-center justify-content-center">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 d-none d-lg-flex align-items-end">
        <img loading="lazy" alt="" class="w-100" src="/assets/img/download.png">
      </div>
      <div class="col-lg-5 d-flex align-items-center justify-content-center justify-content-start py-5">
        <div>
          <h1 class="font-30 font-weight-bold uppercase text-white">{{'doctorWithYou' | translate}}, <br> {{'downloadApplication' | translate}}</h1>
          <div class="d-flex stores">
            <a href="https://apps.apple.com/us/app/redmed/id1483844703" target="_blank">
              <img loading="lazy" alt="" class="mr-3" src="/assets/photos/appstore.png">
            </a>
            <a href="https://play.google.com/store/apps/details?id=ge.redmed.patient" target="_blank">
              <img loading="lazy" alt="" src="/assets/photos/playstore.png">
            </a>
          </div>
          <div>
            <span class="d-flex text font-14 text-white">{{'getTheDownloadLink' | translate}}</span>
            <div class="form-row">
              <div class="col-lg-8 position-relative mb-lg-0 mb-3">
                <label class="phone-index font-14" for="phone">+995 |</label>
                <input [formControl]="phoneControl" class="standard-input" id="phone" [placeholder]="'telephoneNumber' | translate"
                       type="tel">
              </div>
              <div class="col-lg-4">
                <button (click)="sendDownloadLink()" class="bg-white text-dark" matRipple>{{'send' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
