import {Injectable} from '@angular/core';
import {UserModel} from '../models/user.model';

declare var HelpCrunch: any;
@Injectable({
  providedIn: 'root'
})
export class HelpCrunchService {

  public unread: any;

  constructor(

  ) {
  }

  // initIntercom() {
  //   this.intercom.boot({
  //     appId: 'y7fltka2'
  //   })
  // }

  /* init intercom module */
  public initHelpCrunchUser(data: UserModel): void {
    var user = {
      name: data.firstname,
      user_id: data.userId.toString(),
      "REDMED User Type": data.isDoctor ? "Doctor" : "Patient"
    };
    HelpCrunch('updateUser', user);
  }

  public showHelpCrunch(): void {
    HelpCrunch('openChat');
  }

  public openChatWithDoctor(): void {
    var user = {
      company: 'yes'
    };
    HelpCrunch('updateUser', user);
    HelpCrunch('sendUserMessage', 'test');
    HelpCrunch('openChat', {'status': 'open'});
  }

  // public notification(): void {
  //   this.intercom.onUnreadCountChange(
  //     result => {
  //       this.unread = result;
  //     });
  // }

  /* logout from intercom */
  public logout(): void {
    HelpCrunch('logout');
  }

  public hide(): void {
    HelpCrunch('closeChat');
  }

  public setHelpCrunchLanguage(): void {
    HelpCrunch('setPhraseList', localStorage.getItem('CurLang') || 'ka');
  }

  shutDownHelpCrunch() {
    HelpCrunch('closeChat');
  }
}
