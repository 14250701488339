<div class="in-progress-call__container"
     [ngClass]="{video: isVideo, 'full-screen': isFullScreen}"
     (mouseenter)="isMouseOver = !isMouseOver"
     (mouseleave)="isMouseOver = !isMouseOver">
  <div class="caller-image__wrapper" *ngIf="!isVideo">
    <img loading="lazy" (error)="updateUrl($event)" [src]="'/web/api/files/profile_pic/' + callerId + '?size=s'" alt="">
  </div>
  <span *ngIf="isVideo" class="video-timer">{{timePassed | time}}</span>
  <div *ngIf="isVideo" #localVideo class="local-video-container" [ngClass]="{'full-screen': isFullScreen}"></div>
  <div *ngIf="isVideo" #remoteVideo class="remote-video-container" [ngClass]="{'full-screen': isFullScreen}"></div>
  <div *ngIf="!isVideo" class="call-details">
    <div class="caller-info">
      <span class="caller-name">{{caller}}</span>
      <span class="caller-profession">{{timePassed | time}}</span>
    </div>
    <div class="device-controls">
      <!--      <button matRipple class="device-actions">-->
      <!--        <mat-icon style="color: white">volume_up</mat-icon>-->
      <!--      </button>-->
      <button (click)="muteMicrophone()" matRipple class="device-actions">
        <mat-icon style="color: white">{{!isMicrophoneMuted ? 'mic' : 'mic_off'}}</mat-icon>
      </button>
      <button (click)="startChat()" matRipple class="device-actions">
        <mat-icon style="color: white">message</mat-icon>
      </button>
      <button (click)="hangUp()" matRipple class="device-actions hang-up">
        <mat-icon style="color: white">call_end</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="isVideo" class="video-controls">
    <div class="device-controls screen-control" *ngIf="isMouseOver" [@fadeAnimation]="isMouseOver">
      <button matRipple class="device-actions video-button" (click)="isFullScreen = !isFullScreen">
        <mat-icon style="color: white">{{isFullScreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
      </button>
    </div>
    <div class="device-controls video-control" *ngIf="isMouseOver" [@fadeAnimation]="isMouseOver">
      <span style="display: flex">
<!--        <button matRipple class="device-actions video-button">-->
        <!--        <mat-icon style="color: white">volume_up</mat-icon>-->
        <!--      </button>-->
      <button (click)="muteMicrophone()" matRipple class="device-actions video-button">
        <mat-icon style="color: white">{{!isMicrophoneMuted ? 'mic' : 'mic_off'}}</mat-icon>
      </button>
      <button (click)="startChat()" matRipple class="device-actions video-button">
        <mat-icon style="color: white">message</mat-icon>
      </button>
        <!--      <button matRipple class="device-actions video-button">-->
        <!--        <mat-icon style="color: white">videocam</mat-icon>-->
        <!--      </button>-->
      </span>
      <button (click)="hangUp()" *ngIf="isVideo ? localVideoTrack : true" matRipple class="device-actions hang-up">
        <mat-icon style="color: white">call_end</mat-icon>
      </button>
    </div>
  </div>
</div>
