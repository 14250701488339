import {environment} from "../../../environments/environment";

export module CONFIG {
  export class COMMON {
    public static DS = '/';
    public static DEFAULT_USER_TYPE = '1';  // 1 - Patient User; 2 - Doctor
    public static PATIENT_USER_TYPE = '1';
    public static DOCTOR_USER_TYPE = '2';
    public static SUPPORT_FACEBOOK_URL = 'https://www.facebook.com/messages/t/Redmed.ge';
  }

  export module API {

    export class REST {
      // public static API_URLS: object = {'UserUrl': 'http://51.105.187.245', 'ProductUrl': 'http://52.137.56.253'};
      // public static API_URLS: object = {
      //   'UserUrl': 'https://redmed.ge/web/api/user_service',
      //   'ProductUrl': 'https://redmed.ge/web/api/product_service',
      //   'PaymentUrl': 'https://redmed.ge/werrb/api/payment_service',
      //   'ApiUrl': 'https://redmed.ge/web/api'
      // };
      // public static API_URLS: object = {
      //   'UserUrl': 'https://beta.redmed.ge/web/api/user_service',
      //   'ProductUrl': 'https://beta.redmed.ge/web/api/product_service',
      //   'PaymentUrl': 'https://beta.redmed.ge/web/api/payment_service',
      //   'ApiUrl': 'https://beta.redmed.ge/web/api'
      // };
      public static API_URLS: object = {
        'UserUrl': environment.apiUrl + '/users/v2',
        'ProductUrl': environment.apiUrl + '/products/v2',
        'PaymentUrl': environment.apiUrl + '/payment_service',
        'ApiUrl': environment.apiUrl
      };
      public static APP_DATA_FOLDER = 'rest';
      public static APP_DATA_FILE = 'AppData.js';
    }

    export class CONTROLLERS {
      public static APP_DATA = 'AppData';
      public static API = '';
      public static MAIN = 'Main';
      public static USER = 'User';
    }

    export class METHODS {
      public static GET_APP_DATA = 'GetData';
      public static GET_CITIES = 'GetCities';
      public static GET_SPECIALTIES = 'specialty/get_all_specialty';
      public static GET_AVAILABLE_SPECIALTIES = 'GetAvailableSpecialties';
      public static GET_GENDERS = 'GetGender';
      public static GET_LANGUAGE = 'GetLanguage';
      public static GET_USER_INFO = 'Me';
      public static CHECK_PHONE = 'CheckPhone';
      public static CHECK_EMAIL = 'CheckEmail';
      public static CHECK_DOCTOR = 'check_doctor_status';
      public static USERS = 'users';
      public static AUTH = 'login';
      public static REGISTER_USER = 'sign-up';
      public static UPDATE_USER = 'UpdateUserProfile';
      public static FORGOT_PASSWORD = 'ForgotPassword';
      public static CHANGE_PASSWORD = 'ChangePassword';
      public static SEND_SMS = 'SendSms';
      public static GET_DOCTOR_STATUS = 'GetDoctorStatus';
      public static CHECK_DOCTOR_STATUS = 'CheckDoctorSubmitStatus';
      public static SUBMIT_DOCTOR_PROFILE = 'SubmitDoctorProfile';
      public static GET_COUNTRY = 'GetCountry';
      public static GET_EDUCATION_LEVEL = 'GetEducationLevel';
      public static GET_DOCTOR_INFO = 'GetDoctorGeneralInfo';
      public static GET_DOCTOR_ABOUT_INFO = 'GetAboutDoctorInfo';
      public static GET_DOCTOR_EDUCATION = 'GetDoctorEducation';
      public static GET_DOCTOR_WORK = 'GetDoctorWorkExperience';
      public static GET_DOCTOR_SPECIALTIES = 'GetDoctorSpecialties';
      public static ADD_DOCTOR_INFO = 'AddGeneralInfo';
      public static ADD_DOCTOR_ABOUT = 'AddDoctorInfo';
      public static EDIT_DOCTOR_ABOUT = 'doctor-info';
      public static ADD_DOCTOR_WORK = 'AddWorkExperience';
      public static ADD_DOCTOR_EDUCATION = 'AddEducation';
      public static ADD_DOCTOR_SPECIALITY = 'AddUserSpeciality';
      public static EDIT_DOCTOR_WORK = 'EditDoctorWorkExperience';
      public static EDIT_DOCTOR_EDUCATION = 'EditDoctorEducation';
      public static DELETE_DOCTOR_WORK = 'DeleteDoctorWorkExperience';
      public static DELETE_DOCTOR_EDUCATION = 'DeleteDoctorEducation';
      public static DELETE_DOCTOR_SPECIALITY = 'DeleteDoctorSpeciality';

      public static GET_SERVICE_GROUP = 'GetServiceGroup';
      public static GET_SERVICE_CATEGORY = 'GetServiceCategory';
      public static GET_SERVICE_TYPE = 'GetServiceType';
      public static GET_SERVICE_SPECIALITY_CONFIG = 'GetSpecialityServiceConfig';
      public static GET_DOCTOR_SERVICES = 'GetDoctorServices';
      public static GET_ALL_SERVICES = 'GetAllServices';

      public static ADD_DOCTOR_SERVICE = 'AddDoctorService';
      public static EDIT_DOCTOR_SERVICE = 'EditDoctorService';
      public static PAUSE_DOCTOR_SERVICE = 'PauseDoctorService';
      public static STOP_DOCTOR_SERVICE = 'StopDoctorService';
      public static ACTIVE_DOCTOR_SERVICE = 'ActiveDoctorService';
      public static DELETE_DOCTOR_SERVICE = 'DeleteDoctorService';

      public static GET_CALENDAR_DATA = 'GetDoctorSchedule';
      public static ADD_CALENDAR = 'AddDoctorScheduleAsDraft';
      public static EDIT_CALENDAR = 'EditDoctorSchedule';
      public static PAUSE_CALENDAR_TODAY = 'PauseDoctorScheduleToday';
      public static PAUSE_CALENDAR_WEEK = 'PauseDoctorScheduleThisWeek';
      public static PAUSE_CALENDAR_MANUAL = 'schedule/pause'
      public static STOP_CALENDAR = 'StopDoctorSchedule';
      public static ACTIVE_CALENDAR = 'ActiveDoctorSchedule';
      public static DELETE_CALENDAR = 'DeleteDoctorSchedule';
      public static GET_USER_ADDRESS_DATA = 'GetUserAddres';
      public static ADD_USER_ADDRESS = 'AddUserAddress';
      public static EDIT_USER_ADDRESS = 'EditUserAddress';
      public static DELETE_USER_ADDRESS = 'DeleteUserAddress';
      public static SET_MAIN_USER_ADDRESS = 'SetMainUserAddress';
      public static GET_DOCTOR_PUBLIC_PROFILE = 'GetDoctorPublicProfile';
      public static GET_DOCTOR_RATING_CRITERION = 'GetRaitingPerCriterion';
      public static GET_DOCTOR_RATING_AND_COMMENTS = 'GetRaitinAndComment';
      public static GET_DOCTOR_SUM_RATING = 'GetSumRaiting';
      // public static CREATE_ORDER = 'CreateOrder';
      public static CREATE_ORDER = 'orders/create_order_V2';
      public static CREATE_DOCTRA_ORDER = 'orders/create_order_doctra';
      public static ORDER_DATES = 'orders/order_dates';
      public static GET_ORDERS_BY_DATE = 'orders/orders_by_date';
      public static GET_ORDER_REASONS = 'GetOrderReason';
      public static ADD_ORDER_REASON = 'AddOrderReason';
      public static ADD_ORDER_QUESTION = 'AddOrderQuestions';
      public static GET_DRAFT_ORDER = 'GetDraftOrder';
      public static ADD_PATIENT = 'AddPatient';
      public static GET_INSURANCE = 'GetInsurance';
      public static ADD_USER_ORDER_PHONE_NUMBER = 'AddUserOrderPhoneNumber';
      public static ADD_ORDER_DESCRIPTION = 'AddOrderDescription';
      public static ADD_ORDER_ADDRESS = 'AddOrderAddress';
      public static ADD_INSURANCE = 'AddInsurane';
      public static ADD_ORDER_PRICE = 'addOrderPrice';
      public static GET_DOCTOR_IBAN = 'GetDoctorIban';
      public static ADD_DOCTOR_IBAN = 'AddDoctorIban';
      public static GET_USER_ALL_ORDER = 'GetUserAllOrder';
      public static MAKE_PAYMENT_TEST = 'MakePayment';
      public static GET_ORDER_STATUS_LIST = 'GetOrderStatusList';
      public static GET_USER_ORDER_DETAIL = 'orders/user_order_detail';
      public static GET_DOCTOR_ALL_ORDER_RESP = 'GetDoctorAllOrderResp';
      public static GET_DOCTOR_ORDER_DETAIL = 'orders/doctor_order_detail';
      public static GET_DOCTOR_SERVICE_BY_DOCTOR_ID = 'GetDoctorServiceByDoctorId';
      public static GET_PATIENT_CHAT_CALL = "GetPatientChatCall";
      // public static CANCEL_ORDER_BY_DOCTOR = 'CancelOrderByDoctor';
      // public static CANCEL_ORDER_BY_PATIENT = 'CancelOrderByPatient';
      public static CANCEL_ORDER = 'orders/cancel';
      public static APPROVE_ORDER_BY_DOCTOR = 'orders/approve_order_by_doctor';
      public static REJECT_ORDER_BY_DOCTOR = 'orders/cancel';
      public static CHANGE_ORDER_ACTION_DOCTOR_ON_MY_WAY = 'orders/change_order_action_doctor_on_my_way';
      public static CHANGE_ORDER_ACTION_SERVICE_IN_PROGRESS = 'orders/change_order_action_service_in_progress';
      // public static COMPLETE_ORDER_BY_DOCTOR = 'CompleteOrderByDoctor';
      public static COMPLETE_ORDER_BY_DOCTOR = 'orders/finish_by_doctor';
      public static COMPLETE_ORDER_BY_PATIENT = 'orders/complete_order_by_patient';
      public static CALL_TOKEN = 'orders/call_token';
      public static ADD_RATING_BY_PATIENT = 'orders/add_rating_by_patient';
      public static GET_USER_REVIEW_BY_ORDER_ID = 'GetUserReviewByOrderId';
      public static REVIEW_NEEDED = 'orders/review_needed';
      public static CARDS = 'cards';
      public static TRANSACTIONS = 'cards/transactions';
      public static CHECK_PROMO = 'promo/check';
      public static FINISH_ORDER = 'orders/finish_order_V2';
      public static IM_CALLING = 'orders/im_calling';
      public static GET_USER_FILE = 'GetFile';
      public static ADD_BANK_CARD = 'AddCard';
      public static GET_POPULAR_SPECIALITIES = 'GetPopularSpeciality';
      public static GET_POPULAR_DOCTORS = 'GetPopularDoctor';

      public static GET_DOCTOR_ACTION = 'GetDoctorAction';
      public static GET_PATIENT_ACTION = 'GetPatientAction';
      public static SEARCH = 'search?';
      public static SEARCH_SPEC_SLOTS = 'search/getDocSlotsForSpec?';
      public static SEARCH_SLOTS_BY_DATE = 'search/getDocSlotsByDateNew?';
      public static SEARCH_SUGGEST = 'search/suggest?';
      public static SEND_DOWNLOAD_URL = 'orders/download_link';
      public static SAVE_TOKEN = 'push/saveToken';
      public static BANNERS = 'banners'
      public static SERVICE_TYPE_BY_USER = 'specialty/service_type_by_user'
      //
      public static GET_USER_AUTH_INFO = 'auth-info';
    }

    export class TAGS {
      public static USER_ID: string = 'userID';
      public static USER_TOKEN: string = 'UserAccessToken';
      public static USER_EMAIL: string = 'UserEmail';
      public static USER_PHONE: string = 'UserPhone';
      public static USER_NAME: string = 'UserName';
      public static USER_REMEMBER: string = 'RememberMe';
      public static USER_TYPE: string = 'UserType';
      public static USER_DATA: string = 'UserData';
    }
  }

  export module CACHE {
    export class COMMON {

    }

    export class DURATIONS {

    }
  }

  export class PAYMENT_DATA {

  }

  export module LANGS {
    export enum LANG_DEFINITIONS { ka = 1, en = 2}

    export class LANG_DATA {

      public static DEFAULT_LANG = 'ka';
      public static DEFAULT_LANG_ID = 1;
      public static LANGS_TITLES = {'ka': 'ქარ', 'en': 'eng'};
      public static LANGS = ['ka', 'en'];
    }
  }


  export class ROUTES {
  }

}
