import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  steps = new BehaviorSubject([]);
  getSteps = this.steps.asObservable();

  constructor() {
  }

  setSteps(stepsCount: number): void {
    this.steps.next(Array.from({length: stepsCount}).map((res, index) => ({index})));
  }

}

interface Stepper {
  index: number;
}
