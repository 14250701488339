import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DocumentationService} from "../../services/documentation.service";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-info-dialog',
  templateUrl: './send-document-via-email-dialog.component.html',
  styleUrls: ['./send-document-via-email-dialog.component.scss']
})
export class SendDocumentViaEmailDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SendDocumentViaEmailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private documentationService: DocumentationService,
              private loadingService: LoadingService) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  sendToEmail() {
    this.loadingService.setApplicationLoading(true);
    this.documentationService.sendDocumentViaEmail(this.data.documentType, this.data.orderId).subscribe(
      (res) => {
        if (res) {
          this.loadingService.setApplicationLoading(false);
          this.closeDialog();
        }
      }, error => {
        this.loadingService.setApplicationLoading(true);
      }
    );
  }
}
