import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {map, publishReplay, refCount} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiUrl = environment.apiUrl;
  private services: Observable<any> = this.http.get(`${this.apiUrl}/products/v2/GetServiceType`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());

  constructor(private http: HttpClient) {
  }

  getServices(): Observable<any> {
    return this.services;
  }
}
