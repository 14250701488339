import { trigger, transition, style, animate } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InformationModalComponent } from 'src/app/components/information-modal/information-modal.component';
import { OtpSmsDialogComponent } from 'src/app/dialogs/otp-sms-dialog/otp-sms-dialog.component';
import { UserService } from 'src/app/services';
import { InfoDialogComponent } from 'src/app/shared/dialogs/info-dialog/info-dialog.component';
import { InsuranceOtpVerifyComponent } from 'src/app/shared/dialogs/insurance-otp-verify/insurance-otp-verify.component';
import { InsuranceProviderDialogComponent } from 'src/app/shared/dialogs/insurance-provider-dialog/insurance-provider-dialog.component';
import { InsuranceService } from 'src/app/shared/services/insurance.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { SuccessService } from 'src/app/shared/services/success.service';

@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style(
          {
            position: 'absolute',
            top: '72px',
            transform: 'translateX(100%)'
          }
        ),
        animate('200ms ease-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class AddInsuranceComponent implements OnInit {
  @ViewChild('inputField') inputField: ElementRef;
  formGroup: FormGroup
  clicked = false
  user = []
  insuredUsers = []
  gotInsuredUsers = false
  insuredUsers$
  isWriting = false;

  constructor(private loadingService: LoadingService,
              private insuranceService: InsuranceService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private userService: UserService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private successService: SuccessService
    ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      personalNumber: new FormControl(null, [Validators.required, ])
    })
    this.userService.getCurrentUser()
    .subscribe(
        (user) => {
          this.user = user;
          this.loadingService.setApplicationLoading(false);
        }, error => {
          this.loadingService.setApplicationLoading(false);
        }
    );
    this.formGroup.get('personalNumber').valueChanges.subscribe(res => {
      this.isWriting = true
    })

  }

  ngAfterViewInit() {
    const inputElement = this.inputField.nativeElement;
    inputElement.style.caretColor = '#da1f3d';
  }
   
  back(fromInsured?: boolean){
    if(fromInsured){
      this.gotInsuredUsers = false
    }else{
      window.history.back()
    }
  }

  getUserPolices() {
    this.loadingService.setApplicationLoading(true);
    this.insuranceService.getUserPolices()
      .subscribe(
        (res) => {
          if (res) {
            this.loadingService.setApplicationLoading(false);
          }
        }, error => {
          this.loadingService.setApplicationLoading(false);
        }
      );
  }

  openDialog() {
    const dialog = this.dialog.open(InsuranceOtpVerifyComponent, {
      width: '430px',
      data: {
        title: 'insurance',
        userData: this.formGroup.value
      },
      disableClose: true
    });

    dialog.afterClosed()
      .subscribe(
        () => {
          this.getUserPolices();
          this.formGroup?.get('personalNumber').setValue(null)
        }
      ); 
  }

  openInsuranceProviders() {
    this.loadingService.setApplicationLoading(true);
    // if (this.formGroup.invalid) {
    //   this.formGroup.markAllAsTouched();
    //   this.loadingService.setApplicationLoading(false);
    //   return;
    // }
    this.insuranceService.isNewInsuranceUser(this.formGroup.get('personalNumber').value)
      .subscribe(
        (res) => {
          if (!res.data.isNewUser) {
            this.registerInsurance()
          } else {
            this.loadingService.setApplicationLoading(false)
            const infoDialog = this.dialog.open(InfoDialogComponent, {
              width: '420px',
              data: {
                message: 'if-you-continue,-the-main-insured\'s-information-will-be-replaced-with-new-data',
                confirm: this.translateService.instant('continue'),
                title: 'failure',
                decline: this.translateService.instant('cancellation'),
                needsAction: true
              }
            });
            infoDialog.afterClosed().subscribe(
              (result) => {
                if (result) {
                  this.registerInsurance()

                }
              }
            );
          }
        }, error => {
          this.loadingService.setApplicationLoading(false);
        }
      );
  }

  registerInsurance() {
    this.loadingService.setApplicationLoading(true);
    const data = {
      insuranceId: 8,
      personalNumber: this.formGroup.get('personalNumber')?.value
    }
    this.insuranceService.insuranceLogin(data)
        .subscribe((response) => {
          this.loadingService.setApplicationLoading(false);
          if (response.data.uid) {
            this.openModal(response);
          } else {
            this.dialog.open(InfoDialogComponent, {
              width: '420px',
              data: {
                needsAction: false,
                message: 'no-insurance-policy-was-found-with-the-entered-number,-please-enter-the-correct-data',
                title: 'failure',
                buttonText: 'ok'
              }
            })
          }

          // this.dialog.open();
        }, error => {
          this.loadingService.setApplicationLoading(false);
          if (error.status === 404) {
            console.log(error)
            this.dialog.open(InfoDialogComponent, {
              width: '420px',
              data: {
                needsAction: false,
                message: error.error.message,
                title: 'failure',
                buttonText: 'ok'
              }
            });
          }
        });
  }

  private openModal(data: any) {
    const dialog = this.dialog.open(InsuranceOtpVerifyComponent, {
      width: '430px',
      data: {
        title: 'sms-verification',
        type: 1,
        openType: null,
        from: 'insuranceLogin',
        userData: this.user,
        uid: data.data.uid,
        personalNumber: this.formGroup.get('personalNumber').value,
        insuranceId: 8
      },
      disableClose: true
    });
    dialog.afterClosed().subscribe((data) => {
      if(data){
        this.getInsuredUsersNew()
      }
    });
  }

  getInsuredUsersNew(){
    this.loadingService.setApplicationLoading(true)
    this.gotInsuredUsers = true
    this.insuranceService.getInsuredUsersNew(8).pipe(
      tap(res => {
        this.insuredUsers$ = of(res.data.insureds)
      })
    )
    .subscribe(
      (res) => {
          if (res.code === 4) {
            this.dialog.open(InformationModalComponent, <any>{
              width: '430px',
              data: {
                title: 'information',
                text: res.message,
                
              }
            });
            this.gotInsuredUsers = false
        this.loadingService.setApplicationLoading(false);

          } else {
            this.gotInsuredUsers = true
        this.loadingService.setApplicationLoading(false);

          }
      }, error => {
        this.dialog.open(InfoDialogComponent, {
          width: '420px',
          data: {
            needsAction: false,
            message: error.error.message,
            title: 'failure',
            buttonText: 'ok'
          }
        })
        this.gotInsuredUsers = false
        this.loadingService.setApplicationLoading(false);
      }
    );
  }

  redirect(){
    if(this.activeRoute.snapshot.queryParamMap.has("fromProfile")){
      this.insuranceService.popupAfterRedirectInfo = {
        needsAction: false,
        message: 'insurance-info-added-successfully',
        title: 'success',
        buttonText: 'insurance-policies',
        singleButtonAction: 'add-insurance-success'
      }
      this.router.navigate(['/main'])
    }else{
      this.router.navigate([`/search-step`], {
        queryParams: { online: true, insurance: true },
      });
      this.successService.openSuccessPopup('insurance-info-added-successfully', 3000)
    }
  }

}
