import {isPlatformBrowser} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {Inject, NgModule, Optional, PLATFORM_ID} from '@angular/core';
import {BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {Request} from 'express';
import {TranslateCacheModule, TranslateCacheService, TranslateCacheSettings} from 'ngx-translate-cache';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {LoadingService} from "../shared/services/loading.service";

// import {translateLoaderFactory} from './translate-loaders';

@NgModule({
  imports: [
    BrowserTransferStateModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
        deps: [HttpClient, TransferState, PLATFORM_ID]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings, LoadingService]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(
    translate: TranslateService,
    translateCacheService: TranslateCacheService,
    @Optional() @Inject(REQUEST) private req: Request,
    @Inject(PLATFORM_ID) private platform: any,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _adapter: DateAdapter<any>,
    private loadingService: LoadingService
  ) {
    if (isPlatformBrowser(this.platform)) {
      translateCacheService.init();
    }

    if (!localStorage.getItem('CurLang')) {
      localStorage.setItem('CurLang', 'ka');
    }

    translate.addLangs(['ka', 'en']);
    translate.setDefaultLang('ka');

    // const browserLang = isPlatformBrowser(this.platform)
    //   ? translateCacheService.getCachedLanguage() || translate.getBrowserLang() || 'ka'
    //   : this.getLangFromServerSideCookie() || 'ka';

    // translate.use(browserLang.match(/en|ru/) ? browserLang : 'ka');
    const urlParams = new URLSearchParams(window.location.search);
    const selectedLanguage = urlParams.get('selectedLanguage') || localStorage.getItem('CurLang') || 'ka';
    translate.use(selectedLanguage);

    // for date picker
    _locale = localStorage.getItem('CurLang');
    _adapter.setLocale(_locale);
  }

  getLangFromServerSideCookie() {
    if (this.req) {
      return this.req.cookies.lang;
    }
  }
}

export function translateCacheFactory(translateService: TranslateService, translateCacheSettings: TranslateCacheSettings) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
