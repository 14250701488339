import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isFirstLoad = true;
  private loading = new BehaviorSubject<boolean>(false);
  isLoading = this.loading.asObservable();

  constructor() {
  }

  setApplicationLoading(isLoading: boolean, clearFirstLoadField: boolean = false) {
    if (clearFirstLoadField) {
      this.isFirstLoad = false;
    }
    if (this.isFirstLoad && !isLoading) {
      return
    }
    this.loading.next(isLoading);
  }
}
