import {Injectable} from '@angular/core';
import {CONFIG} from '../core/config/config';

@Injectable()
export class RouterHelper {

  constructor() {

  }

  public getURLlang() {
    const cUrlPath = window.location.pathname;
    return (cUrlPath.length <= 2 || CONFIG.LANGS.LANG_DATA.LANGS.indexOf(cUrlPath.substring(1, 3)) == -1) ? '' : cUrlPath.substring(1, 3);
  }

}
