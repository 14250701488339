import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from "../../services/user.service";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})

export class InformationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InformationModalComponent>,
              public User: UserService,
              public API: ApiService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  closeDialog(success: boolean = false): void {
    this.dialogRef.close(success);
  }


}
