import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ApiService, UserService} from "../../../services";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DoctorService} from "../../../services/doctor.service";
import {PageManipulationUtilsService} from "../../../services/page-manipulation-utils.service";

@Component({
  selector: 'app-request-doctor-dialog',
  templateUrl: './request-doctor-dialog.component.html',
  styleUrls: ['./request-doctor-dialog.component.scss']
})
export class RequestDoctorDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RequestDoctorDialogComponent>,
              private doctorService: DoctorService,
              @Inject(MAT_DIALOG_DATA) public data: {
                DoctorId: number,
                SpecialtyId: number,
                ServiceTypeId: number,
                SearchDate: number
              }) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getOtherAvailableDoctors() {
    this.doctorService.getRequestOtherSearchResult(this.data)
      .subscribe(
        (response: any) => {
          this.dialogRef.close({getData: true});
        });
  }

  waitForDoctor() {
    this.doctorService.waitForRequestedDoctor(this.data)
      .subscribe(
        (response: any) => {
          this.dialogRef.close({wait: true});
        });
  }
}
