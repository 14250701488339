import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SmsModalData} from "../../../models";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InsuranceService} from "../../services/insurance.service";
import {InsuranceUserPolicyDialogComponent} from "../insurance-user-policy-dialog/insurance-user-policy-dialog.component";
import {LoadingService} from "../../services/loading.service";
import { InsuranceUserTokenVerifyDialogComponent } from '../insurance-user-token-verify-dialog/insurance-user-token-verify-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';

@Component({
  selector: 'app-insurance-otp-verify',
  templateUrl: './insurance-otp-verify.component.html',
  styleUrls: ['./insurance-otp-verify.component.scss']
})
export class InsuranceOtpVerifyComponent implements OnInit {
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any; 
  config = { 
    allowNumbersOnly: true, length: 4, 
    isPasswordInput: false, disableAutoFocus: false, 
    inputClass: 'otp-input-custom-class' 
  };
  public formGroup: FormGroup;

  public code: string;
  public phone: string;

  public isLoading = false;
  public result = { statusCode: 0, statusMessage: ''};
  public langs = [];

  public isRegistered = true;
  timeLeft = 180;
  interval;
  resendAvailable: boolean;
  registerInProgress: boolean;
  wrongCode = false;
  timerValue = {
    minutes: 3,
    seconds: 0,
  }
  timeIsUp = false
  isRotating = false

  constructor(
    public dialogRef: MatDialogRef<InsuranceUserTokenVerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private insuranceService: InsuranceService,
    private loadingService: LoadingService,
    private dialog: MatDialog
    ) { }


  ngOnInit() {
    this.createForm();
    this.startCountdown();
    this.timer();
  }

  timer() {
    const timer = setInterval(() => {
      if (this.timerValue.seconds === 0 && this.timerValue.minutes === 0) {
        clearInterval(timer);
        this.timeIsUp = true
        // this.orderTimeoutModal();
      } else {
        if (this.timerValue.seconds === 0 && this.timerValue.minutes > 0) {
          this.timerValue.seconds = 59;
          this.timerValue.minutes--;
        } else {
            this.timerValue.seconds--;
          }
        }
    }, 1000);
  }

  createForm() {
    this.formGroup = new FormGroup({
      phoneNumber: new FormControl(
        '', [Validators.required]
      ),
      code: new FormControl(
        '', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      ),
      uid: new FormControl( '', [Validators.required]),
      personalNumber: new FormControl('', [Validators.required])
    });
    this.formGroup.patchValue(this.data);
    this.formGroup.get('phoneNumber').setValue(this.data.userData.phoneNumber);
    // this.formGroup.get('uid').setValue(this.data.uid);
    // this.formGroup.get('personalNumber').setValue(this.data.personalNumber);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  
  onOtpChange(event){
    this.wrongCode = false
    if(event.length === 4){
      this.formGroup?.get('code').setValue(event)
      this.verifyUser()
    }
  }

  resendCode() {
    if(this.timerValue.seconds === 0 && this.timerValue.minutes === 0){
      this.isRotating = true;
      this.ngOtpInput.setValue(null);
      setTimeout(() => {
        this.isRotating = false;
      }, 800);
      this.insuranceService.resendCode(this.formGroup.value)
        .subscribe((res) => {
          this.wrongCode = false
          this.timerValue = {
            minutes: 3,
            seconds: 0,
          }
          this.timeIsUp = false
          this.timer();
        });
    }

  }

  startCountdown() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.resendAvailable = true;
        this.timeLeft = 180;
        clearInterval(this.interval);
      }
    }, 1000)
  }

  verifyUser() {
    this.loadingService.setApplicationLoading(true);
    this.insuranceService.insuredUserToken(this.formGroup.value)
      .subscribe((res) => {
        this.wrongCode = false;
        if (res) {
          this.dialogRef.close(res)
        }
      }, error => {
        this.wrongCode = true;
        this.loadingService.setApplicationLoading(false);
        this.dialog.open(InfoDialogComponent, {
          width: '420px',
          data: {
            needsAction: false,
            message: error.error.message,
            title: 'failure',
            buttonText: 'ok'
          }
        })
        this.dialogRef.close();
      })
  }
}



