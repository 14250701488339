import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getOrderById(orderId, user): Observable<any> {
    const direction = user.isDoctor ? 'doctor_order_detail' : 'user_order_detail';
    return this.http.get(`${this.apiUrl}/orders/${direction}/${orderId}`)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getAllOrders(params: HttpParams): Observable<any> {
    return this.http.get(`${this.apiUrl}/orders/all`, {params})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  getUserActiveOrders(params: HttpParams, isDoctor: boolean = false): Observable<any> {
    return this.http.get(`${this.apiUrl}/orders/active_orders_for_${isDoctor ? 'doctor' : 'patient'}`, {params})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  getUerArchiveOrders(params: HttpParams, isDoctor: boolean = false): Observable<any> {
    return this.http.get(`${this.apiUrl}/orders/archive_orders_for_${isDoctor ? 'doctor' : 'patient'}`, {params})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  updateOrderStatuses(notYetSeenOrders: any[], params: HttpParams): Observable<any> {
    return this.http.post(`${this.apiUrl}/notification/change_notification_seen_status`, {orderIds: notYetSeenOrders}, {params})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  updateOrderChatStatuses(notYetSeenOrders: any[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/notification/change_chat_notification_seen_status`, {orderIds: notYetSeenOrders})
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }


  cancelDraftOrder(orderId): Observable<any> {
    return this.http.delete(`${this.apiUrl}/orders/${orderId}/cancel-draft`)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }
}
