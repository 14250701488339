import {animate, state, style, transition, trigger} from '@angular/animations';
import {isPlatformBrowser, Location} from '@angular/common';
import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, UserService} from '../../../../services';
import {MessagingService} from '../../../messaging.service';
import {UserModel} from '../../../models/user.model';
import {HelpCrunchService} from '../../../services/help-crunch.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style(
          {
            // position: 'absolute',
            width: 0,
            opacity: 0
          }
        ),
        animate('700ms ease-out', style({width: '100%', opacity: 1}))
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({width: 0, opacity: 0}))
      ])
    ]),
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({opacity: 0})))
    ])
  ]
})
export class HeaderNavigationComponent implements OnInit {
  @Input() user: UserModel;
  @Input() mobileSizeScreen: boolean;
  hideSearch = false;
  selectedLanguage = localStorage.getItem('CurLang');
  isDoctor = false

  constructor(private authService: AuthService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: any,
              private userService: UserService,
              private messagingService: MessagingService,
              private helpCrunchService: HelpCrunchService,
              public translate: TranslateService,
              private ART: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit(): void {
    this.checkRoute();
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    localStorage.setItem('CurLang', lang);
    // this.translate.use(lang);
    const url = this.router.createUrlTree([], {relativeTo: this.ART, queryParams: {...this.ART.queryParams['value'], selectedLanguage: this.selectedLanguage}}).toString();
    this.location.replaceState(url);
    window.location.reload();
  }

  checkRoute() {
    this.router.events
      .subscribe(() => {
        this.hideSearch = this.router.url.includes('/search');
      });
  }


  showIntercom() {
    this.helpCrunchService.showHelpCrunch();
  }

  logout() {
    this.helpCrunchService.logout();
    this.authService.logout();
    this.router.navigate(['/'])
      .then(() => {
        this.messagingService.logoutFirebase();
      });
  }

  switchDoctorInstantCallStatus() {
    this.userService.setDoctorInstantCallStatus(this.user.isInstantCallEnabled)
      .subscribe(() => {
      });
  }

  goToClinicPage() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('UserAccessToken')) {
        window.open(`https://clinic.redmed.ge/auth?token=${this.fixedEncodeURIComponent(localStorage.getItem('UserAccessToken'))}&page=order-history&selectedLanguage=${this.selectedLanguage}`);
      }
    }
    window.open(`https://clinic.redmed.ge?selectedLanguage=${this.selectedLanguage}`);
  }

  goToPharmaPage() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('UserAccessToken')) {
        window.open(`https://pharma.redmed.ge/cabinet?token=${this.fixedEncodeURIComponent(localStorage.getItem('UserAccessToken'))}&selectedLanguage=${this.selectedLanguage}`);
      }
    }
    window.open('https://pharma.redmed.ge?selectedLanguage=${this.selectedLanguage}');
  }

  fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  getLanguageImg(): string {
    return this.selectedLanguage === 'ka' ? 'en' : 'ka';
  }
}
