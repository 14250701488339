<div [formGroup]="consultationCardForm" class="consultation-card__container">
  <button (click)="closeConsultationCard()" class="close-button" mat-icon-button>
    <mat-icon>clear</mat-icon>
  </button>
  <div class="consultation-card__heading">
    <img loading="lazy" [src]="'assets/img/logo.svg'" alt="logo" class="logo cursor-pointer mr-3">

  </div>
  <div class="consultation-card__redmed-titles"></div>
  <div class="consultation-card__document-title">
    {{'patient-examination-sheet' | translate}}
  </div>
  <div class="consultation-card__patient-details__container">
    <div class="consultation-card__patient-details">
      <span>{{'name-and-surname-of-the-patient' | translate}}</span>
      <span>{{data.consultationCard.fullName}}</span>
    </div>
    <div class="consultation-card__patient-details">
      <span>{{'birthDate' | translate}}</span>
      <span>{{data.consultationCard.birthDate}}</span>
    </div>
    <div class="consultation-card__patient-details">
      <span>{{'personalNumber' | translate}}</span>
      <span>{{data.consultationCard.personalNumber}}</span>
    </div>
    <div class="consultation-card__patient-details">
      <span>{{'medical-card-№' | translate}}</span>
      <span>{{data.consultationCard.medicalCardNumber}}</span>
    </div>
    <div class="consultation-card__patient-details">
      <span>{{'the-type-of-consultation' | translate}}</span>
      <span>{{data.consultationCard.consultationInfo}}</span>
    </div>
    <div class="consultation-card__patient-details">
      <span>{{'date-of-consultation' | translate}}</span>
      <span>{{data.consultationCard.createdAt | date: 'dd/MM/yyyy' : '' : selectedLanguage}}</span>
    </div>
  </div>
  <div class="consultation-card__diagnosis-details">
    <label for="subjectiveData">{{'subjective-data' | translate}}</label>
    <textarea cols="30" formControlName="subjectiveBriefAnamnesis" id="subjectiveData" name="subjectiveData"
              rows="10"></textarea>
  </div>
  <div class="consultation-card__diagnosis-details">
    <label for="objectiveData">{{'objective-data' | translate}}</label>
    <textarea cols="30" formControlName="objectiveDiagnosticExaminationsConsultations" id="objectiveData"
              name="objectiveData" rows="10"></textarea>
  </div>
  <div class="consultation-card__diagnosis-details">
    <label class="required">{{'preliminary-diagnosis' | translate}}</label>
    <mat-form-field appearance="outline" class="example-full-width">
      <input [matAutocomplete]="auto"
             aria-label="Assessment Diagnosis"
             formControlName="assessmentPreliminaryDiagnosisControl"
             matInput
             [placeholder]="'diagnosis' | translate"
             type="text">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addDiagnosisToData($event)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option.id">
          {{option.code}} - {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span (click)="clearDiagnosis(true)" class="clear-diagnosis">{{'clear' | translate}}</span>
    <div class="assessment-diagnosis-area">
      <span *ngFor="let diagnosis of data.consultationCard.assessmentPreliminaryDiagnosisFullString.split('╟')">
        {{diagnosis}}
      </span>
    </div>
  </div>
  <div class="consultation-card__diagnosis-details">
    <label class="required">{{'final-diagnosis' | translate}}</label>
    <mat-form-field appearance="outline" class="example-full-width">
      <input [matAutocomplete]="autoFinal"
             aria-label="Final Diagnosis"
             formControlName="assessmentFinalDiagnosisControl"
             matInput
             [placeholder]="'diagnosis' | translate"
             type="text">
      <mat-autocomplete #autoFinal="matAutocomplete" (optionSelected)="addFinalDiagnosisToData($event)">
        <mat-option *ngFor="let option of filteredFinalOptions" [value]="option.id">
          {{option.code}} - {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span (click)="clearDiagnosis()" class="clear-diagnosis">{{'clear' | translate}}</span>
    <div class="assessment-diagnosis-area">
      <span *ngFor="let diagnosis of data.consultationCard.assessmentFinalDiagnosisFullString.split('╟')">
        {{diagnosis}}
      </span>
    </div>
  </div>
  <div class="consultation-card__diagnosis-details">
    <label for="assignedExaminations">{{'prescribed-examinations' | translate}}</label>
    <quill-editor formControlName="planningAssignedExaminations" id="assignedExaminations" [modules]="editorModules" format="html" placeholder=""></quill-editor>

<!--    <textarea cols="30" -->
<!--              name="assignedExaminations" rows="10"></textarea>-->
  </div>
  <div class="consultation-card__diagnosis-details">
    <label for="prescribedTreatment">{{'prescribed-treatment' | translate}}</label>
    <quill-editor formControlName="planningAssignedTreatment" id="prescribedTreatment" [modules]="editorModules" format="html" placeholder=""></quill-editor>
  </div>
  <div class="consultation-card__trauma">
    <h5>{{'trauma' | translate}}</h5>
    <input formControlName="hasInjury" id="hasTrauma" type="checkbox">
    <label for="hasTrauma">{{'there-is-an-injury' | translate}}</label>
    <ng-container *ngIf="consultationCardForm.get('hasInjury').value">
      <div class="consultation-card__trauma-time">
        <span>{{'the-treatment-was-carried-out-from-the-injury' | translate}}</span>
        <input formControlName="minutesAfterInjury" type="text">
        <span>{{'in-minute' | translate}}.</span>
      </div>
      <div class="consultation-card__trauma-cause">
        <h6>{{'highway' | translate}}</h6>
        <div>
          <input formControlName="injuryRoadPedestrian" id="infantry" type="checkbox">
          <label for="infantry">{{'infantry' | translate}}</label>
          <input formControlName="injuryRoadBicycle" id="bicycle" type="checkbox">
          <label for="bicycle">{{'bicycle' | translate}}</label>
          <input formControlName="injuryRoadVehicle" id="autoTrauma" type="checkbox">
          <label for="hasTrauma">{{'car' | translate}}</label>
        </div>
      </div>
      <div class="consultation-card__trauma-cause">
        <h6>{{'burnt' | translate}}</h6>
        <div>
          <input formControlName="injuryBurnThermal" id="thermal" type="checkbox">
          <label for="thermal">{{'thermal' | translate}}</label>
          <input formControlName="injuryBurnChemical" id="chemical" type="checkbox">
          <label for="chemical">{{'chemical' | translate}}</label>
          <input formControlName="injuryBurnElectrical" id="electric" type="checkbox">
          <label for="electric">{{'electric' | translate}}</label>
          <input formControlName="injuryBurnFrostbite" id="freeze" type="checkbox">
          <label for="freeze">{{'frostbite' | translate}}</label>
        </div>
      </div>
      <div class="consultation-card__trauma-cause">
        <h6>{{'wound' | translate}}</h6>
        <div>
          <input formControlName="injuryWoundFirearm" id="firearm" type="checkbox">
          <label for="firearm">{{'incendiary' | translate}}</label>
          <input formControlName="injuryWoundColdWeapon" id="coldWeapon" type="checkbox">
          <label for="coldWeapon">{{'cold-weapon' | translate}}</label>
          <input formControlName="injuryWoundHousehold" id="houshold" type="checkbox">
          <label for="houshold">{{'domestic-trauma' | translate}}</label>
          <input formControlName="injuryWoundIndustrial" id="industrial" type="checkbox">
          <label for="industrial">{{'industrial-injury' | translate}}</label>
          <input formControlName="injuryWoundSelfHarm" id="selfHarm" type="checkbox">
          <label for="selfHarm">{{'self-harm' | translate}}</label>
          <input formControlName="injuryWoundIntentional" id="intentional" type="checkbox">
          <label for="intentional">{{'intentional-damage' | translate}}</label>
        </div>
      </div>
      <div class="consultation-card__trauma-cause">
        <h6>{{'by-another-mechanism' | translate}}</h6>
        <div>
          <input formControlName="injuryMechanismFall" id="dropFromHeight" type="checkbox">
          <label for="dropFromHeight">{{'falling-from-a-height' | translate}}</label>
          <input formControlName="injuryMechanismCrush" id="crushed" type="checkbox">
          <label for="crushed">{{'crush' | translate}}</label>
          <input [checked]="consultationCardForm.get('injuryMechanismOther').value" formControlName="otherChecked"
                 id="other" type="checkbox">
          <label for="other">{{'other' | translate}}</label>
        </div>
        <input *ngIf="consultationCardForm.get('otherChecked').value" class="other-trauma"
               formControlName="injuryMechanismOther" placeholder="{{'type-text' | translate}}" type="text">
      </div>
    </ng-container>
  </div>
  <div class="consultation-card__signature">
    <span>{{"doctor's-name-and-surname" | translate}}</span>
    <span>{{data.consultationCard.physicianSpecialist}}</span>
  </div>
  <div class="consultation-card__actions">
    <button (click)="downloadDocument()" [disabled]="isFormSaved" matRipple>
      <span [inlineSVG]="'assets/img/eye.svg'" class="svg-12 mr-2"></span> {{'see' | translate}}
    </button>
    <button (click)="editConsultationCard()" [disabled]="isFormSaved" matRipple>{{'save' | translate}}</button>
    <button (click)="closeDialog()" matRipple>{{'cancel' | translate}}</button>
    <button (click)="sendToPatient()" [disabled]="!data.send" matRipple><span
        [inlineSVG]="'assets/img/paper-plane.svg'"
        class="svg-12 mr-2"></span> {{'send-to-patient' | translate}}
    </button>
  </div>
</div>
