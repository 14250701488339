import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import * as Twilio from 'twilio-client';
import {environment} from '../../../environments/environment';
import {IncomingCallDialogComponent} from '../../components/incoming-call-dialog/incoming-call-dialog.component';
import {InformationModalComponent} from '../../components/information-modal/information-modal.component';
import {OrderModel} from '../../models/order.model';
import {UserService} from '../../services';
import {OrderService} from './order.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioService implements OnInit {
  isRinging = false;
  currentUser: any;
  private apiUrl = environment.apiUrl;
  private device: any;
  private activeConnection: any;
  private callInProgress = new BehaviorSubject<{
    inProgress: boolean,
    isAudio: boolean, order: OrderModel,
    caller?: string
  }>(null);
  checkCallProgress = this.callInProgress.asObservable();
  private callerId = new BehaviorSubject<number | string>(null);
  getCallerId = this.callerId.asObservable();
  private isAudioCall: boolean;
  private activeOrder: OrderModel;
  private callDialog: MatDialogRef<IncomingCallDialogComponent, any>;
  private chat = new BehaviorSubject<{ show: boolean, order: OrderModel, channelUrl?: string }>(null);
  checkForChat = this.chat.asObservable();
  private startCountdown = new BehaviorSubject<boolean>(false);
  checkCountdownStart = this.startCountdown.asObservable();
  private dialogOpened: boolean;
  private muteMicrophone: boolean = false;

  constructor(private http: HttpClient,
              private userService: UserService,
              private dialog: MatDialog,
              private orderService: OrderService) {
    this.userService.getUser
      .subscribe(
        (user => {
          if (user) {
            this.currentUser = user;
          }
        })
      );
  }

  ngOnInit(): void {

  }

  initTwilioDevice(user: any) {
    this.getTwilioToken(user.userId)
      .subscribe(
        (token: string) => {
          navigator.mediaDevices.enumerateDevices()
            .then(
              (devices: any[]) => {
                // if (!devices.find(device => device.kind === 'audioinput')) {
                //   this.messageInformationModal('დაფიქსირდა შეცდომა ზარის დროს, ' +
                //     'გთხოვთ შეამოწმოთ თქვენი მიკროფონის და ვიდეო კამერის გამართულად მუშაობა');
                //   return;
                // }
                this.device = new Twilio.Device();
                this.device.setup(token, {
                  codecPreferences: ['opus', 'pcmu'],
                  fakeLocalDTMF: true,
                  debug: true,
                  enableRingingState: true,
                  allowIncomingWhileBusy: false,
                  forceAggressiveIceNomination: true,
                  sounds: {
                    incoming: '../../../assets/audio/incoming.mp3'
                  }
                });
                this.device.on('ready', () => {

                });
                this.device.on('incoming', (connection) => {
                  if (!this.isRinging) {
                    this.isAudioCall = connection.customParameters.get('type') === 'audio';
                    this.showCallDialog(connection);
                    this.isRinging = true;
                  }
                }, () => {
                  this.messageInformationModal('დაფიქსირდა შეცდომა ზარის დროს, ' +
                    'გთხოვთ შეამოწმოთ თქვენი მიკროფონის და ვიდეო კამერის გამართულად მუშაობა');
                });
                this.device.on('connect', (connection) => {
                  const uuid = connection.customParameters.get('uuid');
                  if (uuid) {
                    this.updateCallLog(uuid)
                      .subscribe(
                        (response: any) => {

                        });
                  }
                  const caller = `${connection.customParameters.get('FirstName')} ${connection.customParameters.get('LastName')} `;
                  if (this.activeOrder) {
                    this.setCallProgress({inProgress: true, isAudio: this.isAudioCall, caller: caller});
                    this.startCountdown.next(true);
                    this.activeConnection = connection;
                    this.mute(this.muteMicrophone);
                  } else {
                    this.orderService.getOrderById(connection.customParameters.get('orderId'), user)
                      .subscribe(
                        (response: any) => {
                          if (response.hasOwnProperty('result')) {
                            this.activeOrder = response.result;
                            this.setCallProgress({inProgress: true, isAudio: this.isAudioCall, caller: caller});
                            this.activeConnection = connection;
                            this.mute(this.muteMicrophone);
                            this.startCountdown.next(true);
                          }
                        });
                  }
                }, () => {

                });
                this.device.on('disconnect', (connection) => {
                  this.setCallProgress({inProgress: false, isAudio: false});
                  this.setCallerId(null);
                  this.hangUp();
                  this.activeConnection = null;
                  this.startCountdown.next(false);

                });
                this.device.on('cancel', () => {
                  this.startCountdown.next(false);
                  this.setCallProgress({inProgress: false, isAudio: false});
                  this.callDialog.close();
                  this.hangUp();
                });
                this.device.on('error', (error) => {
                  this.setCallProgress({inProgress: false, isAudio: false});
                  this.hangUp();
                  this.startCountdown.next(false);
                  if (error.message === 'JWT Token Expired') {
                    if (this.currentUser) {
                      this.reinitializeDevice(this.currentUser)
                    } else {
                      this.destroyDevice();
                    }
                  }
                });
              });
        }
      );
  }

  makeCall(order: OrderModel, user: any, isAudio) {
    this.isAudioCall = isAudio;
    this.activeOrder = order;
    // TODO: აქ უნდა დავჩეკოთ მომხმარებლის ფერმიშენი მიკროფონზე და კამერაზე
    // navigator.permissions.query({ name: 'microphone' })
    //   .then((permission) => {
    //     if (permission.state !== 'granted') {
    //
    //     }
    //   })
    navigator.mediaDevices.enumerateDevices()
      .then(
        (devices: any[]) => {
          if (!devices.find(device => device.kind === 'audioinput')) {
            this.messageInformationModal('დაფიქსირდა შეცდომა ზარის დროს, ' +
              'გთხოვთ შეამოწმოთ თქვენი მიკროფონის და ვიდეო კამერის გამართულად მუშაობა');
            return;
          } else {
            this.device.connect({to: order.orderId, from: user.userId});
            this.setCallProgress({
              inProgress: true,
              isAudio: this.isAudioCall,
              caller: order.patientName || order.doctorName
            });
            this.playRingtone();
          }
        });
  }

  hangUp() {
    this.device.disconnectAll();
    this.activeOrder = null;
    this.activeConnection = null;
    this.isRinging = false;
    this.startCountdown.next(false);
  }

  mute(mute: boolean) {
    this.muteMicrophone = mute;
    this.activeConnection.mute(mute);
    // }
  }

  destroyDevice() {
    if (this.device) {
      this.device.destroy();
    }
  }

  messageInformationModal(message: string, isSuccess?: boolean): void {
    if (this.dialogOpened) {
      return;
    }
    this.dialogOpened = true;
    const dialog = this.dialog.open(InformationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'information',
        text: message,
        isSuccess: isSuccess
      }
    });
    dialog.afterClosed()
      .subscribe(
        () => {
          this.dialogOpened = false;
        }
      );
  }

  reinitializeDevice(user) {
    if (this.device) {
      this.destroyDevice();
    }
    this.initTwilioDevice(user)
  }

  playRingtone() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/outgoing.mp3";
    audio.loop = true;
    audio.load();
    audio.play()
      .then(() => {
        this.device.on('connect', () => {
          audio.pause();
        });
        this.device.on('disconnect', () => {
          audio.pause();
        });
        this.device.on('cancel', () => {
          audio.pause();
        });
        this.device.on('error', () => {
          audio.pause();
        })
      });
  }

  makeChatAction(show: boolean, order: OrderModel, channelUrl?: string) {
    this.chat.next({show, order, channelUrl});
  }

  private getTwilioToken(identity: string): Observable<any> {
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('identity', identity);
    queryParams = queryParams.append('platform', 'WEB');
    return this.http.get(`${this.apiUrl}/orders/accessToken`, {params: queryParams, responseType: "text"})
      .pipe(
        map((token: string) => {
          return token;
        }),
        catchError((error: Error) => {
          return throwError(error);
        })
      );
  }

  private setCallProgress(progress: { inProgress: boolean, isAudio: boolean, caller?: string }) {
    this.callInProgress.next({
      inProgress: progress.inProgress,
      isAudio: progress.isAudio,
      order: this.activeOrder,
      caller: progress.caller ? progress.caller : null
    });
  }

  private setCallerId(id: string | number) {
    this.callerId.next(id);
  }

  private showCallDialog(connection: any) {
    const userId = connection.parameters.From.split(":")[1];
    this.userService.getUserParamsById(userId)
      .subscribe(
        (userParams: any) => {
          this.callDialog = this.dialog.open(IncomingCallDialogComponent, {
            data: {
              userId: userId,
              caller: userParams.fullName,
            },
            height: '314px',
            width: '250px'
          });
          this.callDialog.afterClosed()
            .subscribe(
              (result: boolean) => {
                if (result) {
                  navigator.mediaDevices.enumerateDevices()
                    .then(
                      (devices: any[]) => {
                        if (!devices.find(device => device.kind === 'audioinput')) {
                          this.messageInformationModal('error-during-call');
                          return;
                        } else {
                          connection.accept();
                          this.setCallerId(userId);
                        }
                      });
                } else {
                  connection.reject();
                  this.setCallerId(null);
                  this.isRinging = false;
                }
              });
        });
  }

  private updateCallLog(uuid: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('uuid', uuid);
    return this.http.post(`${this.apiUrl}/orders/update_call_log`, null, {params: queryParams})
      .pipe(
        map(response => {
          return response;
        }, (error) => {
          return throwError(error);
        })
      );
  }
}
