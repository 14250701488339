import { LoadingService } from "src/app/shared/services/loading.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { InfoDialogComponent } from "../dialogs/info-dialog/info-dialog.component";

@Injectable({
  providedIn: "root",
})
export class InsuranceService {
  apiUrl = environment.apiUrl;

  private insurancePolices = new BehaviorSubject(null);
  getStoredInsurancePolices = this.insurancePolices.asObservable();
  isFromProfile: boolean
  popupAfterRedirectInfo = null

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  setInsurancePolices(data) {
    this.insurancePolices.next(data);
  }

  // Get Services

  getInsurancePolicy(): Observable<any> {
    return this.http.get(`${this.apiUrl}/companies/orders`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  checkInsuranceAuth(providerId): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("personalNumber", providerId);

    return this.http
      .get(`${this.apiUrl}/insurance/check_insurance_auth`, {
        params: queryParams,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Get policy details
   *
   * @param orderId - order id
   * @param providerId - Insurance Provider ID
   * @return An `Observable` of the response data from http request.
   */
  getOrderPolicy(orderId, providerId): Observable<any> {
    return this.http
      .get(
        `${this.apiUrl}/insurance/order_policy/${orderId}?providerId=${providerId}`,
        {}
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getInsuranceProviders(): Observable<any> {
    return this.http.get(`${this.apiUrl}/insurance/providers`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getInvoiceDetails(invoiceId: any): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/orders/invoice_details/${invoiceId}`, {})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Get policy details
   *
   * @param personalNumber - User personalNumber
   * @param providerId - Insurance Provider ID
   * @return An `Observable` of the response data from http request.
   */
  getPolicyDetails(personalNumber, providerId): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("personalNumber", personalNumber);
    queryParams = queryParams.append("providerId", providerId);

    return this.http
      .get(`${this.apiUrl}/insurance/policy_details`, { params: queryParams })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Get appeals
   *
   * @param id - Order ID
   * @return An `Observable` of the response data from http request.
   */
  getPolicyAppeals(id): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/insurance/policy_appeals/${id}`, {})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getIntegratedInsuranceProviders(): Observable<any> {
    return this.http
      .get(`${this.apiUrl}/insurance/integrated_providers`, {})
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getUserPolices(): Observable<any> {
    return this.http.get(`${this.apiUrl}/insurance/user_polices`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getInsuredUsers(id): Observable<any> {
    return this.http.get(`${this.apiUrl}/insurance/insured/${8}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getInsuredUsersNew(id): Observable<any> {
    return this.http.get(`${this.apiUrl}/insurance/add-insured-patients/${8}`, {}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  isNewInsuranceUser(personalNumber): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("personalNumber", personalNumber);
    return this.http
      .get(`${this.apiUrl}/insurance/is_new_insurance_user`, {
        params: queryParams,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  checkPriceForInsurance(orderId): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("orderId", orderId);
    return this.http
      .get(`${this.apiUrl}/insurance/check_price_for_insurance`, {
        params: queryParams,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // POST Requests

  insuranceLogin(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/insurance/insurance_login`, data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  clearOrderFromInsurance(orderId: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("orderId", orderId);

    return this.http
      .put(`${this.apiUrl}/insurance/clear_order_from_insurance`, null, {
        params: queryParams,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  resendCode(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/insurance/resend_code`, data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  addInsuredPolicy(data: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/insurance/add_insured_policy`, data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Get policy details
   *
   * @param data - object
   * @return An `Observable` of the response data from http request.
   */
  insuredUserToken(data: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("personalNumber", data.personalNumber);
    return this.http
      .post(`${this.apiUrl}/insurance/insured_user_token`, data, {
        params: queryParams,
      })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  checkInsurance(providerId: any = 8): Observable<any> {
    // TODO: change 8 to real providerId
    return this.http
      .get(`${this.apiUrl}/insurance/check-insurance?providerId=${providerId}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  insuranceProviderDoctorBubbleLogic(selectedLanguage: string): void {
    this.loadingService.setApplicationLoading(true);
    if (this.authService.isLogged()) {
      this.checkInsurance().subscribe(
        (result: any) => {
          if (result && result.status === "OK") {
            this.router.navigate([`/search-step`], {
              queryParams: { online: true, insurance: true },
            });
          }
          this.loadingService.setApplicationLoading(false);
        },
        (result: any) => {
          if (result?.error?.status === "NOT_FOUND") {
            // location.href = `/profile/insurance`;
            const infoDialog = this.dialog.open(InfoDialogComponent, {
              width: '420px',
              data: {
                primaryMessage: 'couldnt-found-info',
                secondaryMessage: 'click-to-add-insurance',
                needsAction: false,
                singleButtonAction: 'add-insurance',
                title: 'failure',
                buttonText: 'add-insurance-info'
              }
            });
            infoDialog.afterClosed().subscribe(res => {
              if(res === 'add-insurance'){
                this.router.navigate(['/add-insurance'])
              }
            }
            );
          }
          this.loadingService.setApplicationLoading(false);
        }
      );
    } else {
      this.router.navigateByUrl(
        `/user/auth-user/insurance`
      );
      this.loadingService.setApplicationLoading(false);
    }
  }
}
