import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileSaverService} from 'ngx-filesaver';
import {InvoiceService} from '../../services/invoice.service';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit {
  invoiceFormGroup: FormGroup;
  downloadInProgress = false;

  constructor(private dialogRef: MatDialogRef<InvoiceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private loadingService: LoadingService,
              private _FileSaverService: FileSaverService,
              private invoiceService: InvoiceService) {
  }

  ngOnInit() {
    this.invoiceFormGroup = this.formBuilder.group({
      patientPn: this.formBuilder.control(null, Validators.required),
      payerPn: this.formBuilder.control(null, Validators.required),
      payerFullName: this.formBuilder.control(null, Validators.required),
      orderedByPn: this.formBuilder.control(null, Validators.required)
    })
  }

  closeDialog() {
    this.dialogRef.close();
  }

  downloadInvoice() {
    if (this.invoiceFormGroup.valid) {
      this.loadingService.setApplicationLoading(true);
      this.downloadInProgress = true;
      this.invoiceService.downloadOrderInvoice(this.invoiceFormGroup.value, this.data.orderId)
        .subscribe(
          (response: any) => {
            if (this.checkBrowser()) {
              let fileBlob = new Blob([response], {type: 'application/pdf'});
              this._FileSaverService.save(fileBlob, 'invoice.pdf');

              // const url = window.URL.createObjectURL(fileBlob);
              // window.open(url, '_self');
              // window.URL.revokeObjectURL(url);
            } else {
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.style.display = 'none';
              const url = window.URL.createObjectURL(response);
              a.href = url;
              a.download = 'invoice.pdf';
              a.click();
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }

            this.dialogRef.close();
            this.loadingService.setApplicationLoading(false);
            this.downloadInProgress = false;
          }, () => {
            alert('დაქსირდა შეცდომა, გთხოვთ გადაამოწმოთ პირადი ნომერი');
            this.loadingService.setApplicationLoading(false);
            this.downloadInProgress = false;
          });
    }
  }

  checkBrowser(): boolean {
    // Get the user-agent string
    let userAgentString = navigator.userAgent;

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    if ((chromeAgent) && (safariAgent)) {
      safariAgent = false;
    }
    return safariAgent;
  }
}
