import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {TwilioService} from "../../services/twilio.service";
import {VideoCallService} from "../../services/video-call.service";
import {OrderModel} from "../../../models/order.model";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-in-progress-call',
  templateUrl: './in-progress-call.component.html',
  styleUrls: ['./in-progress-call.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(200)
      ]),
      transition(':leave',
        animate(200, style({opacity: 0})))
    ])
  ]
})
export class InProgressCallComponent implements OnInit, OnChanges, OnDestroy {
  callerId: number | string;
  isMicrophoneMuted = false;
  @Input() isVideo: boolean;
  @Input() caller: string;
  @Input() order: OrderModel;
  localVideoTrack: any;

  @ViewChild('localVideo') localVideo: ElementRef<any>;
  @ViewChild('remoteVideo') remoteVideo: ElementRef<HTMLDivElement>;
  isMouseOver = false;
  isFullScreen: boolean = false;
  interval: any;
  timePassed = 0;

  constructor(private twilioService: TwilioService,
              private videoService: VideoCallService,
              private router: Router) {
  }

  ngOnInit() {
    this.twilioService.getCallerId
      .subscribe(
        (caller) => {
          if (caller) {
            this.callerId = caller;
          }
        });
    this.checkCountdownStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isVideo && this.order) {
      this.videoService.startVideoCall(this.order.orderId);
      this.videoService.getLocalVideoTrack
        .subscribe(
          (track: any) => {
            if (track) {
              if (this.localVideoTrack) {
                this.detachVideo();
              }
              this.localVideoTrack = track;
              this.localVideo.nativeElement.appendChild(this.localVideoTrack.attach());
            } else if (this.localVideoTrack) {
              this.localVideoTrack.detach().forEach(element => element.remove());
              this.localVideoTrack.stop();
              this.localVideoTrack = null;
            }
          });
      this.videoService.getParticipantArray
        .subscribe(
          (participant: any[]) => {
            if (participant) {
              this.displayParticipant(participant);
            }
          });
      this.videoService.getVideoCallParticipant
        .subscribe(
          user => {
            if (user) {
              this.displayParticipant(user);
            }
          }
        );
    }
  }

  ngOnDestroy(): void {
    if (this.isVideo) {
      this.videoService.endUpCall();
    }
    this.hangUp();
    this.stopCountdown();
  }

  checkCountdownStatus() {
    this.twilioService.checkCountdownStart
      .subscribe(
        (start: boolean) => {
          if (start) {
            this.startCallCountdown();
          }
        }
      );
  }

  displayParticipant(participant) {
    participant.on('trackSubscribed', track => {
      this.remoteVideo.nativeElement.innerHTML = '';
      this.remoteVideo.nativeElement.appendChild(track.attach());
    });
  }

  updateUrl($event) {
    $event.target.src = 'assets/photos/avatar-3.png';
  }

  detachVideo() {
    if (this.localVideoTrack) {
      this.localVideoTrack.detach().forEach(element => element.remove());
      this.localVideoTrack.stop();
      this.localVideoTrack = null;
    }
  }


  hangUp() {
    this.stopCountdown();
    if (this.isVideo) {
      if (this.localVideoTrack) {
        this.localVideoTrack.detach().forEach(element => element.remove());
        this.localVideoTrack.stop();
        this.localVideoTrack = null;
      }
      this.videoService.setLocalVideoTrack(null);
      this.videoService.endUpCall();
    }
    this.twilioService.hangUp();
  }

  muteMicrophone() {
    this.isMicrophoneMuted = !this.isMicrophoneMuted;
    this.twilioService.mute(this.isMicrophoneMuted);
  }

  startCallCountdown() {
    this.interval = setInterval(() => {
      this.timePassed++;
    }, 1000)
  }

  stopCountdown() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.timePassed = 0;
  }

  startChat() {
    this.router.navigate(['profile/booking'])
      .then(
        () => {
          const channelUrl = 'order_' + (environment.production ? '' : 'stage_') + this.order.orderId;
          this.twilioService.makeChatAction(true, this.order, channelUrl);
        }
      );
  }
}
