<div class="incoming-call__container">
  <div class="user-image__container">
    <div class="image-wrapper"></div>
    <div class="image-wrapper delay"></div>
    <img loading="lazy" (error)="updateUrl($event)" [src]="'/web/api/files/profile_pic/' + data.userId + '?size=s'" alt="">
  </div>
  <div class="caller-info__wrapper">
    <span class="caller">{{(currentUser && currentUser.isDoctor ? 'patient-calling' : 'doctor-calling') | translate}}</span>
    <p class="user-name">{{data.caller}}</p>
    <div class="profession">REDMED</div>
  </div>
  <div class="call-action__container">
    <button (click)="answerCall(true)" class="call-action answer" mat-icon-button>
      <mat-icon>call</mat-icon>
    </button>
    <button (click)="answerCall(false)" class="call-action decline" mat-icon-button>
      <mat-icon>call_end</mat-icon>
    </button>
  </div>
</div>
