export class AddRaitingByPatient {
  constructor(public OrderId: number,
              public Punctuality: number,
              public Satisfaction: number,
              public Reliability: number,
              public Comment: string,
              public Advice: string,
              public ShowInitialName: boolean) {
  }
}
