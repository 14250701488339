import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageManipulationUtilsService {
  private headerHeight = new BehaviorSubject<number>(null);
  public getHeaderHeight = this.headerHeight.asObservable();

  constructor() {
  }

  static scrollToTop(): void {
    window.scrollTo(0, 0);
    window.scroll({top: 0, behavior: 'smooth'});
  }

  static scrollToBottom(): void {
    window.scroll({top: document.body.scrollHeight, behavior: 'smooth'});
  }

  static scrollToElement($element: HTMLElement, scrollDifferential?: HTMLDivElement): void {
    // ;
    const innerWidth = window.innerWidth || 1024;
    let differentialHeight = 0;
    if (scrollDifferential) {
      differentialHeight = scrollDifferential.clientHeight;
      if (innerWidth < 767) {
        differentialHeight -= 70;
      }
    }
    const headerAfterCollapse = innerWidth > 767 ? 150 + 32 : 242 + 50;
    const scrollHeight = differentialHeight + headerAfterCollapse;
    window.scroll({top: scrollHeight, behavior: 'smooth'});
  }

  setHeaderHeight(height: number) {
    this.headerHeight.next(height);
  }
}
