<app-redmed-loading></app-redmed-loading>
<app-global-success-popup></app-global-success-popup>
<ng-container [class.invisible]="loadingService.isFirstLoad">
  <app-download-app></app-download-app>
  <app-redmed-header *ngIf="!hideHeader"
                     [mobileSizeScreen]="mobileQuery.matches"></app-redmed-header>
  <div class="example-sidenav-container">
    <router-outlet (activate)="modifyHeader()"></router-outlet>
    <app-cookie-dialog *ngIf="show" [startValuePerformance]="performance" [startValuePreference]="preference" (show)="handleShowChange($event)" (preferencesToggleChange)="handlePreference($event)" (performanceToggleChange)="handlePerformance($event) "></app-cookie-dialog>
    <app-cookie-alert *ngIf="allertShow" (agree)="handleAgree($event)" (openDialog)="handleOpenDialog($event)" (show)="handleShowAllertChange($event)"></app-cookie-alert>
  </div>
  <app-redmed-footer *ngIf="!hideHeader" [mobileSizeScreen]="mobileQuery.matches"></app-redmed-footer>
  <!--<app-firework *ngIf="showFirework"></app-firework>-->
</ng-container>
<app-in-progress-call *ngIf="callInProgress"
                      [caller]="caller"
                      [isVideo]="isVideo"
                      [order]="currentOrder"></app-in-progress-call>
<iframe #frame id="parentFrame" src="https://sso.redmed.ge"></iframe>
