import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from "@ngx-translate/core";

export class CustomPaginator {

  constructor(private translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = this.translate.instant('items-per-page') + ':';
    customPaginatorIntl.lastPageLabel = this.translate.instant('last');
    customPaginatorIntl.firstPageLabel = this.translate.instant('first');
    customPaginatorIntl.nextPageLabel = this.translate.instant('next');
    customPaginatorIntl.previousPageLabel = this.translate.instant('previous');

    return customPaginatorIntl;
  }
}
