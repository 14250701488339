import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {OrderUserDetailModel} from '../../models/order-user-detail.model';
import {ApiService} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {momentGeoTimeParser} from '../../shared/helpers/moment-geo-time';
import {LoadingService} from '../../shared/services/loading.service';
import {InformationModalComponent} from '../information-modal/information-modal.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})

export class ConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
              private loadingService: LoadingService,
              public User: UserService,
              public API: ApiService,
              public dialog: MatDialog,
              private translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data.orderId) {
      this.checkIfRefundAmountExistForOrder(this.data.orderId);
    }
  }

  closeDialog(success: boolean = false): void {
    this.dialogRef.close(success);
  }

  cancelOrderConfirmation(orderId: number): void {
    if (!orderId) {
      this.closeDialog(true);
      return
    }
    this.loadingService.setApplicationLoading(true);
    const request: string = '/' + String(orderId);

    this.API.cancelOrder(request).subscribe(
      res => {
        this.messageInformationModal('booking-is-cancelled', true);
        this.loadingService.setApplicationLoading(false);
        this.closeDialog(true);
      },
      error => {
        this.messageInformationModal('an-error-occurred-while-cancellation');
        this.loadingService.setApplicationLoading(false);
      }
    );

    // if (this.User.isDoctor()) {
    //     this.API.cancelOrderByDoctor(request).subscribe(
    //         res => {
    //             console.log('cancelOrderByDoctor Response: ', res);
    //             this.messageInformationModal('ჯავშანი გაუქმებულია');
    //             this.closeDialog(true);
    //         }
    //     );
    // } else {
    //     this.API.cancelOrderByPatient(request).subscribe(
    //         res => {
    //             console.log('cancelOrderByPatient Response: ', res);
    //             this.messageInformationModal('ჯავშანი გაუქმებულია');
    //             this.closeDialog(true);
    //         }
    //     );
    // }
  }

  messageInformationModal(message: string, isSuccess?: boolean): void {
    this.dialog.open(InformationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'information',
        text: message,
        isSuccess: isSuccess
      }
    });
  }

  checkIfRefundAmountExistForOrder(orderId: number): void {
    const order = this.data.orders.find(o => o.orderId === orderId);
    let refundAmount = null;
    const refundLimitTime = 3600000; /* 1 Hour */
    const dateNow = momentGeoTimeParser();
    let x = (order.appointmentDateTime - dateNow);
    const dataText = this.translateService.instant('are-you-sure-you-want-to-cancel-reservation?');
    if (!this.User.isDoctor() && x >= 0 && x < refundLimitTime) {
      const request: { OrderId: number } = {
        OrderId: orderId
      };
      this.API.getUserOrderDetail(request).subscribe(
        res => {
          if (res) {
            if (res.hasOwnProperty('result')) {
              refundAmount = <OrderUserDetailModel>res['result'].price;
              this.data.amount = refundAmount;
              this.data.text = dataText;
            }
          }
        }, error => {
          this.data.text = dataText;
        }
      );
    } else {
      this.data.text = dataText;
    }
  }


}
