<footer class="footer-main-wrapper" >
  <div class="container" *ngIf="!mobileSizeScreen">
    <div class="footer-actions">
      <div class="links-wrapper first">
        <a [inlineSVG]="'assets/img/logo.svg'" class="pr-2 svg-100" routerLink="/"></a>
        <div class="d-flex mb-2 social-icons">
          <a [inlineSVG]="'assets/img/footer/appstore-white-small.svg'"
             href="https://apps.apple.com/us/app/redmed/id1483844703" target="_blank"
             class="download-from-store"></a>
          <a [inlineSVG]="'assets/img/footer/playstore-white-small.svg'"
             href="https://play.google.com/store/apps/details?id=ge.redmed.patient" target="_blank"
             class="download-from-store ml-2"></a>
        </div>
      </div>
      <div class="links-wrapper doctor">
        <a class="footer-links cursor-default">{{'doctor-space' | translate}}</a>
  
              <a class="footer-links-info" [queryParams]="{doctor: true}" routerLink="user/auth-user">{{'auth' | translate}}</a>
              <a class="footer-links-info" routerLink="user/register-doctor">{{'register' | translate}}</a>
              <a class="footer-links-info" (click)="onAppDownloadClick()">{{'download-doctor-app' | translate}}</a>
      </div>
      <div class="links-wrapper">
        <a class="footer-links cursor-default">{{'aboutUs' | translate}}</a>
  
              <a class="footer-links-info" routerLink="about">{{'about' | translate}}</a>
              <a class="footer-links-info" (click)="scrollToHowItWorksSection()">{{'howItWorks' | translate}}</a>
              <a class="footer-links-info" routerLink="blog-list" *ngIf="selectedLanguage === 'ka'">{{'blog' | translate}}</a>
              <a class="footer-links-info" routerLink="contact">{{'contact' | translate}}</a>
              <a class="footer-links-info" routerLink="personal-data-officer">{{'personal-data-officer' | translate}}</a>
              <!-- <a (click)="expandElement('specialty')" [ngClass]="{active: expandedElement === 'specialty'}"
                 class="footer-links-info footer-links-info-dropdown">{{'specialities' | translate}}
                <mat-icon>keyboard_arrow_{{expandedElement === 'specialty' ? 'up' : 'down'}}</mat-icon>
              </a> -->
      </div>
      <div class="links-wrapper last">
        <a class="footer-links cursor-default">{{'termsConditions' | translate}}</a>
  
        <a class="footer-links-info" routerLink="doctor-terms" target="_blank">{{'only-doctor' | translate}}</a>
        <a class="footer-links-info" routerLink="userterms" target="_blank">{{'only-patient' | translate}}</a>
      </div>
    </div>
    <div class="contact-info d-flex justify-content-between mt-4 company-info mb-3">
      <p> @ {{currentYear}} {{'redmed---2021---all-rights-reserved' | translate}}</p>

      <div class="d-flex">
        <a href="mailto:info@redmed.ge"
           target="_blank"
           class="mr-3">Email: info@redmed.ge</a>
        <a href="tel:+995 322 422 922">{{'phone' | translate}}: 2 422 922</a>
      </div>
    </div>
  </div>
  <div *ngIf="mobileSizeScreen" class="mobileWrapper">
    <div class="logo-wrapper">
      <a [inlineSVG]="'assets/img/logo.svg'" class="pr-2 svg-100" routerLink="/"></a>
    </div>
    <div class="footer-wrapper">
      <mat-expansion-panel
      class="mat-elevation-z0"
      hideToggle
    >
      <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[0])">
        <mat-panel-title class="info-title" >
          <span>{{titleArray[0].title | translate}}</span>
          <span><img src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[0].isOpen}"></span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button class="menu-item" [queryParams]="{doctor: true}" routerLink="user/auth-user">
        <span>{{'auth' | translate}}</span>
      </button>
      <button
        class="menu-item"
        routerLink="user/register-doctor"
      >
        <span>{{'register' | translate}}</span>
      </button>
      <button (click)="onAppDownloadClick()" class="menu-item">
        <span>{{'download-doctor-app' | translate}}</span>
      </button>
    </mat-expansion-panel>
    <mat-expansion-panel
    class="mat-elevation-z0"
    hideToggle
  >
    <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[1])">
      <mat-panel-title class="align-items-center info-title" >
        <span>{{titleArray[1].title | translate}}</span>
        <span><img src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[1].isOpen}"></span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <button class="menu-item" routerLink="about">
      <span>{{'about' | translate}}</span>
    </button>
    <button
      class="menu-item"
      (click)="scrollToHowItWorksSection()"
    >
      <span>{{'howItWorks' | translate}}</span>
    </button>
    <button routerLink="blog-list" *ngIf="selectedLanguage === 'ka'" class="menu-item">
      <span>{{'blog' | translate}}</span>
    </button>
    <button routerLink="contact" class="menu-item">
      <span>{{'contact' | translate}}</span>
    </button>
    <button routerLink="personal-data-officer" class="menu-item">
      <span>{{'personal-data-officer' | translate}}</span>
    </button>
  </mat-expansion-panel>
  <mat-expansion-panel
  class="mat-elevation-z0 lastOne"
  hideToggle
>
  <mat-expansion-panel-header class="info-header" (click)="isContainerOpen(titleArray[2])">
    <mat-panel-title class="align-items-center info-title" >
      <span>{{titleArray[2].title | translate}}</span>
      <span><img  src="../../../../assets/icons/Stroke 4.png" alt="" [ngClass]="{'upside-down': titleArray[2].isOpen}"></span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <button class="menu-item" (click)="goToPage('doctor-terms')">
    <span>{{'only-doctor' | translate}}</span>
  </button>
  <button
    class="menu-item"
    (click)="goToPage('userterms')"
  >
    <span class="footer-links-info">{{'only-patient' | translate}}</span>
  </button>

</mat-expansion-panel>
    </div>
    <div class="d-flex column padding-class">
      <a href="mailto:info@redmed.ge"
         target="_blank"
         class="info-text">Email: info@redmed.ge</a>
      <a class="info-text" href="tel:+995 322 422 922">{{'phone' | translate}}: 2 422 922</a>
    </div>
    <div class="mobile-icons-wrapper">
      <a [inlineSVG]="'assets/img/footer/appstore-white-small.svg'"
         href="https://apps.apple.com/us/app/redmed/id1483844703" target="_blank"
         class="mobile-icons"></a>
      <a [inlineSVG]="'assets/img/footer/playstore-white-small.svg'"
         href="https://play.google.com/store/apps/details?id=ge.redmed.patient" target="_blank"
         class="mobile-icons"></a>
    </div>
    <p class="company-info all-rights">@ {{currentYear}} {{'redmed---2021---all-rights-reserved' | translate}}</p>
  </div>
</footer>