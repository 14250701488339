import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, publishReplay, refCount, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CONFIG} from '../core/config/config';
import {DocumentsAvailabilityForDoctorInterface} from '../interfaces/documents-availability-for-doctor-interface';
import {ConfirmDialogComponent} from '../components';
import {MatDialog} from '@angular/material/dialog';
import {TbcConnectService} from './tbc-connect.service';
import {Location} from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import { SuccessService } from '../shared/services/success.service';
import { InfoDialogComponent } from '../shared/dialogs/info-dialog/info-dialog.component';

@Injectable()
export class UserService {
  readonly tbcConnectConfirmPopupLockedRoutes: string[] = [
    '/privacypolicy',
    'redirect'
  ];
  private userType = CONFIG.COMMON.DEFAULT_USER_TYPE;
  private apiUrl = environment.apiUrl;


  private user = new BehaviorSubject<any>(null);
  getUser = this.user.asObservable();
  private gender: Observable<any> = this.http.get(`${this.apiUrl}/users/v2/GetGender`)
    .pipe(
      map(user => user),
      publishReplay(1),
      refCount());

  constructor(private http: HttpClient,
              private dialog: MatDialog,
              private successService: SuccessService,
              private tbcConnectService: TbcConnectService,
              private location: Location,
              private translateService: TranslateService,
              private router: Router) {
    this.userType = this.getUserType();
  }

  getUserType() {
    const userType = localStorage.getItem(CONFIG.API.TAGS.USER_TYPE);
    return userType ? userType : CONFIG.COMMON.DEFAULT_USER_TYPE;
  }

  addUserPateints(data: any) {
    return this.http.post(`${this.apiUrl}/user-patients`, data);
  }

  getUserPatients(hasInsuranceBubble?: string) {
    if (hasInsuranceBubble==='true') {
      let params = new HttpParams();
      params = params.append('is-insurance-bubble', hasInsuranceBubble);
      return this.http.get(`${this.apiUrl}/user-patients`, {params});
    } else {
      return this.http.get(`${this.apiUrl}/user-patients`);
    }

  }

  public documentationNeeded(userId: string, data: any) {
    return this.http.post(`${this.apiUrl}/medical-documents/documentation-needed/${userId}`, data);
  }

  public getRequestDocument(orderId: string, documentTypeId: any) {
    return this.http.post(`${this.apiUrl}/medical-documents/request-document/${orderId}`, documentTypeId);
  }

  public getDocumentsAvailability(orderId: string, isDoctor: boolean) {
    return this.http.get(`${this.apiUrl}/medical-documents/documents-availability-for-${isDoctor ? 'doctor' : 'user'}/${orderId}`)
      .pipe(
        map((data: any) => {
          const result: DocumentsAvailabilityForDoctorInterface = data.data;
          return result;
        }),
        catchError((error: Error) => {
          return throwError(error);
        })
      );
  }

  public orderPatients(orderId: any, data: any) {
    return this.http.post(`${this.apiUrl}/orders/v2/${orderId}/order-patients`, data);
  }

  /* download/preview documents */
  public previewPdfFormOneHundred(id: number) {
    return this.http.get(`${this.apiUrl}/medical-documents/preview-pdf-form-one-hundred/${id}`, {responseType: 'blob'});
  }

  public downloadPdfFormOneHundred(id: number) {
    return this.http.get(`${this.apiUrl}/medical-documents/download-pdf-form-one-hundred/${id}`, {responseType: 'arraybuffer'});
  }

  public previewPdfConsultationcard(id: number) {
    return this.http.get(`${this.apiUrl}/medical-documents/preview-pdf-consultation-card/${id}`, {responseType: 'blob'});
  }

  public downloadPdfConsultationcard(id: number) {
    return this.http.get(`${this.apiUrl}/medical-documents/download-pdf-consultation-card/${id}`, {responseType: 'arraybuffer'});
  }

  /*  */

  deleteUserPatients(id: string) {
    return this.http.delete(`${this.apiUrl}/user-patients/${id}`);
  }

  setUserType(userType: any) {
    localStorage.setItem(CONFIG.API.TAGS.USER_TYPE, userType);
  }

  isDoctor() {
    this.userType = this.getUserType();
    return this.userType == CONFIG.COMMON.DOCTOR_USER_TYPE;
  }

  getUserID() {
    return parseInt(localStorage.getItem(CONFIG.API.TAGS.USER_ID));
  }

  getCurrentUser(): Observable<any> {
    let isRedirect = false
    this.router.events.subscribe((event:any) => {
      if(event?.url?.includes('auth-user')){
        isRedirect = true
      }
    })
    return this.http.post(`${this.apiUrl}/users/v2/Me`, null)
      .pipe(
        switchMap((user: any) => {
          return this.tbcConnectService.getTbcConnectStatus().pipe(
            map(tbcConnect => {
              const status = tbcConnect?.data?.agreed;
              const isLockedRoute = this.tbcConnectConfirmPopupLockedRoutes.some(i => this.location?.path()?.includes(i));
              if (status) {
                this.tbcConnectService.sendUserLogin(user);
              }
              // else if (status === null && !this.dialog.openDialogs.length && !isLockedRoute) {
              //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              //     width: '700px',
              //     data: {
              //       message: this.translateService.instant('agree-tbc-connect'),
              //       customClass: 'text-dark'
              //     }
              //   });
              //   dialogRef.afterClosed().subscribe(
              //     result => {
              //       this.tbcConnectService.setTbcConnectStatus(result).subscribe();
              //       const resp=user;
              //       if(resp.isDoctor === false){
              //         if(resp.marketingTermsAndConditionsAgreement === null){
              //           const marketingDialog = this.dialog.open(InfoDialogComponent, {
              //             width: '420px',
              //             data: {
              //               title: 'success',
              //               offers: true,
              //               needsAction: true,
              //               confirm: 'yesAgree',
              //               decline: 'notAgree'
              //             }
              //           });
              //           marketingDialog.afterClosed().subscribe(res => {
              //             if(res===true){
              //               this.postMarketingAgreement(true).subscribe(resp => {
              //                 this.successService.openSuccessPopup('Marketing-offers-are-enabled', 3000);
              //               })
              //             }
              //             if(res===false){
              //               this.postMarketingAgreement(false).subscribe(resp => {
              //                 this.successService.openFailPopup('Marketing-offers-are-disabled', 3000);
              //               })
              //             }
              //           }
              //           );
              //         }
              //       }
              //       if (result) {
              //         this.tbcConnectService.sendUserLogin(user);
              //       }
              //     },
              //     error => {
              //       console.log(error.error);
              //     }
              //   );
              // }
              return user;
            })
          );
        }),
        map((user: any) => {
          // this.router.navigate([`ka/profile/sms-language`]);
          if (!user.firstname) {
            user.firstname = 'მომხმარებელი';
          }
          if (JSON.stringify(this.user.value) !== JSON.stringify(user)) {
            this.user.next(user);
          }

          if (user && !user.isDoctor && !user?.preferredLanguage) {
            this.router.navigate([`profile/sms-language`]);
          }
          return user;
        }),
        catchError((error: Error) => {
          if(isRedirect){
            window.location.href = 'https://redmed.ge/user/auth-user?redirect=https://clinic.redmed.ge/auth'
          }
          return throwError(error);
        })
      );
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem('UserAccessToken');
    // navigator.serviceWorker.getRegistrations()
    //   .then(
    //     (registrations) => {
    //       registrations.forEach(registration => {
    //         registration.unregister();
    //       })
    //     });
  }

  getGenders(): Observable<any> {
    return this.gender;
  }

  sendSms(phoneNumber: string, index): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/v2/SendSms`, {PhoneNumber: phoneNumber, index})
      .pipe(
        map(response => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  deactivateUser(sms: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', sms);
    return this.http.post(`${this.apiUrl}/users/deactivate_user`, null, {params})
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  checkUserActivationStatus(phoneNumber: string, index, isDoctor = false): Observable<any> {
    let params = new HttpParams();
    params = params.append('PhoneNumber', phoneNumber);
    params = params.append('index', index);
    params = params.append('isDoctor', isDoctor.toString());

    return this.http.get(`${this.apiUrl}/users/v2/CheckUser`, {params})
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  getUserParamsById(userId: number | string) {
    return this.http.get(`${this.apiUrl}/users/full_name/${userId}`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  setDoctorInstantCallStatus(isDoctorAvailableForInstantCall: boolean) {
    return this.http.put(`${this.apiUrl}/users/set-instant-call-status`, {enabled: isDoctorAvailableForInstantCall})
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  setDoctorPrimarySpecialty(item: any) {
    return this.http.put(`${this.apiUrl}/specialty/user-specialties/${item.userSpecialtiId}/primary`,
      null)
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }


  checkUserPhone(phoneNumber: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/v2/CheckPhone`, {phoneNumber})
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  checkUserEmail(email: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/users/v2/CheckEmail`, {Email: email})
      .pipe(
        map(result => {
          return result;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  preferredLanguage(language: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('language', language);

    return this.http.post(`${this.apiUrl}/users/v2/preferred-language`, null,{params: queryParams});
  }

  public getUserAuthInfo(userName: string, suggestSms?: string) {
    let params = new HttpParams();
    params = params.append('username', userName);
    params = params.append('suggest-sms', suggestSms);
    return this.http.get(`${this.apiUrl}/users/auth-info`, {params});
  }

  public setAuthMethod(id: any) {
    let params = new HttpParams();
    params = params.append('auth-type-id', id);
    return this.http.put(`${this.apiUrl}/users/auth-method`, {}, {params});
  }

  public resetPasswordByToken(data: any) {
    return this.http.patch(`${this.apiUrl}/auth/save-password`, data);
  }

  postMarketingAgreement(accepted: boolean): Observable<any> {
    const url = `${this.apiUrl}/terms-and-conditions/marketing/agreement`;
    let params = new HttpParams();
    params = params.set('accepted', accepted.toString());
    params = params.set('os-type', '4');
    return this.http.post(url, null, { params });
  }
  
}
