import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { SpecialtyService } from "../../../services/specialty.service";

@Component({
  selector: "app-redmed-footer",
  templateUrl: "./redmed-footer.component.html",
  styleUrls: ["./redmed-footer.component.scss"],
})
export class RedmedFooterComponent implements OnInit{
  @Input() mobileSizeScreen: boolean;
  expandedElement = "";
  specialties: any;
  currentYear: number;
  public selectedLanguage = localStorage.getItem("CurLang") || "ka";

  constructor(
    private specialtyService: SpecialtyService,
    private router: Router
  ) {}

  titleArray= [
    {
    title: 'doctor-space',
    isOpen: false
  },    
  {
    title: 'aboutUs',
    isOpen: false
  },    {
    title: 'termsConditions',
    isOpen: false
  },
]

isContainerOpen(container: any){
  const index = this.titleArray.indexOf(container);
  if (index > -1) {
    this.titleArray[index].isOpen = !this.titleArray[index].isOpen;
  }
}

goToPage(page: string){
  const url = this.router.serializeUrl(
    this.router.createUrlTree([page])
  );
  window.open(url, '_blank');
}


  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.getAllSpecialties();
  }

  public scrollToHowItWorksSection(): void {
    const itemToScrollTo = document.getElementById("how-it-works-main-section");
    if (itemToScrollTo) {
      const topOfElement = itemToScrollTo.offsetTop;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  public expandElement(specialty: string) {
    this.expandedElement = this.expandedElement === specialty ? "" : specialty;
  }

  onAppDownloadClick(){
    if(this.checkMobileSystem() === 'Android'){
      window.location.replace('market://details?id=ge.redmed.doctor');
    }else if(this.checkMobileSystem() === 'iOS'){
      window.location.replace('itms-apps://itunes.apple.com/app/1483753311');
    }else{
      window.location.replace('https://play.google.com/store/apps/details?id=ge.redmed.doctor&hl=en&gl=US');
    }
  }

  checkMobileSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return 'iOS';
    }
    return null;
  }


  private getAllSpecialties() {
    this.specialtyService.getAllSpecialties().subscribe(
      (res) => {
        if (res) {
          console.log(res);
          this.specialties = res;
        }
      },
      (error) => {
        //
      }
    );
  }

  public goToSelectedSpecialty(specialty: any) {
    this.router.navigate([`specialoba/${specialty.specialityNameLat}`]);
  }
}
