import {isPlatformBrowser} from '@angular/common';
import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {ApiService, AuthService} from '../../../services';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit, OnDestroy {
  phoneControl = new FormControl(null, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]+$')]);
  innerWidth = 1024;
  showDownloadFreezer = false;

  public isDownload: boolean = false;
  public hide: boolean = false;

  constructor(private loadingService: LoadingService,
              public API: ApiService,
              @Inject(PLATFORM_ID) private platformId: any,
              public RT: Router,
              public Auth: AuthService) {
    this.download();
  }

  @HostListener('window:resize', [])
  onResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth || 1024;
    }
    if (innerWidth < 768) {
      if (this.Auth.isLogged() && this.Auth.isDoctor()) {
        document.body.style.overflow = "hidden";
        this.showDownloadFreezer = true;
      } else {
        document.body.style.overflow = "auto";
        this.showDownloadFreezer = false;
      }
    } else {
      document.body.style.overflow = "auto";
      this.showDownloadFreezer = false;
    }
  }

  public hideDownload(): void {
    this.hide = true;
  }

  public download(): void {
    this.RT.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const path = this.RT.url.split('?');
        if ((path[0] === '/download')) {
          this.isDownload = true;
        }
      }
    });
  }

  ngOnInit() {
    // if (innerWidth < 768) {
    //   if (this.Auth.isLogged() && this.Auth.isDoctor()) {
    //     document.body.style.overflow = "hidden";
    //     this.showDownloadFreezer = true;
    //   } else {
    //     document.body.style.overflow = "auto";
    //     this.showDownloadFreezer = false;
    //   }
    // } else {
    //   document.body.style.overflow = "auto";
    //   this.showDownloadFreezer = false;
    // }
    // this.Auth.checkLoginStatus
    //   .subscribe(
    //     (status: boolean) => {
    //       if (status) {
    //         if (innerWidth < 768) {
    //           if (this.Auth.isDoctor()) {
    //             document.body.style.overflow = "hidden";
    //             this.showDownloadFreezer = true;
    //           } else {
    //             document.body.style.overflow = "auto";
    //             this.showDownloadFreezer = false;
    //           }
    //         } else {
    //           document.body.style.overflow = "auto";
    //           this.showDownloadFreezer = false;
    //         }
    //       }
    //     }
    //   );
  }

  ngOnDestroy(): void {
    //   document.body.style.overflow = "auto";
  }

  sendDownloadLink() {
    if (this.phoneControl.valid) {
      const data = {
        phone: '995' + this.phoneControl.value
      };
      this.loadingService.setApplicationLoading(true);
      this.API.sendDownloadUrl(data)
        .subscribe(
          (response: any) => {
            this.loadingService.setApplicationLoading(false);
          }, () => {
            this.loadingService.setApplicationLoading(false);
          }
        );
    }
  }


}
