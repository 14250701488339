import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {mergeMap, take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs'
import {ToastrService} from "ngx-toastr";
import {ApiService, LanguageService} from "../services";
import {CustomAngularFireMessagingPayloadModel} from "../models/custom-angular-fire-messaging-payload.model";
import {NotificationTypeModel} from "../models/notification-type.model";

@Injectable()
export class MessagingService {
  cLang: any;
  currentMessage = new BehaviorSubject(null);

  private orderNotification = new BehaviorSubject(null);
  getOrderNotification = this.orderNotification.asObservable();

  constructor(
    private API: ApiService,
    private LS: LanguageService,
    private router: Router,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private toastrService: ToastrService) {
    this.cLang = this.LS.getLang();
    this.angularFireMessaging.messages.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
    // this.angularFireMessaging.messages.subscribe(
    //   (payload: CustomAngularFireMessagingPayloadModel) => {
    //     console.log("new message received. ", payload);
    //     this.currentMessage.next(payload);
    //     this.toastrService.info(payload.notification.body, payload.notification.title, {
    //       disableTimeOut: true
    //     });
    //   }
    // );

    // setTimeout(() => {
    //   const orderId = 711;
    //   const notificationType = NotificationTypeModel.CompleteOrderByDoctor;
    //   this.toastrService.info('Body text', 'Title', {
    //     disableTimeOut: true,
    //     closeButton: true
    //   })
    //     .onTap
    //     .pipe(take(1))
    //     .subscribe(() => this.toasterClickedHandler(notificationType, orderId));
    // }, 6000);
  }

  /**
   * update token in firebase database
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.API.setUserToken({token: token})
          .subscribe(
            (response: any) => {
              this.angularFireDB.object('fcmTokens/').update(data)
                .then(() => {

                })
            }, () => {

            });
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
      },
      (err) => {
        // console.log('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: CustomAngularFireMessagingPayloadModel) => {
        this.currentMessage.next(payload);
        this.toastrService.info(payload.notification.body, payload.notification.title || 'REDMED', {
          disableTimeOut: true,
          closeButton: true,
        }).onTap.pipe(take(1)).subscribe(() =>
          this.toasterClickedHandler(payload.data.type, Number(payload.data.order_id) || null)
        );
      });
  }

  toasterClickedHandler(notificationType: NotificationTypeModel, orderId?: number): void {
    this.setOrderNotification(notificationType);
    if (orderId) {
      this.router.navigate(['/profile/booking'], {
        queryParams: {
          notification: true,
          notificationType: notificationType,
          orderId: orderId
        }
      });
    }
  }

  setOrderNotification(notification: any) {
    this.orderNotification.next(notification);
  }

  logoutFirebase() {
    this.angularFireMessaging.getToken
      .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          console.log('Token deleted!');
        },
      );
  }
}
