import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {CONFIG} from '../../core/config/config';
import {AddRaitingByPatient} from '../../models/add-raiting-by-patient.model';
import {GetUserReviewByOrderIdModel} from '../../models/get-user-review-by-order-id.model';
import {OrderStatusEnum} from '../../models/order-status.enum';
import {OrderUserDetailModel} from '../../models/order-user-detail.model';
import {ApiService, AuthService, UserService} from '../../services';
import {ClinicService} from '../../shared/services/clinic.service';
import {LoadingService} from '../../shared/services/loading.service';
import {ConfirmationModalComponent} from '../confirmation-modal/confirmation-modal.component';
import {InformationModalComponent} from '../information-modal/information-modal.component';
import {HelpCrunchService} from "../../shared/services/help-crunch.service";

export interface DialogData {
  title: string;
  text: string;
  amount: string;
}

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrls: ['./booking-modal.component.scss']
})
export class BookingModalComponent implements OnInit, OnDestroy {
  userInfo: any;
  tabIndex: number = 1;
  supportFacebookUrl = CONFIG.COMMON.SUPPORT_FACEBOOK_URL;
  orderDetail: OrderUserDetailModel;
  orderConfirmIntervalObject: any;
  updateDetailOrderInterval = 15000; /* 15 Second */
  updateDetailOrderIntervalObject: any;
  reviewEditMode = false;
  showInitialName = new FormControl(false, Validators.required);
  reviewRatingItems = [
    {Name: 'Satisfaction', Rating: 0},
    {Name: 'Punctuality', Rating: 0},
    {Name: 'Reliability', Rating: 0}
  ];
  orderPatientDetails: any;
  reviewComment = new FormControl();
  reviewAdvice = new FormControl();
  orderAccepted: boolean = false;

  constructor(public dialogRef: MatDialog,
              private _loadingService: LoadingService,
              private _apiService: ApiService,
              private helpCrunchService: HelpCrunchService,
              private clinicService: ClinicService,
              public Auth: AuthService, public userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialogRef<BookingModalComponent>) {
  }

  ngOnInit() {
    if (this.userService.isDoctor() || this.data.isClinicAdmin) {
      this.getDoctorOrderDetail(this.data.orderId);
    } else {
      this.getUserOrderDetail(this.data.orderId);
    }
    this.updateDetailOrder();
    this.getUserInfo();

    if (this.data.freezeOrderReview) {
      this.tab(3);
      this.reviewEditMode = true;
    }

    if (this.data.orderConfirmTime) {
      this.startConfirmTimerForOrderInDetailModal();
    }
  }

  ngOnDestroy() {
    if (this.updateDetailOrderIntervalObject) {
      clearInterval(this.updateDetailOrderIntervalObject);
    }
    if (this.orderConfirmIntervalObject) {
      clearInterval(this.orderConfirmIntervalObject);
    }
  }

  startConfirmTimerForOrderInDetailModal(): void {
    const that = this;
    const timeParts = this.data.orderConfirmTime.split(':');
    let x = (+timeParts[0] * 60000 + (+timeParts[1] * 1000));

    this.orderConfirmIntervalObject = setInterval(() => {
      x -= 1000;
      const tempTime = moment.duration(x);
      that.orderDetail.confirmTime = that.numberToTwoDigits(tempTime.minutes()) + ':' + that.numberToTwoDigits(tempTime.seconds());

      if (x <= 0) {
        clearInterval(that.orderConfirmIntervalObject);
      }
    }, 1000);
  }

  numberToTwoDigits(n: number): string {
    if (n < 10) {
      return '0' + String(n);
    }
    return String(n);
  }

  getUserReviewByOrderId(orderId: number): void {

    const request: { orderId: number } = {orderId: orderId};

    this._apiService.GetUserReviewByOrderId(request).subscribe(
      res => {
        if (res) {
          res = <GetUserReviewByOrderIdModel>res;
          this.showInitialName.setValue(res.userName !== this.userInfo.fullName);
          this.reviewRatingItems.find(i => i.Name === 'Satisfaction').Rating = res.satisfaction;
          this.reviewRatingItems.find(i => i.Name === 'Punctuality').Rating = res.punctuality;
          this.reviewRatingItems.find(i => i.Name === 'Reliability').Rating = res.reliability;
          this.reviewComment.setValue(res.comment);
          this.reviewAdvice.setValue(res.advice);
        }
      },
      error => {
        console.log('Error getReview: ', error);
      }
    );
  }

  openSupportFacebookUrl($event: any): void {
    $event.stopPropagation();
    this.helpCrunchService.showHelpCrunch();
  }

  getUserInfo(): void {
    this._apiService.getUserInfo()
      .subscribe(response => {
        this.userInfo = response;
        if (this.data.isClinicAdmin) {
          this.getOrderPatientDetails(this.userInfo.clinics[0].id, this.data.orderId);
        }
        if (!this.userService.isDoctor()) {
          this.getUserReviewByOrderId(this.data.orderId);
        }
      });
  }

  updateDetailOrder(): void {
    this.updateDetailOrderIntervalObject = setInterval(() => {
      this.updateDetailOrderRequest();
    }, this.updateDetailOrderInterval);
  }

  updateDetailOrderRequest(): void {
    if (this.userService.isDoctor() || this.data.isClinicAdmin) {
      this.updateDoctorOrderDetail();
    } else {
      this.updateUserOrderDetail();
    }
  }

  getUserOrderDetail(orderId: number): void {
    const request: { OrderId: number } = {
      OrderId: orderId
    };
    this._apiService.getUserOrderDetail(request).subscribe(
      res => {
        if (res) {
          if (res.hasOwnProperty('result')) {
            this.orderDetail = res['result'];
            // this.getPatientAction();
            this.updateIsActiveReviewTab();
          }
        }
      }
    );
  }

  getDoctorOrderDetail(orderId: number): void {
    const request: { OrderId: number } = {
      OrderId: orderId
    };
    this._apiService.getDoctorOrderDetail(request).subscribe(
      res => {
        if (res) {
          if (res.hasOwnProperty('result')) {
            this.orderDetail = res['result'];
            // this.getDoctorAction();
            this.updateIsActiveReviewTab();
          }
        }
      }
    );
  }

  updateDoctorOrderDetail(): void {
    const request: { OrderId: number } = {
      OrderId: this.orderDetail.orderId
    };
    this._apiService.getDoctorOrderDetail(request).subscribe(
      res => {
        if (res) {
          if (res.hasOwnProperty('result')) {
            res['result'].actions = this.orderDetail.actions;
            this.orderDetail = res['result'];
            // this.getDoctorAction();
            this.updateIsActiveReviewTab();
          }
        }
      }
    );
  }

  updateUserOrderDetail(): void {
    const request: { OrderId: number } = {
      OrderId: this.orderDetail.orderId
    };
    this._apiService.getUserOrderDetail(request).subscribe(
      res => {
        if (res) {
          if (res.hasOwnProperty('result')) {
            res['result'].actions = this.orderDetail.actions;
            this.orderDetail = res['result'];
            // this.getPatientAction();
            this.updateIsActiveReviewTab();
          }
        }
      }
    );
  }

  updateIsActiveReviewTab(): void {
    this.orderDetail.isActiveReviewTab = (this.orderDetail.orderStatusId === OrderStatusEnum.feedBack || this.orderDetail.orderStatusId === OrderStatusEnum.finished);
  }

  // getUserAction():void {
  //     const orderId = this.orderDetail.orderId;
  //     const request: {OrderId: number } = {OrderId: this.orderDetail.orderId};
  //     this.API.getPatientAction(request).subscribe(
  //         res => {
  //             if (!res) return;
  //             if (!res.hasOwnProperty('result')) return;
  //             if (this.orderDetail && this.orderDetail.orderId === orderId) {
  //                 this.orderDetail.actions = res['result'];
  //             }
  //         }
  //     );
  // }


  tab(id: number): void {
    this.tabIndex = id;
  }

  closeDialog(): void {
    this.dialog.close({orderAccepted: this.orderAccepted});
  }

  confirmationDialog(): void {
    const dialogRef = this.dialogRef.open(ConfirmationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'notification',
        text: 'are-you-sure-you-want-to-cancel-reservation?',
        amount: 'გაუქმების საფასურია 3 ლ',
        orderId: 0
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((res => {
      if (res) {
        dialogRef.close();
      }
    }));
  }

  cancelOrderFromBookingModal(orderId: number): void {
    const dialog = this.dialogRef.open(ConfirmationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'notification',
        text: 'are-you-sure-you-want-to-cancel-reservation?',
        amount: '',
        orderId: orderId
      },
      disableClose: true
    });
    dialog.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.orderAccepted = true;
          }
        }
      );
  }

  approveOrderByDoctorFromBookingModal(orderId: number): void {
    if (!this.userService.isDoctor() && !this.data.isClinicAdmin) {
      return;
    }
    this._loadingService.setApplicationLoading(true);
    const request: { OrderId: number } = {OrderId: orderId};
    this._apiService.approveOrderByDoctor(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('booking-confirmed', true);
        this.updateDetailOrderRequest();
        this.orderAccepted = true;
      },
      error => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('an-error-occurred-while-confirming-the-booking');
      }
    );
  }

  rejectOrderByDoctorFromBookingModal(orderId: number): void {
    if (!this.userService.isDoctor() || this.data.isClinicAdmin) return;
    this._loadingService.setApplicationLoading(true);
    const request: string = '/' + String(orderId);
    this._apiService.cancelOrder(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('booking-rejected', true);
        this.updateDetailOrderRequest();
        this.orderAccepted = true;
      },
      error => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('error-occurred-while-rejecting-the-reservation!');
      }
    );
  }

  // approveOrderByPatientFromBookingModal(orderId: number): void {
  //     if (this.User.isDoctor())
  //         return;
  //     const request:{OrderId: number} = {OrderId: orderId};
  //
  //     this.API.approveOrderByPatient(request).subscribe(
  //         res => {
  //             console.log('approveOrderByDoctor Response: ', res);
  //             this.dialogRef.open(InformationModalComponent, <any>{
  //                 width: '430px',
  //                 data: {
  //                     title: 'ინფორმაცია',
  //                     text: 'ორდერი დადასტურებულია',
  //                 }
  //             });
  //         }
  //     );
  // }
  baseUrl = environment.baseUrl;

  completeOrderByDoctorFromBookingModal(orderId: number): void {
    if (!this.userService.isDoctor()) return;
    this._loadingService.setApplicationLoading(true);
    const request: { orderId: number } = {orderId: orderId};
    console.log('request: ', request);
    this._apiService.completeOrderByDoctor(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('the-service-is-complete', true);
        this.updateDetailOrderRequest();
        this.orderAccepted = true;
      },
      error => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('an-error-occurred-while-completing-the-service!');
      }
    );
  }

  completeOrderByPatientFromBookingModal(orderId: number): void {
    if (this.userService.isDoctor()) return;
    this._loadingService.setApplicationLoading(true);
    const request: { OrderId: number } = {OrderId: orderId};
    this._apiService.completeOrderByPatient(request)
      .subscribe(
        res => {
          this._loadingService.setApplicationLoading(false);
          this.messageInformationModal('the-service-is-complete', true);
          this.updateDetailOrderRequest();
          this.orderDetail.isActiveReviewTab = true;
          this.tab(3);
          this.orderAccepted = true;
        },
        error => {
          this._loadingService.setApplicationLoading(false);
          this.messageInformationModal('an-error-occurred-while-completing-the-service!');
        }
      );
  }

  onMyWayActionOrderFromModal(orderId: number): void {
    if (!this.userService.isDoctor()) return;
    this._loadingService.setApplicationLoading(true);
    const request: { OrderId: number } = {OrderId: orderId};
    this._apiService.changeOrderActionDoctorOnMyWay(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal(`booking-status-changed---i'm-on-my-way`, true);
        this.updateDetailOrderRequest();
        this.orderAccepted = true;
      },
      error => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('error-occurred-when-changing-the-booking-status!');
      }
    );
  }

  serviceInProgressFromBookingModal(orderId: number): void {
    if (!this.userService.isDoctor()) return;
    this._loadingService.setApplicationLoading(true);
    const request: { OrderId: number } = {OrderId: orderId};
    this._apiService.changeOrderActionServiceInProgress(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        // this.openServiceInProgressModal(orderId)
        this.updateDetailOrderRequest();
        this.orderAccepted = true;
      },
      error => {
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('an-error-occurred-while-starting-the-service!');
      }
    );
  }

  startEditReview(): void {
    this.reviewEditMode = !this.reviewEditMode;
  }

  selectReviewRating(i, star): void {
    this.reviewRatingItems[i].Rating = star;
  }

  saveReview(): void {
    if (this.userService.isDoctor()) return;
    if (this.reviewRatingItems[0].Rating === 0 || this.reviewRatingItems[1].Rating === 0 ||
      this.reviewRatingItems[2].Rating === 0) {
      this.messageInformationModal('please-rate-the-service-with-all-three-parameters');
      return;
    }
    // if (!this.reviewComment.value) {
    //   this.messageInformationModal('გთხოვთ დაწეროთ კომენტარი');
    //   return;
    // }

    // if (!this.reviewComment.value) {
    //   this.messageInformationModal('გთხოვთ დაწეროთ კომენტარი');
    //   return;
    // }

    const request = new AddRaitingByPatient(
      this.orderDetail.orderId,
      this.reviewRatingItems[1].Rating,
      this.reviewRatingItems[0].Rating,
      this.reviewRatingItems[2].Rating,
      this.reviewComment.value,
      this.reviewAdvice.value,
      this.showInitialName.value);

    this._loadingService.setApplicationLoading(true);
    this._apiService.addRaitingByPatient(request).subscribe(
      res => {
        this._loadingService.setApplicationLoading(false);
        this.reviewEditMode = false;
        this.messageInformationModal('the-service-is-complete', true);
        this.data.freezeOrderReview = false;
        this.updateDetailOrderRequest();
      },
      error => {
        console.log(error);
        this._loadingService.setApplicationLoading(false);
        this.messageInformationModal('an-error-occurred-while-adding--rating!');
      }
    );
  }

  startCallFromBookingModal(order: any): void {
    this.dialog.close({'callOpenOrderId': order});
  }

  startChatFromBookingModal(orderId: number, foreign = false): void {
    this.dialog.close({'chatOpenOrderId': orderId, isForeign: foreign});
  }

  messageInformationModal(message: string, isSuccess?: boolean): void {
    this.dialogRef.open(InformationModalComponent, <any>{
      width: '430px',
      data: {
        title: 'information',
        text: message,
        isSuccess: isSuccess
      }
    });
  }

  downloadInvoice() {
    this.dialog.close({'downloadInvoice': true});
  }

  private getOrderPatientDetails(clinicId, orderId) {
    this.clinicService.getOrderPatientDetails(clinicId, orderId)
      .subscribe((response: any) => {
        this.orderPatientDetails = response.data;
      });
  }
}
